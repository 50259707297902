import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    Snackbar, Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Info} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import HourGlassAnimation from "../rigid/steps/hourglass.gif";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonIcapeSoftBlue} from "../../button/button-icape";
import {useDropzone} from "react-dropzone";
import {snackbarTransition} from "../../../tools/snackbarTransition";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";


const GerberDropzone = (props) => {

    const {t} = useTranslation();

    // PROPS SETUP
    const {userGerber, setUserGerber, handleDropFile} = props.gerber
    const {analyzeInProgress, isJaydaPcbQuotable} = props.jayda
    const {repeatOrderMode, isLockFields} = props.lock


    // DRAG AND DROP SETUP
    const [isErrorGerberUploaded, setIsErrorGerberUploaded] = useState(false);
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'application/zip': ['.zip', '.zipx'],
            'application/x-rar-compressed': ['.rar'],
            'application/x-7z-compressed': ['.7z'],
        },
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                setUserGerber([
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                ]);
            } else {
                setIsErrorGerberUploaded(true);
                console.error('Seuls les fichiers d\'archive sont autorisés.');
            }
        }
    });
    useEffect(() => {
        // console.log('useeffect step1 userGerber : ', userGerber[0]?.path);
        // Make sure to revoke the data uris to avoid memory leaks
        userGerber.forEach(file => URL.revokeObjectURL(file.preview));
        if (userGerber.length > 0 && analyzeInProgress === 'stop') {
            handleDropFile(userGerber);
        }
    }, [userGerber]);


    // HELPER
    const [isGerberHelperDialogOpen, setIsGerberHelperDialogOpen] = useState(false);

    return (
        <>
            <Box className="technical-data-card drop-bloc">
                <Typography variant={'h3'}>{t("calculators.step1.gerber.gerberFile")}</Typography>

                {/*TOOLTIP GERBER HELPER*/}
                {(analyzeInProgress === 'stop' || analyzeInProgress === 'ended') && !repeatOrderMode.background &&
                    <Tooltip title={t("common.moduleInfo")} className="tooltip-gerber">
                        <IconButton className="tooltip-icon-purple"
                                    onClick={() => setIsGerberHelperDialogOpen(true)}>
                            <InfoTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                }

                <Box className="archive-bloc">
                    {isLockFields &&
                        <Box className='dropzone drop-off'>
                            <LockIcon />
                            <Typography component={"span"}>{t("calculators.step1.gerber.notAvailablePcbLoaded")}</Typography>
                        </Box>
                    }
                    {analyzeInProgress === 'stop' && !repeatOrderMode.background && !isLockFields &&
                        <Box {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <img src="/assets/lottie/dragndrop.gif" alt=""/>
                            <Box component="span">{t("calculators.step1.gerber.dragNDrop")}</Box>
                            <Box component="span" className="dropzone-text-small">{t("calculators.step1.gerber.allowedFiles")}</Box>
                        </Box>
                    }
                    {
                        analyzeInProgress === 'inProgress' &&
                        <Box className='dropzone drop-off'>
                            <img src={HourGlassAnimation} alt={"hourglass animation"}/>
                            <Box component="span">{t("calculators.step1.gerber.analysisInProgress")}</Box>
                        </Box>
                    }
                    {
                        analyzeInProgress === 'ended' && isJaydaPcbQuotable &&
                        <Box className='dropzone drop-off'>
                            <TaskAltIcon/>
                            <Box component="span">{t("calculators.step1.gerber.analysisComplete")}</Box>
                        </Box>
                    }
                    {
                        analyzeInProgress === 'ended' && !isJaydaPcbQuotable &&
                        <Box className='dropzone drop-off'>
                            <BrokenImageIcon />
                            <Box component="span">
                                {t("calculators.step1.gerber.contactEngineer")}
                            </Box>
                        </Box>
                    }

                </Box>
            </Box>

            {/*HELPER POPUP MODAL*/}
            <Dialog open={isGerberHelperDialogOpen} onClose={ () => setIsGerberHelperDialogOpen(false)}>
                <DialogTitle>
                    {t("popup.gerberHelper.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("popup.gerberHelper.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeSoftBlue onClick={ () => setIsGerberHelperDialogOpen(false) }>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>

            {/*SNACKBAR ERROR MIME TYPE GERBER DROPZONE*/}
            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={isErrorGerberUploaded}
                      key='topright'
                      sx={{ width: '100%' }}
                      autoHideDuration={6000}
                      TransitionComponent={snackbarTransition}
                      onClose={() => setIsErrorGerberUploaded(false)}>
                <Alert severity="warning" variant="filled"
                       onClose={() => setIsErrorGerberUploaded(false)}>
                    Only archive files are accepted for Gerber
                </Alert>
            </Snackbar>
        </>
    )
}

export default GerberDropzone