const getSelectorIdFromTechnoAndNbLayers = (_technology, _nbLayers) => {

    //todo maybe Ajax call to get these data from DB ?
    const rigid = [
                    { id: 1, numberOfLayer: 1, name: 'Single Side' },
                    { id: 2, numberOfLayer: 2, name: 'Double Side' },
                    { id: 3, numberOfLayer: 4, name: '4 Layers' },
                    { id: 4, numberOfLayer: 6, name: '6 Layers' },
                    { id: 5, numberOfLayer: 8, name: '8 Layers' },
                    { id: 6, numberOfLayer: 10, name: '10 Layers' },
                    { id: 7, numberOfLayer: 12, name: '12 Layers' },
                    { id: 8, numberOfLayer: 1, name: 'NC: No Copper' },
                  ];

    const aluminum = [
                    { id: 11, numberOfLayer: 1, name: 'Single Side' },
                    { id: 12, numberOfLayer: 2, name: 'Double Side' },
                  ];

    const hdi = [
                    { id: 21, numberOfLayer: 1, name: 'Single Side' },
                    { id: 22, numberOfLayer: 2, name: 'Double Side' },
                    { id: 23, numberOfLayer: 4, name: '4L' },
                    { id: 24, numberOfLayer: 6, name: '6L' },
                    { id: 25, numberOfLayer: 8, name: '8L' },
                    { id: 26, numberOfLayer: 10, name: '10L' },
                    { id: 27, numberOfLayer: 12, name: '12L' },
    ];
    const flex = [
                    { id: 31, numberOfLayer: 1, name: '1L' },
                    { id: 32, numberOfLayer: 2, name: '2L' },
                    { id: 33, numberOfLayer: 4, name: '4L' },
    ];


    function getIdByNumberOfLayer(technology, numberOfLayer) {
        let id = null;

        for (const techno of pcb_technology[technology]) {
            if (techno.numberOfLayer === parseInt(numberOfLayer)) {
                id = techno.id;
                break;
            }
        }
        //TODO create an feedback object with success|techno ID  /  error|translate message about the error - return this object to calculator (which will initiate a popup if error)

        //TODO There are constraints in certain technologies, such as layers 1 and 2 in HDI, which are blocked by default.
        // Currently, if you load a gerber in HDI calculator, the selector still takes the single (for example) when it shouldn't be able to.

        // if(id)
        // {
        //    let feedback = {'status': 'success', id}
        // }else
        // {
        //    let feedback = {'status': 'error', id, 'message':`number of layers not technically possible for the ${technology} technology. Contact US blablabla (translate this message)`  }
        // }
        // return feedback;

        //for the moment, if the number of layers is out for the techno, the return 'null' will be managed by calculator_XXX.jsx and initiate a popup for the error.
        return id;
    }

    const pcb_technology = { rigid, aluminum, hdi, flex };

    return getIdByNumberOfLayer(_technology, _nbLayers)
}

export {
    getSelectorIdFromTechnoAndNbLayers
}
