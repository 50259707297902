import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {mmToInch, mmToMil} from "../../../tools/unit.utils";
import {Info} from "@mui/icons-material";
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';

function StencilFields(props) {
    const {t} = useTranslation();
    const {
        stencilTypeId, setStencilTypeId,
        stencilEdgeId,setStencilEdgeId,
        stencilThicknessId, setStencilThicknessId,
        stencilFrameId, setStencilFrameId,
        unitFormat
    } = props.fields

    const { repeatOrderMode, handleStencilUpdateOnLockedPrice } = props.repeatOrder

    const [isStencilModifiedOnLockedPrice, setIsStencilModifiedOnLockedPrice] = useState(false)


    // Reset stencil values if no stencil is selected
    //TODO CHANGE USEEFECT TO A FUCNTION TRIGGERDED BY STENCILTYPE ONCHANGE
    useEffect(() => {

        if (!props.isStencil) {
            return
        }

        if (stencilTypeId === 1) {
            setStencilEdgeId(1);
            setStencilThicknessId(1);
            setStencilFrameId && setStencilFrameId(1);
        }

    }, [stencilTypeId])


    /**
     * Updates stencil if locked price
     */
    useEffect(() => {
        if (!repeatOrderMode?.lockPrice){
            return
        }

        if (isStencilModifiedOnLockedPrice){
            handleStencilUpdateOnLockedPrice();
            setIsStencilModifiedOnLockedPrice(false);
        }
    }, [isStencilModifiedOnLockedPrice]);

    return (
        <>
            <Box className="technical-data-card" sx={{position: "relative"}}>
                    <Typography variant={'h3'}>
                        {t("common.stencil")}
                    </Typography>

                    {props.isStencil && props.isActive && !repeatOrderMode?.background ?
                        <>
                             {/*STENCIL Field*/}
                            <FormControl>
                                <InputLabel id="Stencil-label">
                                    {t("common.stencil")}
                                </InputLabel>
                                <Select
                                    labelId="stencil"
                                    id="stencil"
                                    value={stencilTypeId}
                                    label={t("common.stencil")}
                                    onChange={(e) => {
                                        setStencilTypeId(e.target.value);
                                        if (repeatOrderMode.lockPrice) {
                                            setIsStencilModifiedOnLockedPrice(true);
                                        }
                                    }}
                                >
                                    <MenuItem value={1}>{t("calculators.selectable.noStencil")}</MenuItem>
                                    <MenuItem value={2}>{t("calculators.selectable.top")}</MenuItem>
                                    <MenuItem value={3}>{t("calculators.selectable.bottom")}</MenuItem>
                                    <MenuItem value={4}>{t("calculators.selectable.topBottom")}</MenuItem>
                                </Select>
                            </FormControl>

                             {/*STENCIL EDGE Field*/}
                            <FormControl disabled={stencilTypeId === 1}>
                                <InputLabel id="StencilEdge-label">
                                    {t("calculators.step3.fields.stencilEdge")}
                                </InputLabel>
                                <Select
                                    labelId="StencilEdge"
                                    id="StencilEdge"
                                    value={stencilEdgeId}
                                    label={t("calculators.step3.fields.stencilEdge")}
                                    onChange={(e) => {
                                        setStencilEdgeId(e.target.value);
                                        if (repeatOrderMode.lockPrice) {
                                            setIsStencilModifiedOnLockedPrice(true);
                                        }
                                    }}
                                    endAdornment={<InputAdornment className="select-input-adornment" position="end">{unitFormat}</InputAdornment>}
                                >
                                    <MenuItem value={1}>--</MenuItem>
                                    <MenuItem value={2}> { unitFormat === 'mm' ? 10 : `${ mmToInch(10)}` } </MenuItem>
                                    <MenuItem value={3}> { unitFormat === 'mm' ? 20 : `${ mmToInch(20)}` } </MenuItem>
                                    <MenuItem value={4}> { unitFormat === 'mm' ? 30 : `${ mmToInch(30)}` } </MenuItem>
                                    <MenuItem value={5}> { unitFormat === 'mm' ? 40 : `${ mmToInch(40)}` } </MenuItem>
                                    <MenuItem value={6}> { unitFormat === 'mm' ? 50 : `${ mmToInch(50)}` } </MenuItem>
                                    <MenuItem value={7}> { unitFormat === 'mm' ? 60 : `${ mmToInch(60)}` } </MenuItem>
                                    <MenuItem value={8}> { unitFormat === 'mm' ? 70 : `${ mmToInch(70)}` } </MenuItem>
                                    <MenuItem value={9}> { unitFormat === 'mm' ? 80 : `${ mmToInch(80)}` } </MenuItem>
                                    <MenuItem value={10}> { unitFormat === 'mm' ? 90 : `${ mmToInch(90)}` } </MenuItem>
                                    <MenuItem value={11}> { unitFormat === 'mm' ? 100 : `${ mmToInch(100)}` } </MenuItem>
                                </Select>
                            </FormControl>

                             {/*STENCIL THICKNESS Field*/}
                            <FormControl disabled={stencilTypeId === 1}>
                                <InputLabel id="stencilThickness-label">
                                    {t("calculators.step3.fields.stencilThickness")}
                                </InputLabel>
                                <Select
                                    labelId="stencilThickness"
                                    id="stencilThickness"
                                    value={stencilThicknessId}
                                    label={t("calculators.step3.fields.stencilThickness")}
                                    onChange={(e) => {
                                        setStencilThicknessId(e.target.value);
                                        if (repeatOrderMode.lockPrice) {
                                            setIsStencilModifiedOnLockedPrice(true);
                                        }
                                    }}
                                    endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                        {unitFormat === 'mm' ? unitFormat : "mil"}
                                    </InputAdornment>}
                                >
                                    <MenuItem value={1}>--</MenuItem>
                                    <MenuItem value={2}> { unitFormat === 'mm' ? 0.06 : `${ mmToMil(0.06)}` } </MenuItem>
                                    <MenuItem value={3}> { unitFormat === 'mm' ? 0.08 : `${ mmToMil(0.08)}` } </MenuItem>
                                    <MenuItem value={4}> { unitFormat === 'mm' ? 0.10 : `${ mmToMil(0.10)}` } </MenuItem>
                                    <MenuItem value={5}> { unitFormat === 'mm' ? 0.12 : `${ mmToMil(0.12)}` } </MenuItem>
                                    <MenuItem value={6}> { unitFormat === 'mm' ? 0.13 : `${ mmToMil(0.13)}` } </MenuItem>
                                    <MenuItem value={7}> { unitFormat === 'mm' ? 0.15 : `${ mmToMil(0.15)}` } </MenuItem>
                                    <MenuItem value={8}> { unitFormat === 'mm' ? 0.16 : `${ mmToMil(0.16)}` } </MenuItem>
                                    <MenuItem value={9}> { unitFormat === 'mm' ? 0.18 : `${ mmToMil(0.18)}` } </MenuItem>
                                    <MenuItem value={10}> { unitFormat === 'mm' ? 0.20 : `${ mmToMil(0.20)}` } </MenuItem>
                                    <MenuItem value={11}> { unitFormat === 'mm' ? 0.25 : `${ mmToMil(0.25)}` } </MenuItem>
                                    <MenuItem value={12}> { unitFormat === 'mm' ? 0.30 : `${ mmToMil(0.30)}` } </MenuItem>
                                </Select>
                            </FormControl>

                             {/*FRAME Field*/}
                            {stencilFrameId &&
                                <FormControl disabled={stencilTypeId === 1}>
                                    <InputLabel id="frame-label">
                                        {t("calculators.step3.fields.frame")}
                                    </InputLabel>
                                    <Select
                                        labelId="frame"
                                        id="frame"
                                        value={stencilFrameId}
                                        label={t("calculators.step3.fields.frame")}
                                        onChange={(e) => {
                                            setStencilFrameId(e.target.value);
                                            if (repeatOrderMode.lockPrice) {
                                                setIsStencilModifiedOnLockedPrice(true);
                                            }
                                        }}
                                    >
                                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                                    </Select>
                                </FormControl>
                            }

                            <Box className='customer-info'>
                                <Info/>
                                <span>{t("calculators.step3.calendar.orderStencilInfo")}</span>
                            </Box>
                        </>
                        :
                        <>
                            <Box component="span" sx={{zIndex: 10, height: '150px'}}>
                                {
                                    !props.isActive ?
                                        <>
                                            The stencil is currently unavailable, please contact support if necessary:
                                            <br/>
                                            quickturn@icape-group.com
                                        </>
                                        :
                                        repeatOrderMode?.background ?
                                            <>
                                                Stencil is not available in repeat order.
                                            </>
                                            :
                                            <>
                                                No stencil available for this technology.
                                            </>
                                }
                            </Box>
                            <Box className="no-stencil-container">
                                <BrowserNotSupportedIcon fontSize="large"/>
                            </Box>
                        </>
                    }

                </Box>
        </>
    )
}

export default StencilFields