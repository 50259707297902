import React from 'react';
import BackgroundImage from './bg.jpg';
import './widget.scss';
import {Box} from "@mui/material";

function Widget() {
    return (
        <>
            <img src={BackgroundImage} alt="PCB background login" className="background-image"/>
            {/*<Box className="background-image"/>*/}
        </>
    )
}

export default Widget;
