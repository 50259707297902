import {axios, BASE_URL} from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const authStorage = localStorage.getItem("auth");

    //Sylius doesn't use a refresh token. Just comment this code to use refresh token in the futur.
    // return async () => {
    //
    //     const response = await axios.post('/api/token/refresh', JSON.stringify({"refresh_token": authStorage.refresh_token}),
    //         {
    //             headers: {'Content-Type': 'application/json'},
    //             withCredentials: true
    //         }
    //     );
    //     setAuth(prev => {
    //         console.log(JSON.stringify(prev));
    //         console.log(response.data.token);
    //         return {...prev, token: response.data.refresh_token}
    //     });
    //     return response.data.token;
    // };

    return async () => {
        let credentials = JSON.parse(authStorage);
        const response = await axios.post(BASE_URL+'/shop/authentication-token',
            JSON.stringify({"email": credentials.username, "password": credentials.userPassword}),
            {
                headers: {'Content-Type': 'application/json'},
                // withCredentials: true
            }
        );
        setAuth(prev => {
            return {...prev, token: response?.data?.token}
        });
        return response.data.token;
    };
};

export default useRefreshToken;
