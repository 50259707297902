import React, {useContext, useEffect, useMemo} from "react";
import debounce from "lodash.debounce";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import './step-1.scss';
import Typography from "@mui/material/Typography";
import Panelization from "../../components/panelization/Panelization";
import CalculatorRigidContext from "../../../../context/calculatorRigidProvider";
import {inchToMm, mmToInch} from "../../../../tools/unit.utils";
import {useTranslation} from "react-i18next";
import GerberDropzone from "../../components/gerber-dropzone";
import DeliveryFormatSwitch from "../../components/delivery-format-switch";
import {extremeSizeValues, maxPcbCalculator, panelSizeCheck, pcbSizeCheck} from "../../../../tools/quantity-checks";

function Step1(props) {
    const {t} = useTranslation();

    const {
        isJaydaPcbQuotable, repeatOrderMode,
        quantity, setQuantity,
        unitFormat,
        analyzeInProgress,
        technologyId, setTechnologyId,
        pcbLength, setPcbLength,
        pcbWidth, setPcbWidth,
        solderMaskId, setSolderMaskId,
        smallestDrillingHoleSizeId, setSmallestDrillingHoleSizeId,
        tracksWidthAndSpaceId, setTracksWidthAndSpaceId,
        legendId, setLegendId,
        setPanelQuantity,
        pcbPerPanel, setPcbPerPanel,
        xoutId, setXoutId,
        userGerber, setUserGerber,
        pcbDeliveryFormat, setPcbDeliveryFormat,
        isLockFields,
        isStepOneToFinalize,
        pcbLengthIn, setPcbLengthIn,
        pcbWidthIn, setPcbWidthIn,
        panelLength, setPanelLength,
        panelWidth, setPanelWidth,
        panelLengthIn, setPanelLengthIn,
        panelWidthIn, setPanelWidthIn,
        lockCustomPanelizationFields, setLockCustomPanelizationFields,
        customPanelizationSettings, setCustomPanelizationSettings,
    } = useContext(CalculatorRigidContext);


    const handleDropFile = props.handleDropFile;


    // PANELIZATION SETUP
    const showPanelization = props.showPanelization
    const setShowPanelization = props.setShowPanelization
    const handleShowPanelization = () => {
        let state = !showPanelization;
        setShowPanelization(state);
        window.scrollTo(0,0);
    }
    const handleSavePanelization = (data) => {
        console.log("data", data);
        setLockCustomPanelizationFields(true);
        setPanelQuantity(Math.ceil(quantity / data.quantityPcbPanelization));
        setQuantity(data.quantityPcbPanelization);
        setPcbPerPanel(data.quantityPcbPanelization);

        //Panelization
        setCustomPanelizationSettings(data.panelizationSettings);
        setPcbDeliveryFormat('custom');

        // MM
        setPcbWidth(data.pcbWidth);
        setPcbLength(data.pcbHeight);
        setPanelWidth(data.panelWidth["mm"]);
        setPanelLength(data.panelHeight["mm"]);

        // INCH
        setPcbWidthIn(data.pcbWidthIn);
        setPcbLengthIn(data.pcbHeightIn);
        setPanelWidthIn(data.panelWidth["in"]);
        setPanelLengthIn(data.panelHeight["in"]);

        handleShowPanelization();
        window.scrollTo(0, 0);
    }



    // handle PCB length field in mm and inch
    const handleMmInchPcbLength = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPcbLength('');
            setPcbLengthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPcbLength(newValue);
            setPcbLengthIn(mmToInch(newValue));
        } else {
            setPcbLength(inchToMm(newValue));
            setPcbLengthIn(newValue);
        }
    }
    // handle PCB width field in mm and inch
    const handleMmInchPcbWidth = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPcbWidth('');
            setPcbWidthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPcbWidth(newValue);
            setPcbWidthIn(mmToInch(newValue));
        } else {
            setPcbWidth(inchToMm(newValue));
            setPcbWidthIn(newValue);
        }
    };
    // handle PANEL length field in mm and inch
    const handleMmInchPanelLength = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPanelLength('');
            setPanelLengthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPanelLength(newValue);
            setPanelLengthIn(mmToInch(newValue));
        } else {
            setPanelLength(inchToMm(newValue));
            setPanelLengthIn(newValue);
        }
    }
    // handle PANEL width field in mm and inch
    const handleMmInchPanelWidth = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPanelWidth('');
            setPanelWidthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPanelWidth(newValue);
            setPanelWidthIn(mmToInch(newValue));
        } else {
            setPanelWidth(inchToMm(newValue));
            setPanelWidthIn(newValue);
        }
    }
    const handlePcbPerPanel = (nbPcb) => {
       if (nbPcb > maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength) || nbPcb < 1) {
           return
       }
        setPcbPerPanel(nbPcb);
    }


    // Checking all dimensions
    const dimensionsCheck = (_unitFormat, _pcbLength, _pcbLengthIn, _pcbWidth, _pcbWidthIn, _panelLength, _panelLengthIn, _panelWidth, _panelWidthIn) => {
        pcbSizeCheck(_unitFormat, _pcbLength, _pcbLengthIn, setPcbLength, setPcbLengthIn, 'length');
        pcbSizeCheck(_unitFormat, _pcbWidth, _pcbWidthIn, setPcbWidth, setPcbWidthIn, 'width');
        panelSizeCheck(_unitFormat, _panelLength, _panelLengthIn, _pcbLength, _pcbLengthIn, setPanelLength, setPanelLengthIn, 'length');
        panelSizeCheck(_unitFormat, _panelWidth, _panelWidthIn, _pcbWidth, _pcbWidthIn, setPanelWidth, setPanelWidthIn, 'width');
    }

    //debounce useEffect to give the user time to type any number in fields.
    const debounceDimensions = useMemo(() => debounce(dimensionsCheck, 800), []);

    // DEBOUNCE LAUNCHER
    useEffect(() => {
        debounceDimensions(unitFormat, pcbLength, pcbLengthIn, pcbWidth, pcbWidthIn, panelLength, panelLengthIn, panelWidth, panelWidthIn);
    }, [pcbLength, pcbLengthIn, pcbWidth, pcbWidthIn, panelLength, panelLengthIn, panelWidth, panelWidthIn]);
    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return () => {
            debounceDimensions.cancel();
        }
    }, []);


    return (
        <>
            {showPanelization ? <Panelization
                    handleShowPanelization={handleShowPanelization}
                    handleSavePanelization={handleSavePanelization}
                    lockCustomPanelizationFields={lockCustomPanelizationFields}
                    isLockFields={isLockFields}
                    unitFormat={unitFormat}
                    size={{ pcbWidth, pcbLength, pcbWidthIn, pcbLengthIn, customPanelizationSettings }}/> :
                <>
                    {/*SWITCHER + DRAG N DROP*/}
                    <Box className={"technical-data-card-column step1-form-settings"}>

                        {/*DRAG N DROP GERBER*/}
                        <GerberDropzone
                            gerber={{userGerber, setUserGerber, handleDropFile}}
                            jayda={{analyzeInProgress, isJaydaPcbQuotable}}
                            lock={{repeatOrderMode, isLockFields}}/>


                        {/*SWITCHER*/}
                        <DeliveryFormatSwitch
                            pcbDeliveryFormat={{pcbDeliveryFormat, setPcbDeliveryFormat}}
                            panelization={{isCustomPanelization: true, handleShowPanelization, lockCustomPanelizationFields}}
                            isLockFields={isLockFields}/>
                    </Box>

                    {/*SINGLE PCB / GERBER PANELIZATION*/}
                    <Box className="technical-data-card step1-settings">

                        {/*TITLE SINGLE PCB - PANELIZATION*/}
                        <Typography variant={'h3'}>
                            {
                                pcbDeliveryFormat === 'single' ?
                                    t("calculators.step1.deliveryFormat.singlePcb")
                                    :
                                    (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                                    t("calculators.step1.deliveryFormat.panelization")
                            }
                        </Typography>

                        {/*FIELDS*/}
                        <Box className="technical-data-card-column-fields">

                            {/*PCB LENGTH*/}
                            <TextField
                                id="pcbLength"
                                label={t("calculators.step1.fields.pcbLength")}
                                value={unitFormat === 'mm' ? pcbLength : pcbLengthIn}
                                onChange={e => handleMmInchPcbLength(parseFloat(e.target.value))}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                disabled={isLockFields || lockCustomPanelizationFields}
                                error={pcbLength === '' && isStepOneToFinalize}
                                helperText={`${t("common.dimensions")} ${extremeSizeValues("pcb","length", unitFormat)}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                            />

                            {/*PCB WIDTH*/}
                            <TextField
                                id="pcbWidth"
                                label={t("calculators.step1.fields.pcbWidth")}
                                value={unitFormat === 'mm' ? pcbWidth : pcbWidthIn}
                                onChange={e => handleMmInchPcbWidth(parseFloat(e.target.value))}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                disabled={isLockFields || lockCustomPanelizationFields}
                                error={pcbWidth === '' && isStepOneToFinalize}
                                helperText={`${t("common.dimensions")} ${extremeSizeValues("pcb","width", unitFormat)}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                            />

                            {
                                (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                                <>
                                    {/* PANEL LENGTH */}
                                    <TextField
                                        id="panelLength"
                                        label={t("calculators.step1.fields.panelLength")}
                                        value={unitFormat === 'mm' ? panelLength : panelLengthIn}
                                        onChange={e => handleMmInchPanelLength(parseFloat(e.target.value))}
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        required
                                        disabled={isLockFields || lockCustomPanelizationFields}
                                        error={panelLength === '' && isStepOneToFinalize}
                                        helperText={`${t("common.dimensions")} ${extremeSizeValues("panel","length", unitFormat)}`}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                        }}
                                    />

                                    {/* PANEL WIDTH */}
                                    <TextField
                                        id="panelWidth"
                                        label={t("calculators.step1.fields.panelWidth")}
                                        value={unitFormat === 'mm' ? panelWidth : panelWidthIn}
                                        onChange={e => handleMmInchPanelWidth(parseFloat(e.target.value))}
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        required
                                        disabled={isLockFields || lockCustomPanelizationFields}
                                        error={panelWidth === '' && isStepOneToFinalize}
                                        helperText={`${t("common.dimensions")} ${extremeSizeValues("panel","width", unitFormat)}`}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                        }}
                                    />

                                    {/* PCB PER PANEL */}
                                    <TextField
                                        id="pcbPerPanel"
                                        label={t("calculators.step1.fields.pcbPerPanel")}
                                        value={pcbPerPanel}
                                        onChange={e => handlePcbPerPanel(parseInt(e.target.value))}
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        required
                                        disabled={isLockFields || lockCustomPanelizationFields}
                                        error={pcbPerPanel === '' && isStepOneToFinalize}
                                    />
                                </>
                            }

                            {/* SOLDER MASK Field */}
                            <FormControl disabled={isLockFields} required
                                         error={solderMaskId === '' && isStepOneToFinalize}>
                                <InputLabel id="solderMask-label">{t("calculators.step1.fields.solderMask")}</InputLabel>
                                <Select
                                    labelId="solderMask-label"
                                    id="solderMask"
                                    value={solderMaskId}
                                    label={t("calculators.step1.fields.solderMask")}
                                    onChange={e => setSolderMaskId(e.target.value)}

                                >
                                    <MenuItem value={1}>{t("calculators.selectable.noSolderMask")}</MenuItem>
                                    <MenuItem value={2}>{t("calculators.selectable.top")}</MenuItem>
                                    <MenuItem value={3}>{t("calculators.selectable.bottom")}</MenuItem>
                                    <MenuItem value={4}>{t("calculators.selectable.topBottom")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/* STACK UP Field */}
                            <FormControl disabled={isLockFields} required
                                         error={technologyId === '' && isStepOneToFinalize}>
                                <InputLabel id="stackUp-label">{t("calculators.step1.fields.stackUp")}</InputLabel>
                                <Select
                                    labelId="stackUp-label"
                                    id="stackUp"
                                    value={technologyId}
                                    label={t("calculators.step1.fields.stackUp")}
                                    onChange={(e) => setTechnologyId(e.target.value)}
                                >
                                    <MenuItem value={1}>{t("calculators.selectable.singleSide")}</MenuItem>
                                    <MenuItem value={2}>{t("calculators.selectable.doubleSide")}</MenuItem>
                                    <MenuItem value={3}>4 {t("common.layers")}</MenuItem>
                                    <MenuItem value={4}>6 {t("common.layers")}</MenuItem>
                                    <MenuItem value={5}>8 {t("common.layers")}</MenuItem>
                                    <MenuItem value={6}>10 {t("common.layers")}</MenuItem>
                                    <MenuItem value={8}>{t("calculators.selectable.noCopper")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/* SMALLEST DRILLING HOLE SIZE Field */}
                            <FormControl disabled={isLockFields} required
                                         error={smallestDrillingHoleSizeId === '' && isStepOneToFinalize}>
                                <InputLabel id="smallestDrillingHoleSize-label">{t("calculators.step1.fields.smallestDrillingHoleSize")}</InputLabel>
                                <Select
                                    labelId="smallestDrillingHoleSize-label"
                                    id="smallestDrillingHoleSize"
                                    value={smallestDrillingHoleSizeId}
                                    onChange={(e) => setSmallestDrillingHoleSizeId(e.target.value)}
                                    label={t("calculators.step1.fields.smallestDrillingHoleSize")}
                                    endAdornment={<InputAdornment className="select-input-adornment" position="end">{unitFormat}</InputAdornment>}
                                >
                                    <MenuItem value={1}> { unitFormat === 'mm' ? ">= 0.2" : `>=  ${ mmToInch(0.2)}` } </MenuItem>
                                    <MenuItem value={2}> { unitFormat === 'mm' ? "< 0.2" : `<  ${ mmToInch(0.2)}` } </MenuItem>
                                </Select>
                            </FormControl>

                            {/* TRACKS WIDTH AND SPACE Field */}
                            <FormControl disabled={isLockFields} required
                                         error={tracksWidthAndSpaceId === '' && isStepOneToFinalize}>
                                <InputLabel id="tracks-width--spaces-label">{t("calculators.step1.fields.trackWidthSpaces")}</InputLabel>
                                <Select
                                    labelId="tracks-width--spaces-label"
                                    id="tracks-width--spaces"
                                    value={tracksWidthAndSpaceId}
                                    onChange={(e) => setTracksWidthAndSpaceId(e.target.value)}
                                    label={t("calculators.step1.fields.trackWidthSpaces")}
                                    endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                        {unitFormat === 'mm' ? "mm" : "mil"}
                                    </InputAdornment>}
                                >
                                    <MenuItem value={1}> { unitFormat === 'mm' ? ">= 0.1" : `>=  ${ mmToInch(100)}` } </MenuItem>
                                    <MenuItem value={2}> { unitFormat === 'mm' ? "< 0.1" : `<  ${ mmToInch(100)}` } </MenuItem>
                                </Select>
                            </FormControl>

                            {/* LEGEND Field */}
                            <FormControl disabled={isLockFields} required
                                         error={legendId === '' && isStepOneToFinalize}>
                                <InputLabel id="legend-label">{t("calculators.step1.fields.legend")}</InputLabel>
                                <Select
                                    labelId="legend-label"
                                    id="legend"
                                    value={legendId}
                                    onChange={(e) => setLegendId(e.target.value)}
                                    label={t("calculators.step1.fields.legend")}
                                >
                                    <MenuItem value={1}>{t("calculators.selectable.noLegend")}</MenuItem>
                                    <MenuItem value={2}>{t("calculators.selectable.top")}</MenuItem>
                                    <MenuItem value={3}>{t("calculators.selectable.bottom")}</MenuItem>
                                    <MenuItem value={4}>{t("calculators.selectable.topBottom")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/*XOUT AND CUSTOM PANELIZATION*/}
                            { (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                                <>
                                    {/* XOUT Field */}
                                    <FormControl disabled={isLockFields} required
                                                 error={xoutId === '' && isStepOneToFinalize}>
                                        <InputLabel id="xOut-label">{t("calculators.step1.fields.xOut")}</InputLabel>
                                        <Select
                                            labelId="xOut-label"
                                            id="xOut"
                                            value={xoutId}
                                            label={t("calculators.step1.fields.xOut")}
                                            onChange={(e) => setXoutId(e.target.value)}
                                        >
                                            <MenuItem value={1}>{t("common.yes")} (10%)</MenuItem>
                                            <MenuItem value={2}>{t("common.no")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>}

                        </Box>
                    </Box>
                </>
            }
        </>
    )
}

export default Step1;
