import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {LinearProgress} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const CalculatorStepper = (props) => {

    const {t} = useTranslation();

    const {analyzeInProgress, progress} = props.jayda
    const {checkIfAllFieldsAreFilled} = props.field
    const {activeStep, setActiveStep, completed, steps} = props.step

    const handleChangeStepFromStepper = (index) => {
        if(!checkIfAllFieldsAreFilled() && index === 2 )
        {
            setActiveStep(1)
        }else {

            setActiveStep(index);
        }
    };

    return(
        <>
            <Box className="stepperBlock">
                <Stepper nonLinear activeStep={activeStep} sx={{zIndex: '10'}}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={e => handleChangeStepFromStepper(index)}
                                        disableRipple>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {/*JAYDA'S BAR*/}
                <Box className={(analyzeInProgress === 'inProgress') ? 'stepper-loading-bar show-stepper-bar' : 'stepper-loading-bar'}>
                    <Typography component={"span"}>
                        {t("calculators.step1.gerber.loadingData")}
                    </Typography>
                    <Box>
                        <LinearProgress
                            variant="determinate"
                            value={parseInt(progress)}
                            color='success'
                            style={{
                                height: '5px'
                            }}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default CalculatorStepper