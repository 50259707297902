import React, {useContext, useEffect} from "react";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, Slide} from "@mui/material";
import './step-2.scss';
import Typography from "@mui/material/Typography";
import CalculatorHdiContext from "../../../../context/calculatorHdiProvider";
import {mmToMil} from "../../../../tools/unit.utils";
import {useTranslation} from "react-i18next";

function Step2() {
    const {t} = useTranslation();

    const {
        isLockFields, unitFormat,
        technologyId, setTechnologyId,
        solderMaskId,
        finishedInnerCuId, setFinishedInnerCuId,
        finishedOuterCuId, setFinishedOuterCuId,
        ipc3Id, setIpc3Id,
        resinFillingId, setResinFillingId,
        copperFillingId, setCopperFillingId,
        rawMaterialId, setRawMaterialId,
        boardThicknessId, setBoardThicknessId,
        stepsNumberId, setstepsNumberId,
        solderMaskColorId, setSolderMaskColorId,
        finishingKey, setFinishingKey,
        peelableMaskOptionId, setPeelableMaskOptionId,
        impedanceControlId, setImpedanceControlId,
        pressFitHoleId, setpressFitHoleId,
        counterSinkHoleId, setcounterSinkHoleId,
        laserDrillingId, setLaserDrillingId,
        buriedViaId, setBuriedViaId,
        halfHoleId, sethalfHoleId,
        blindViaId, setBlindViaId
    } = useContext(CalculatorHdiContext);


    return (
        <>
            {/*TECHNOLOGY*/}
            <Box className={"technical-data-card rigid-step-2-form"}>
                <Typography variant={'h3'}>{t("calculators.step2.fields.technology.name")}</Typography>

                {/* STACK UP Field */}
                <FormControl disabled={true}>
                    <InputLabel id="stackUp-label">
                        {t("calculators.step1.fields.stackUp")}
                    </InputLabel>
                    <Select
                        labelId="stackUp"
                        id="stackUp"
                        value={technologyId}
                        label={t("calculators.step1.fields.stackUp")}
                        onChange={(e) => setTechnologyId(e.target.value)}
                    >
                        <MenuItem value={21} disabled={true}>{t("calculators.selectable.singleSide")}</MenuItem>
                        <MenuItem value={22} disabled={true}>{t("calculators.selectable.doubleSide")}</MenuItem>
                        <MenuItem value={23}>4 {t("common.layers")}</MenuItem>
                        <MenuItem value={24}>6 {t("common.layers")}</MenuItem>
                        <MenuItem value={25}>8 {t("common.layers")}</MenuItem>
                        <MenuItem value={26}>10 {t("common.layers")}</MenuItem>
                        <MenuItem value={27}>12 {t("common.layers")}</MenuItem>
                    </Select>
                </FormControl>

                {/* INNER COPPER Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="innerCopper-label">
                        {t("calculators.step2.fields.technology.innerCopper")}
                    </InputLabel>
                    <Select
                        labelId="innerCopper"
                        id="innerCopper"
                        value={finishedInnerCuId}
                        label="Inner-copper"
                        onChange={(e) => setFinishedInnerCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1}> { unitFormat === 'mm' ? 12 : "1/3" } </MenuItem>
                        <MenuItem value={2}> { unitFormat === 'mm' ? 18 : "1/2" } </MenuItem>
                        <MenuItem value={3}> { unitFormat === 'mm' ? 35 : 1 } </MenuItem>
                        <MenuItem value={4}> { unitFormat === 'mm' ? 70 : 2 } </MenuItem>
                        <MenuItem value={5}> { unitFormat === 'mm' ? 105 : 3 } </MenuItem>
                    </Select>
                </FormControl>

                {/* OUTER COPPER FINISHED Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="outerCopperFinished-label">
                        {t("calculators.step2.fields.technology.outerCopperFinished")}
                    </InputLabel>
                    <Select
                        labelId="outerCopperFinished"
                        id="outerCopperFinished"
                        value={finishedOuterCuId}
                        label={t("calculators.step2.fields.technology.outerCopperFinished")}
                        onChange={(e) => setFinishedOuterCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1}> { unitFormat === 'mm' ? 35 : 1} </MenuItem>
                    </Select>
                </FormControl>

                {/* IPC Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="ipc-label">
                        {t("calculators.step2.fields.technology.ipc")}
                    </InputLabel>
                    <Select
                        labelId="ipc"
                        id="ipc"
                        value={ipc3Id}
                        label={t("calculators.step2.fields.technology.ipc")}
                        onChange={(e) => setIpc3Id(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.class2")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.class3")}</MenuItem>
                    </Select>
                </FormControl>

                {/* RESIN FILLING Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="resinFilling-label">
                        {t("calculators.step2.fields.technology.resinFilling")}
                    </InputLabel>
                    <Select
                        labelId="resinFilling"
                        id="resinFilling"
                        value={resinFillingId}
                        label={t("calculators.step2.fields.technology.resinFilling")}
                        onChange={(e) => setResinFillingId(e.target.value)}
                    >
                        <MenuItem value={1}>0 {t("common.time")}</MenuItem>
                        <MenuItem value={2}>1 {t("common.time")}</MenuItem>
                        <MenuItem value={3}>2 {t("common.times")}</MenuItem>
                        <MenuItem value={4}>3 {t("common.times")}</MenuItem>
                    </Select>
                </FormControl>

                {/* COPPER FILLING Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="copperFilling-label">
                        {t("calculators.step2.fields.technology.copperFilling")}
                    </InputLabel>
                    <Select
                        labelId="copperFilling"
                        id="copperFilling"
                        value={copperFillingId}
                        label={t("calculators.step2.fields.technology.copperFilling")}
                        onChange={(e) => setCopperFillingId(e.target.value)}
                    >
                        <MenuItem value={1}>0 {t("common.time")}</MenuItem>
                        <MenuItem value={2}>1 {t("common.time")}</MenuItem>
                        <MenuItem value={3}>2 {t("common.times")}</MenuItem>
                        <MenuItem value={4}>3 {t("common.times")}</MenuItem>
                    </Select>
                </FormControl>

            </Box>

            {/*MATERIAL & PCB DETAILS*/}
            <Box className={"technical-data-card-column rigid-step-2-form"}>
                {/*MATERIAL*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.material.name")}
                    </Typography>

                    {/* RAW MATERIAL TG VALUE Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="rawMaterialId-label">
                            {t("calculators.step2.fields.material.rawMaterialTgValue")}
                        </InputLabel>
                        <Select
                            labelId="rawMaterialId"
                            id="rawMaterialId"
                            value={rawMaterialId}
                            label={t("calculators.step2.fields.material.rawMaterialTgValue")}
                            onChange={(e) => setRawMaterialId(e.target.value)}
                        >
                            <MenuItem value={1}>FR4 Tg 170</MenuItem>
                        </Select>
                    </FormControl>

                    {/* PCB THICKNESS Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="boardThickness-label">
                            {t("calculators.step2.fields.material.pcbThickness")}
                        </InputLabel>
                        <Select
                            labelId="boardThickness"
                            id="boardThickness"
                            value={boardThicknessId}
                            label={t("calculators.step2.fields.material.pcbThickness")}
                            onChange={(e) => setBoardThicknessId(e.target.value)}
                            endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                {unitFormat === 'mm' ? unitFormat : "mil"}
                            </InputAdornment>}
                        >
                            <MenuItem value={1}> { unitFormat === 'mm' ? 0.8 : `${ mmToMil(0.8)}` } </MenuItem>
                            <MenuItem value={2}> { unitFormat === 'mm' ? 1 : `${ mmToMil(1)}` } </MenuItem>
                            <MenuItem value={3}> { unitFormat === 'mm' ? 1.2 : `${ mmToMil(1.2)}` } </MenuItem>
                            <MenuItem value={4}> { unitFormat === 'mm' ? 1.6 : `${ mmToMil(1.6)}` } </MenuItem>
                            <MenuItem value={5}> { unitFormat === 'mm' ? 2 : `${ mmToMil(2)}` } </MenuItem>
                        </Select>
                    </FormControl>

                    {/* NUMBER OF STEPS Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="stepsNumber-label">
                            {t("calculators.step2.fields.material.stepsNumber")}
                        </InputLabel>
                        <Select
                            labelId="stepsNumber"
                            id="stepsNumber"
                            value={stepsNumberId}
                            label={t("calculators.step2.fields.material.stepsNumber")}
                            onChange={(e) => setstepsNumberId(e.target.value)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2} disabled={technologyId === 23}>2</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/*PCB DETAILS*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.pcbDetails.name")}
                    </Typography>

                    {/* SOLDER MASK COLOR Field */}
                    <FormControl disabled={isLockFields || (solderMaskId === "") || (solderMaskId === 1)}>
                        <InputLabel id="solderMaskColor-label">
                            {t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                        </InputLabel>
                        <Select
                            labelId="solderMaskColor"
                            id="solderMaskColor"
                            value={solderMaskColorId}
                            label={t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                            onChange={(e) => setSolderMaskColorId(e.target.value)}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-green"></div>{t("calculators.selectable.colors.green")}</div></MenuItem>
                            <MenuItem value={3}><div className="color-dot-wrapper"><div className="color-dot color-dot-blue"></div>{t("calculators.selectable.colors.blue")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* FINISHING Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="finishing-label">
                            {t("calculators.step2.fields.pcbDetails.finishing")}
                        </InputLabel>
                        <Select
                            labelId="finishing"
                            id="finishing"
                            value={finishingKey}
                            label={t("calculators.step2.fields.pcbDetails.finishing")}
                            onChange={(e) => setFinishingKey(e.target.value)}
                        >
                            <MenuItem value={1}>O.S.P</MenuItem>
                            <MenuItem value={2}>HASL-LF</MenuItem>
                            <MenuItem value={3}>ENIG 0.05µm</MenuItem>
                            <MenuItem value={4}>{t("calculators.selectable.immersionSilver")}</MenuItem>
                            <MenuItem value={5}>{t("calculators.selectable.immersionTin")}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* PEELABLE MASK OPTION Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="peelableMaskOption-label">Pealable soldermask</InputLabel>
                        <Select
                            labelId="peelableMaskOption"
                            id="peelableMaskOption"
                            value={peelableMaskOptionId}
                            label="peelableMaskOption"
                            onChange={(e) => setPeelableMaskOptionId(e.target.value)}
                        >
                            <MenuItem value={1}>{t("common.no")}</MenuItem>
                            <MenuItem value={2}>{t("common.yes")}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {/*ADVANCED OPTIONS*/}
            <Box className="technical-data-card rigid-step-2-form">
                <Typography variant={'h3'}>
                    {t("calculators.step2.fields.advancedOption.name")}
                </Typography>

                {/* IMPEDANCE CONTROL Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="impedanceControl-label">
                        {t("calculators.step2.fields.advancedOption.impedanceControl")}
                    </InputLabel>
                    <Select
                        labelId="impedanceControl"
                        id="impedanceControl"
                        value={impedanceControlId}
                        label={t("calculators.step2.fields.advancedOption.impedanceControl")}
                        onChange={(e) => setImpedanceControlId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* PRESS FIT HOLES Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="pressFitHoleId-label">
                        {t("calculators.step2.fields.advancedOption.pressFitHoles")}
                    </InputLabel>
                    <Select
                        labelId="pressFitHoleId"
                        id="pressFitHoleId"
                        value={pressFitHoleId}
                        label={t("calculators.step2.fields.advancedOption.pressFitHoles")}
                        onChange={(e) => setpressFitHoleId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* COUNTER SINK HOLES Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="counterSink-label">
                        {t("calculators.step2.fields.advancedOption.counterSinkHoles")}
                    </InputLabel>
                    <Select
                        labelId="counterSink"
                        id="counterSink"
                        value={counterSinkHoleId}
                        label={t("calculators.step2.fields.advancedOption.counterSinkHoles")}
                        onChange={(e) => setcounterSinkHoleId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* LASER DRILLING Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="laserDrilling-label">
                        {t("calculators.step2.fields.advancedOption.laserDrilling")}
                    </InputLabel>
                    <Select
                        labelId="laserDrilling"
                        id="laserDrilling"
                        value={laserDrillingId}
                        label={t("calculators.step2.fields.advancedOption.laserDrilling")}
                        onChange={(e) => setLaserDrillingId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* BURIED VIA Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="buriedVia-label">
                        {t("calculators.step2.fields.advancedOption.buriedVia")}
                    </InputLabel>
                    <Select
                        labelId="buriedVia"
                        id="buriedVia"
                        value={buriedViaId}
                        label={t("calculators.step2.fields.advancedOption.buriedVia")}
                        onChange={(e) => setBuriedViaId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* HALF HOLES Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="halfHoles-label">
                        {t("calculators.step2.fields.advancedOption.halfHoles")}
                    </InputLabel>
                    <Select
                        labelId="halfHoles"
                        id="halfHoles"
                        value={halfHoleId}
                        label={t("calculators.step2.fields.advancedOption.halfHoles")}
                        onChange={(e) => sethalfHoleId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* BLIND VIA Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="blindVia-label">
                        {t("calculators.step2.fields.advancedOption.blindVia")}
                    </InputLabel>
                    <Select
                        labelId="blindVia"
                        id="blindVia"
                        value={blindViaId}
                        label={t("calculators.step2.fields.advancedOption.blindVia")}
                        onChange={(e) => setBlindViaId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

            </Box>
        </>
    )
}

export default Step2;
