import React, {useContext, useEffect} from "react";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import './step-2.scss';
import Typography from "@mui/material/Typography";
import CalculatorRigidContext from "../../../../context/calculatorRigidProvider";
import {mmToMil} from "../../../../tools/unit.utils";
import {useTranslation} from "react-i18next";

function Step2(props) {
    const {t} = useTranslation();

    const {
        isLockFields, unitFormat,
        technologyId, setTechnologyId,
        rawMaterialId, setRawMaterialId,
        finishedInnerCuId, setFinishedInnerCuId,
        boardThicknessId, setBoardThicknessId,
        basedOuterCuId, setBasedOuterCuId,
        solderMaskColorId, setSolderMaskColorId,
        finishedOuterCuId, setFinishedOuterCuId,
        finishingKey, setFinishingKey,
        resinFillingId, setResinFillingId,
        extraPrepregsId, setExtraPrepregsId,
        legendColorId, setLegendColorId,
        pressFitHoleId, setPressFitHoleId,
        ipc3Id, setIpc3Id,
        ulCountryId, setUlCountryId,
        vCutId, setVCutId,
        ionicContaminationId, setIonicContaminationId,
        cti600Id, setCti600Id,
        impedanceControlId, setImpedanceControlId,
        solderMaskId, legendId,
        pluggingViaId, setPluggingViaId,
        carbonInkId, setCarbonInkId,
        maskOptionId, setMaskOptionId
    } = useContext(CalculatorRigidContext);



    const isOuterFinishCopperItemsActive = props.isOuterFinishCopperItemsActive;



    return (
        <>
            {/*TECHNOLOGY*/}
            <Box className={"technical-data-card rigid-step-2-form"}>
                <Typography variant={'h3'}>{t("calculators.step2.fields.technology.name")}</Typography>

                {/* STACK UP Field */}
                <FormControl disabled={true}>
                    <InputLabel id="stackUp-label">
                        {t("calculators.step1.fields.stackUp")}
                    </InputLabel>
                    <Select
                        labelId="stackUp"
                        id="stackUp"
                        value={technologyId}
                        label={t("calculators.step1.fields.stackUp")}
                        onChange={(e) => setTechnologyId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.singleSide")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.doubleSide")}</MenuItem>
                        <MenuItem value={3}>4 {t("common.layers")}</MenuItem>
                        <MenuItem value={4}>6 {t("common.layers")}</MenuItem>
                        <MenuItem value={5}>8 {t("common.layers")}</MenuItem>
                        <MenuItem value={6}>10 {t("common.layers")}</MenuItem>
                        <MenuItem value={8}>{t("calculators.selectable.noCopper")}</MenuItem>
                    </Select>
                </FormControl>

                {/* EXTRA PREPREGS Field */}
                <FormControl disabled={isLockFields || technologyId <= 2}>
                    <InputLabel id="extraPrepregs-label">
                        {t("calculators.step2.fields.technology.extraPrepregs")}
                    </InputLabel>
                    <Select
                        labelId="extraPrepregs"
                        id="extraPrepregs"
                        value={technologyId <= 2 ? 1 : extraPrepregsId}
                        label={t("calculators.step2.fields.technology.extraPrepregs")}
                        onChange={(e) => setExtraPrepregsId(e.target.value)}
                    >
                        <MenuItem value={1}>0</MenuItem>
                        <MenuItem value={2}>1</MenuItem>
                        <MenuItem value={3}>2</MenuItem>
                        <MenuItem value={4}>3</MenuItem>
                        <MenuItem value={5}>4</MenuItem>
                        <MenuItem value={6}>5</MenuItem>
                        <MenuItem value={7}>6</MenuItem>
                        <MenuItem value={8}>7</MenuItem>
                        <MenuItem value={9}>8</MenuItem>
                        <MenuItem value={10}>9</MenuItem>
                        <MenuItem value={11}>10</MenuItem>
                    </Select>
                </FormControl>

                {/* INNER COPPER Field */}
                <FormControl disabled={isLockFields || (technologyId === "") || (technologyId <= 2)}>
                    <InputLabel id="innerCopper-label">
                        {t("calculators.step2.fields.technology.innerCopper")}
                    </InputLabel>
                    <Select
                        labelId="innerCopper"
                        id="innerCopper"
                        value={finishedInnerCuId}
                        label={t("calculators.step2.fields.technology.innerCopper")}
                        onChange={(e) => setFinishedInnerCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1} disabled={true}>--</MenuItem>
                        <MenuItem value={2}> { unitFormat === 'mm' ? 18 : "1/2"} </MenuItem>
                        <MenuItem value={3}> { unitFormat === 'mm' ? 35 : 1} </MenuItem>
                        <MenuItem value={4}> { unitFormat === 'mm' ? 70 : 2} </MenuItem>
                        <MenuItem value={5}> { unitFormat === 'mm' ? 105 : 3} </MenuItem>
                        <MenuItem value={6}> { unitFormat === 'mm' ? 140 : 4} </MenuItem>
                        <MenuItem value={7}> { unitFormat === 'mm' ? 175 : 5} </MenuItem>
                        <MenuItem value={8}> { unitFormat === 'mm' ? 210 : 6} </MenuItem>
                    </Select>
                </FormControl>

                {/* OUTER COPPER BASE Field */}
                <FormControl disabled={isLockFields || (technologyId === "")}>
                    <InputLabel id="pcbThickness-label">
                        {t("calculators.step2.fields.technology.outerCopperBase")}
                    </InputLabel>
                    <Select
                        labelId="outerCopperBase"
                        id="outerCopperBase"
                        value={basedOuterCuId}
                        label={t("calculators.step2.fields.technology.outerCopperBase")}
                        onChange={e => setBasedOuterCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1} disabled={true}>--</MenuItem>
                        <MenuItem value={2} disabled={(technologyId === 1)}> { unitFormat === 'mm' ? 12 : "1/3"} </MenuItem>
                        <MenuItem value={3} disabled={(technologyId === 1)}> { unitFormat === 'mm' ? 18 : "1/2"} </MenuItem>
                        <MenuItem value={4}> { unitFormat === 'mm' ? 35 : 1} </MenuItem>
                        <MenuItem value={5}> { unitFormat === 'mm' ? 70 : 1} </MenuItem>
                        <MenuItem value={6} disabled={(technologyId === 1)}> { unitFormat === 'mm' ? 105 : 3} </MenuItem>
                        <MenuItem value={7} disabled={(technologyId === 1)}> { unitFormat === 'mm' ? 140 : 4} </MenuItem>
                    </Select>
                </FormControl>

                {/* OUTER COPPER FINISHED Field */}
                <FormControl disabled={isLockFields || (technologyId === "")}>
                    <InputLabel id="outerCopperFinished-label">
                        {t("calculators.step2.fields.technology.outerCopperFinished")}
                    </InputLabel>
                    <Select
                        labelId="outerCopperFinished"
                        id="outerCopperFinished"
                        value={finishedOuterCuId}
                        label={t("calculators.step2.fields.technology.outerCopperFinished")}
                        onChange={(e) => setFinishedOuterCuId(e.target.value)}
                        endAdornment={<InputAdornment className="select-input-adornment" position="end">
                            {unitFormat === 'mm' ? "µm" : "oz"}
                        </InputAdornment>}
                    >
                        <MenuItem value={1} disabled={isOuterFinishCopperItemsActive[0]}>--</MenuItem>
                        <MenuItem value={2} disabled={isOuterFinishCopperItemsActive[1]}> { unitFormat === 'mm' ? 30 : 0.97} </MenuItem>
                        <MenuItem value={3} disabled={isOuterFinishCopperItemsActive[2]}> { unitFormat === 'mm' ? 35 : 1} </MenuItem>
                        <MenuItem value={4} disabled={isOuterFinishCopperItemsActive[3]}> { unitFormat === 'mm' ? 40 : 1.14} </MenuItem>
                        <MenuItem value={5} disabled={isOuterFinishCopperItemsActive[4]}> { unitFormat === 'mm' ? 50 : 1.38} </MenuItem>
                        <MenuItem value={6} disabled={isOuterFinishCopperItemsActive[5]}> { unitFormat === 'mm' ? 55 : 1.42} </MenuItem>
                        <MenuItem value={7} disabled={isOuterFinishCopperItemsActive[6]}> { unitFormat === 'mm' ? 70 : 2} </MenuItem>
                        <MenuItem value={8} disabled={isOuterFinishCopperItemsActive[7]}> { unitFormat === 'mm' ? 80 : 2.29} </MenuItem>
                        <MenuItem value={9} disabled={isOuterFinishCopperItemsActive[8]}> { unitFormat === 'mm' ? 85 : 2.43} </MenuItem>
                        <MenuItem value={10} disabled={isOuterFinishCopperItemsActive[9]}> { unitFormat === 'mm' ? 105 : 3} </MenuItem>
                        <MenuItem value={11} disabled={isOuterFinishCopperItemsActive[10]}> { unitFormat === 'mm' ? 110 : 3.14} </MenuItem>
                        <MenuItem value={12} disabled={isOuterFinishCopperItemsActive[11]}> { unitFormat === 'mm' ? 115 : 3.19} </MenuItem>
                        <MenuItem value={13} disabled={isOuterFinishCopperItemsActive[12]}> { unitFormat === 'mm' ? 140 : 4} </MenuItem>
                        <MenuItem value={14} disabled={isOuterFinishCopperItemsActive[13]}> { unitFormat === 'mm' ? 145 : 4.14} </MenuItem>
                    </Select>
                </FormControl>

                {/* IPC Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="ipc-label">
                        {t("calculators.step2.fields.technology.ipc")}
                    </InputLabel>
                    <Select
                        labelId="ipc"
                        id="ipc"
                        value={ipc3Id}
                        label={t("calculators.step2.fields.technology.ipc")}
                        onChange={(e) => setIpc3Id(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.class2")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.class3")}</MenuItem>
                    </Select>
                </FormControl>

                {/* PLUGGING VIA Field */}
                <FormControl disabled={isLockFields || technologyId <= 2}>
                    <InputLabel id="pluggingVia-label">
                        {t("calculators.step2.fields.technology.pluggingVia")}
                    </InputLabel>
                    <Select
                        labelId="pluggingVia-label"
                        id="pluggingViaId"
                        value={pluggingViaId}
                        label={t("calculators.step2.fields.technology.pluggingVia")}
                        onChange={(e) => setPluggingViaId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.noPlugging")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.top")}</MenuItem>
                        <MenuItem value={3}>{t("calculators.selectable.bottom")}</MenuItem>
                        <MenuItem value={4}>{t("calculators.selectable.topBottom")}</MenuItem>
                    </Select>
                </FormControl>

                {/* VIA FILLING MATERIAL Field */}
                <FormControl disabled={isLockFields || (pluggingViaId === "") || (pluggingViaId === 1)}>
                    <InputLabel id="viaFillingMaterial-label">
                        {t("calculators.step2.fields.technology.viaFillingMaterial")}
                    </InputLabel>
                    <Select
                        labelId="viaFillingMaterial"
                        id="viaFillingMaterial"
                        value={resinFillingId}
                        label={t("calculators.step2.fields.technology.viaFillingMaterial")}
                        onChange={(e) => setResinFillingId(e.target.value)}
                    >
                        <MenuItem value={1} disabled={true}>--</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.solderMask")}</MenuItem>
                        <MenuItem value={3}>{t("calculators.selectable.resin")}</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/*MATERIAL & PCB DETAILS*/}
            <Box className={"technical-data-card-column rigid-step-2-form"}>
                {/*MATERIAL*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.material.name")}
                    </Typography>

                    {/* RAW MATERIAL TG VALUE Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="rawMaterialId-label">
                            {t("calculators.step2.fields.material.rawMaterialTgValue")}
                        </InputLabel>
                        <Select
                            labelId="rawMaterialId"
                            id="rawMaterialId"
                            value={rawMaterialId}
                            label={t("calculators.step2.fields.material.rawMaterialTgValue")}
                            onChange={(e) => setRawMaterialId(e.target.value)}
                        >
                            <MenuItem value={1}>FR4 Tg 130</MenuItem>
                            <MenuItem value={2}>FR4 Tg 150</MenuItem>
                            <MenuItem value={3}>FR4 Tg 170</MenuItem>
                            <MenuItem value={4}>FR4 Tg 180</MenuItem>
                            <MenuItem disabled={technologyId !== 1} value={5}>CEM-1</MenuItem>
                            <MenuItem disabled value={6}>Rogers 4350</MenuItem>
                            <MenuItem disabled value={7}>Rogers 4003</MenuItem>
                        </Select>
                    </FormControl>

                    {/* PCB THICKNESS Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="pcbThickness-label">
                            {t("calculators.step2.fields.material.pcbThickness")}
                        </InputLabel>
                        <Select
                            labelId="pcbThickness"
                            id="pcbThickness"
                            value={boardThicknessId}
                            label={t("calculators.step2.fields.material.pcbThickness")}
                            onChange={(e) => setBoardThicknessId(e.target.value)}
                            endAdornment={<InputAdornment className="select-input-adornment" position="end">
                                {unitFormat === 'mm' ? unitFormat : "mil"}
                            </InputAdornment>}
                        >
                            <MenuItem value={1}> { unitFormat === 'mm' ? 0.6 : `${ mmToMil(0.6)}` } </MenuItem>
                            <MenuItem value={2}> { unitFormat === 'mm' ? 0.8 : `${ mmToMil(0.8)}` } </MenuItem>
                            <MenuItem value={3}> { unitFormat === 'mm' ? 1 : `${ mmToMil(1)}` } </MenuItem>
                            <MenuItem value={4}> { unitFormat === 'mm' ? 1.2 : `${ mmToMil(1.2)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={5}> { unitFormat === 'mm' ? 1.4 : `${ mmToMil(1.4)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={6}> { unitFormat === 'mm' ? 1.5 : `${ mmToMil(1.5)}` } </MenuItem>
                            <MenuItem value={7}> { unitFormat === 'mm' ? 1.6 : `${ mmToMil(1.6)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={8}> { unitFormat === 'mm' ? 1.8 : `${ mmToMil(1.8)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={9}> { unitFormat === 'mm' ? 2 : `${ mmToMil(2)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={10}> { unitFormat === 'mm' ? 2.4 : `${ mmToMil(2.4)}` } </MenuItem>
                            <MenuItem disabled={rawMaterialId === 6} value={11}> { unitFormat === 'mm' ? 3.2 : `${ mmToMil(3.2)}` } </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/*PCB DETAILS*/}
                <Box className="technical-data-card">
                    <Typography variant={'h3'}>
                        {t("calculators.step2.fields.pcbDetails.name")}
                    </Typography>

                    {/* SOLDER MASK COLOR Field */}
                    <FormControl disabled={isLockFields || (solderMaskId === "") || (solderMaskId === 1)}>
                        <InputLabel id="solderMaskColor-label">
                            {t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                        </InputLabel>
                        <Select
                            labelId="solderMaskColor"
                            id="solderMaskColor"
                            value={solderMaskColorId}
                            label={t("calculators.step2.fields.pcbDetails.solderMaskColor")}
                            onChange={(e) => setSolderMaskColorId(e.target.value)}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-green"></div>{t("calculators.selectable.colors.green")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-matte-green"></div>{t("calculators.selectable.colors.matteGreen")}</div></MenuItem>
                            <MenuItem value={5}><div className="color-dot-wrapper"><div className="color-dot color-dot-blue"></div>{t("calculators.selectable.colors.blue")}</div></MenuItem>
                            <MenuItem value={6}><div className="color-dot-wrapper"><div className="color-dot color-dot-matte-blue"></div>{t("calculators.selectable.colors.matteBlue")}</div></MenuItem>
                            <MenuItem value={7}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                            <MenuItem value={9}><div className="color-dot-wrapper"><div className="color-dot color-dot-matte-black"></div>{t("calculators.selectable.colors.matteBlack")}</div></MenuItem>
                            <MenuItem value={10}><div className="color-dot-wrapper"><div className="color-dot color-dot-white"></div>{t("calculators.selectable.colors.white")}</div></MenuItem>
                            <MenuItem value={11}><div className="color-dot-wrapper"><div className="color-dot color-dot-red"></div>{t("calculators.selectable.colors.red")}</div></MenuItem>
                            <MenuItem value={12}><div className="color-dot-wrapper"><div className="color-dot color-dot-yellow"></div>{t("calculators.selectable.colors.yellow")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* LEGEND COLOR Field */}
                    <FormControl disabled={isLockFields || (legendId === "") || (legendId === 1)}>
                        <InputLabel id="legendColor-label">
                            {t("calculators.step2.fields.pcbDetails.legendColor")}
                        </InputLabel>
                        <Select
                            labelId="legendColor"
                            id="legendColor"
                            value={legendColorId}
                            label={t("calculators.step2.fields.pcbDetails.legendColor")}
                            onChange={(e) => setLegendColorId(e.target.value)}
                        >
                            <MenuItem value={1} disabled={true}>--</MenuItem>
                            <MenuItem value={2}><div className="color-dot-wrapper"><div className="color-dot color-dot-white"></div>{t("calculators.selectable.colors.white")}</div></MenuItem>
                            <MenuItem value={3}><div className="color-dot-wrapper"><div className="color-dot color-dot-black"></div>{t("calculators.selectable.colors.black")}</div></MenuItem>
                            <MenuItem value={4}><div className="color-dot-wrapper"><div className="color-dot color-dot-yellow"></div>{t("calculators.selectable.colors.yellow")}</div></MenuItem>
                        </Select>
                    </FormControl>

                    {/* FINISHING Field */}
                    <FormControl disabled={isLockFields}>
                        <InputLabel id="finishing-label">
                            {t("calculators.step2.fields.pcbDetails.finishing")}
                        </InputLabel>
                        <Select
                            labelId="finishing"
                            id="finishing"
                            value={finishingKey}
                            label={t("calculators.step2.fields.pcbDetails.finishing")}
                            onChange={(e) => setFinishingKey(e.target.value)}
                        >
                            <MenuItem value={3}>O.S.P</MenuItem>
                            <MenuItem value={6}>HASL-SnPb</MenuItem>
                            <MenuItem value={1}>HASL-LF</MenuItem>
                            <MenuItem value={2}>ENIG 0.05µm</MenuItem>
                            <MenuItem value={5}>{t("calculators.selectable.immersionSilver")}</MenuItem>
                            <MenuItem value={4}>{t("calculators.selectable.immersionTin")}</MenuItem>
                            <MenuItem value={7}>{t("calculators.selectable.noFinishing")}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {/*ADVANCED OPTIONS*/}
            <Box className="technical-data-card rigid-step-2-form">
                <Typography variant={'h3'}>
                    {t("calculators.step2.fields.advancedOption.name")}
                </Typography>

                {/* UL COUNTRY Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="rawMaterial-label">
                        {t("calculators.step2.fields.advancedOption.ulCountry")}
                    </InputLabel>
                    <Select
                        labelId="ulCountry"
                        id="ulCountry"
                        value={ulCountryId}
                        label={t("calculators.step2.fields.advancedOption.ulCountry")}
                        onChange={(e) => setUlCountryId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.usa")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.canada")}</MenuItem>
                        <MenuItem value={3}>{t("calculators.selectable.usaCanda")}</MenuItem>
                        <MenuItem value={4}>{t("calculators.selectable.noUl")}</MenuItem>
                    </Select>
                </FormControl>

                {/* V CUT Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="rawMaterial-label">
                        {t("calculators.step2.fields.advancedOption.vCut")}
                    </InputLabel>
                    <Select
                        labelId="vcut"
                        id="vcut"
                        value={vCutId}
                        label={t("calculators.step2.fields.advancedOption.vCut")}
                        onChange={(e) => setVCutId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* IONIC CONTAMINATION Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="ionicContamination-label">
                        {t("calculators.step2.fields.advancedOption.ionicContamination")}
                    </InputLabel>
                    <Select
                        labelId="ionicContamination"
                        id="ionicContamination"
                        value={ionicContaminationId}
                        label={t("calculators.step2.fields.advancedOption.ionicContamination")}
                        onChange={(e) => setIonicContaminationId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.noValue")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.standardValue")}</MenuItem>
                    </Select>
                </FormControl>

                {/* CTI 600 Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="cti600-label">{t("calculators.step2.fields.advancedOption.cti600")}</InputLabel>
                    <Select
                        labelId="cti600"
                        id="cti600"
                        value={cti600Id}
                        label={t("calculators.step2.fields.advancedOption.cti600")}
                        onChange={(e) => setCti600Id(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* IMPEDANCE CONTROL Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="impedanceControl-label">
                        {t("calculators.step2.fields.advancedOption.impedanceControl")}
                    </InputLabel>
                    <Select
                        labelId="impedanceControl"
                        id="impedanceControl"
                        value={impedanceControlId}
                        label={t("calculators.step2.fields.advancedOption.impedanceControl")}
                        onChange={(e) => setImpedanceControlId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* PRESS FIT HOLES Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="pressFitHoleId-label">
                        {t("calculators.step2.fields.advancedOption.pressFitHoles")}
                    </InputLabel>
                    <Select
                        labelId="pressFitHoleId"
                        id="pressFitHoleId"
                        value={pressFitHoleId}
                        label={t("calculators.step2.fields.advancedOption.pressFitHoles")}
                        onChange={(e) => setPressFitHoleId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* CARBON INK Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="carbonPrint-label">
                        {t("calculators.step2.fields.advancedOption.carbonPrint")}
                    </InputLabel>
                    <Select
                        labelId="carbonPrint-label"
                        id="carbonPrint"
                        value={carbonInkId}
                        label={t("calculators.step2.fields.advancedOption.carbonPrint")}
                        onChange={(e) => setCarbonInkId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("common.no")}</MenuItem>
                        <MenuItem value={2}>{t("common.yes")}</MenuItem>
                    </Select>
                </FormControl>

                {/* MASK OPTION Field */}
                <FormControl disabled={isLockFields}>
                    <InputLabel id="maskOption-label">
                        {t("calculators.step2.fields.advancedOption.maskOption")}
                    </InputLabel>
                    <Select
                        labelId="maskOption-label"
                        id="maskOption"
                        value={maskOptionId}
                        label={t("calculators.step2.fields.advancedOption.maskOption")}
                        onChange={(e) => setMaskOptionId(e.target.value)}
                    >
                        <MenuItem value={1}>{t("calculators.selectable.none")}</MenuItem>
                        <MenuItem value={2}>{t("calculators.selectable.peelable")}</MenuItem>
                        <MenuItem value={3}>{t("calculators.selectable.kapton")}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default Step2;