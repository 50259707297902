import {inchToMm, mmToInch} from "./unit.utils";
import {useTranslation} from "react-i18next";


// PCB SIZE / PANEL SIZE CHECK MM - INCH -----------------------------------------
//mm var
const minPcbWidth = 10;
const minPcbLength = 15;
const maxPcbWidth = 480;
const maxPcbLength = 580;

const minPanelWidth = 50;
const minPanelLength = 50;
const maxPanelWidth = 550;
const maxPanelLength = 600;

//inch var
const minPcbWidthIn = parseFloat(mmToInch(minPcbWidth));
const minPcbLengthIn = parseFloat(mmToInch(minPcbLength));
const maxPcbWidthIn = 18.89;//parseFloat(mmToInch(maxPcbWidth)); avoiding conversion > 480 for mm
const maxPcbLengthIn = parseFloat(mmToInch(maxPcbLength));

const minPanelWidthIn = parseFloat(mmToInch(minPanelWidth));
const minPanelLengthIn = parseFloat(mmToInch(minPanelLength));
const maxPanelWidthIn = parseFloat(mmToInch(maxPanelWidth));
const maxPanelLengthIn = parseFloat(mmToInch(maxPanelLength));


/**
 * Calculate the correct number of PCB a panel can handle
 * @param pcbWidth
 * @param pcbLength
 * @param panelWidth
 * @param panelLength
 * @returns {number}
 */
export const maxPcbCalculator = (pcbWidth, pcbLength, panelWidth, panelLength) => {
    if(pcbWidth === "" || pcbLength=== "" || panelWidth === "" || panelLength === ""){
        return 1
    }
    const _maxPcbWidth = panelWidth / pcbWidth;
    const _maxPcbLength = panelLength / pcbLength;
    return Math.trunc(_maxPcbWidth * _maxPcbLength);
}


/**
 * Checking if the size of the PCB is respecting ICAPE standard
 * @param {string} unitFormat Using 'mm' or 'in' to do all checks in the right order, depending on what the user is using
 * @param {float} pcbSize
 * @param {float} pcbSizeIn
 * @param setPcbSize
 * @param setPcbSizeIn
 * @param {string} measure Using 'width' or 'length' to initialize the right size check
 * @returns the correct PCB size if it's outer standard or the input value in both mm and inches
 */
export const pcbSizeCheck = (unitFormat, pcbSize, pcbSizeIn, setPcbSize, setPcbSizeIn, measure) => {

    // Extremes initialization
    let maxPcbSize = 0
    let maxPcbSizeIn = 0
    let minPcbSize = 0
    let minPcbSizeIn = 0
    if (measure === 'length') {
        maxPcbSize = maxPcbLength;
        maxPcbSizeIn = maxPcbLengthIn;
        minPcbSize = minPcbLength;
        minPcbSizeIn = minPcbLengthIn;
    }
    if (measure === 'width') {
        maxPcbSize = maxPcbWidth;
        maxPcbSizeIn = maxPcbWidthIn;
        minPcbSize = minPcbWidth;
        minPcbSizeIn = minPcbWidthIn;
    }

    // EMPTY stringify the float to enable the comparison
    if((pcbSize + '') === '' || (pcbSizeIn + '') === ''){
        return;
    }

    // Check Unit
    if(unitFormat === 'mm'){
        // MAX mm
        if (pcbSize > maxPcbSize) {
            setPcbSize(maxPcbSize);
            setPcbSizeIn(maxPcbSizeIn);
            return;
        }

        // MIN mm
        if (pcbSize < minPcbSize) {
            setPcbSize(minPcbSize);
            setPcbSizeIn(minPcbSizeIn);
            return;
        }

        // VALUE INPUT mm
        setPcbSize(pcbSize);
        setPcbSizeIn(mmToInch(pcbSize));

    }else{
        // MAX inch
        if (pcbSizeIn > maxPcbSizeIn) {
            setPcbSize(maxPcbSize);
            setPcbSizeIn(maxPcbSizeIn);
            return;
        }

        // MIN inch
        if (pcbSizeIn < minPcbSizeIn) {
            setPcbSize(maxPcbSize);
            setPcbSizeIn(maxPcbSizeIn);
            return;
        }

        // VALUE INPUT inch
        setPcbSize(inchToMm(pcbSizeIn));
        setPcbSizeIn(pcbSizeIn);
    }
};


/**
 * Checking if the size of the panel is respecting ICAPE standard
 * @param {string} unitFormat Using 'mm' or 'in' to do all checks in the right order, depending on what the user is using
 * @param {float} panelSize
 * @param {float} panelSizeIn
 * @param {float} pcbSize
 * @param {float} pcbSizeIn
 * @param setPanelSize
 * @param setPanelSizeIn
 * @param {string} measure Using 'width' or 'length' to initialize the right size check
 * @returns the correct panel size if it's outer standard or the input value in both mm and inches
 */
export const panelSizeCheck = (unitFormat, panelSize, panelSizeIn, pcbSize, pcbSizeIn, setPanelSize, setPanelSizeIn, measure) => {

    // Extremes initialization
    let maxPanelSize = 0;
    let maxPanelSizeIn = 0;
    let minPanelSize = 0;
    let minPanelSizeIn = 0;
    if (measure === 'length') {
        maxPanelSize = maxPanelLength;
        maxPanelSizeIn = maxPanelLengthIn;
        minPanelSize = minPanelLength;
        minPanelSizeIn = minPanelLengthIn;
    }
    if (measure === 'width') {
        maxPanelSize = maxPanelWidth;
        maxPanelSizeIn = maxPanelWidthIn;
        minPanelSize = minPanelWidth;
        minPanelSizeIn = minPanelWidthIn;
    }

    // EMPTY stringify the float to enable the comparison
    if((panelSize + '') === '' || (panelSizeIn + '') === ''){
        return;
    }

    // Check Unit
    if(unitFormat === 'mm'){
        // MAX mm
        if (panelSize > maxPanelSize) {
            setPanelSize(maxPanelSize);
            setPanelSizeIn(maxPanelSizeIn);
            return;
        }

        // MAX pcb mm - TODO Je sais plus pourquoi j'ai fais ça jpense que ça pue le mauvais copier coller
        if (pcbSize > maxPcbLength) {
            setPanelSize(maxPcbLength);
            setPanelSizeIn(maxPcbLengthIn);
            return;
        }

        // MIN mm & PANEL SMALLER THAN PCB
        if(panelSize < minPanelSize && pcbSize < minPanelSize){
            setPanelSize(minPanelSize);
            setPanelSizeIn(minPanelSizeIn);
            return;
        }

        // PANEL SMALLER THAN PCB
        if(panelSize < pcbSize){
            setPanelSize(pcbSize);
            setPanelSizeIn(mmToInch(pcbSize));
            return;
        }

        // VALUE INPUT mm
        setPanelSize(panelSize);
        setPanelSizeIn(mmToInch(panelSize));

    }
    else{
        // MAX inch
        if (panelSizeIn > maxPanelSizeIn) {
            setPanelSize(maxPanelSize);
            setPanelSizeIn(maxPanelSizeIn);
            return;
        }

        // MAX pcb inch
        if (pcbSizeIn > maxPcbLengthIn) {
            setPanelSize(maxPcbLength);
            setPanelSizeIn(maxPcbLengthIn);
            return;
        }

        // MIN inch & PANEL SMALLER THAN PCB
        if(panelSizeIn < minPanelSizeIn && pcbSizeIn < minPanelSizeIn){
            setPanelSize(minPanelSize);
            setPanelSizeIn(minPanelSizeIn);
            return;
        }

        // PANEL SMALLER THAN PCB
        if(panelSizeIn < pcbSizeIn){
            setPanelSize(inchToMm(pcbSizeIn));
            setPanelSizeIn(pcbSizeIn);
            return;
        }

        // VALUE INPUT inch
        setPanelSize(inchToMm(panelSizeIn));
        setPanelSizeIn(panelSizeIn);
    }
};


/**
 * Compile values and translation in one variable
 * @param {"pcb"|"panel"} _type
 * @param {"length"|"width"} _dimensions
 * @param {"mm"|"in"} _unitFormat
 * @returns {string} Returns a stencence with min and max pcb/panel size
 */
export const extremeSizeValues = (_type, _dimensions, _unitFormat) => {
    let extremValue = ""
    let minValue = 0
    let maxValue = 0

    switch (_type){
        case 'pcb':
            if (_dimensions === "length") {
                if (_unitFormat === "mm") {
                    minValue = minPcbLength;
                    maxValue = maxPcbLength;
                }
                if (_unitFormat === "in") {
                    minValue = minPcbLengthIn;
                    maxValue = maxPcbLengthIn;
                }
            }
            if (_dimensions === "width") {
                if (_unitFormat === "mm") {
                    minValue = minPcbWidth;
                    maxValue = maxPcbWidth;
                }
                if (_unitFormat === "in") {
                    minValue = minPcbWidthIn;
                    maxValue = maxPcbWidthIn;
                }
            }
            break

        case 'panel':
            if (_dimensions === "length") {
                if (_unitFormat === "mm") {
                    minValue = minPanelLength;
                    maxValue = maxPanelLength;
                }
                if (_unitFormat === "in") {
                    minValue = minPanelLengthIn;
                    maxValue = maxPanelLengthIn;
                }
            }
            if (_dimensions === "width") {
                if (_unitFormat === "mm") {
                    minValue = minPanelWidth;
                    maxValue = maxPanelWidth;
                }
                if (_unitFormat === "in") {
                    minValue = minPanelWidthIn;
                    maxValue = maxPanelWidthIn;
                }
            }
            break
    }

    extremValue = `${minValue} - ${maxValue} ${_unitFormat}`

    return extremValue
}
