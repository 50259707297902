import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import './shipping.scss';
import Typography from "@mui/material/Typography";
import {ButtonIcapeOutlined} from "../../button/button-icape";
import UserAddresses from "../../user-account/user-information/components/user-addresses";
import {logInConsole} from "../../../tools/debug";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function Shipping(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const [sameAddress, setSameAddress] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({});
    const [billingAddress, setBillingAddress] = useState('');
    const [selectedBillingAddress, setSelectedBillingAddress] = useState({});
    const [availableAddresses, setAvailableAddresses] = useState([])
    const addresses = props.addresses;
    const country = props.country;


    useEffect(()=>{
        logInConsole(`Checking addresses compatibility in shipping module`, 'olive');
        if(deliveryAddress !== '')
        {
            setSelectedDeliveryAddress(findAddress(deliveryAddress));
        }

        if(billingAddress !== '')
        {
            setSelectedBillingAddress(findAddress(billingAddress));
        }

        if(deliveryAddress!=='' && billingAddress!==''){
            logInConsole(`Sending addresses chosen by the customer to useCartProcess`, 'olive');
            props.addressesChooseByCustomer({'delivery':deliveryAddress, 'billing':billingAddress});
            return
        }
        logInConsole(`No addresses to set MUI`, 'olive');
    },[deliveryAddress, billingAddress])

    /**
     * Set customer address by default (@boot)
     */
    useEffect(() => {
        if (!addresses){
            return
        }
        logInConsole(`Set default address in customer DB to MUI fields`, 'olive');

        let _availableAddresses = []
        let isDefaultAddressAvailable = false

        // Filtering address by country code selected by user in step 3
        for(let i in addresses){
            if (addresses[i].countryCode === country.code) {
                _availableAddresses.push(addresses[i]);
            }
        }

        // searching if the default address is available with this country code
        for (let j in _availableAddresses) {
            if(_availableAddresses[j].isDefaultAddress){
                setDeliveryAddress(_availableAddresses[j].id);
                setBillingAddress(_availableAddresses[j].id);
                isDefaultAddressAvailable = true;
                logInConsole(`Default address set.`, 'olive');
            }
        }

        // If default address is not available, setting the first address available in this country
        if (!isDefaultAddressAvailable && _availableAddresses.length > 0) {
            setDeliveryAddress(_availableAddresses[0].id);
            setBillingAddress(_availableAddresses[0].id);
            logInConsole(`Default address unavailable in the country selected. First address set`, 'olive');
        }

        setAvailableAddresses(_availableAddresses);

    }, [addresses])

    const handleUseSameAddress = (e) => {
        setSameAddress(e.target.checked)
        setBillingAddress(deliveryAddress);
    }
    const handleDeliveryAddress = (e) => {
        e.preventDefault();
        setDeliveryAddress(e.target.value);
        if(sameAddress)
        {
            setBillingAddress(e.target.value);
        }
    }
    const handleBillingAddress = (e) => {
        e.preventDefault();
        setBillingAddress(e.target.value);
    }

    const addressReformat = (addressId) => {
        return addresses.filter(address => address.id === addressId).map(addressFiltered => (
            /*addressFiltered.company + ' - ' + addressFiltered.firstName + ' ' + addressFiltered.lastName + ' '*/
            addressFiltered.alias
        ))
    }
    const findAddress = (id) => {
        return addresses.find(address => address.id === id);
    }

    // HANDLE DIALOG MANAGE ADDRESS
    const [isManageAddressOpen, setIsManageAddressOpen] = useState(false);

    return (
        <>
            <Box className="shopping-data-card shipping-card">
                {/*TITLE*/}
                <Typography variant={'h3'}>{t("common.shipping")}</Typography>
                <Box className="shipping-addresses-wrapper">
                    <Box>
                        <FormControl style={{width: "100%"}}>
                            <InputLabel id="select-delivery-address">{t("common.deliveryAddress")}</InputLabel>
                            <Select
                                labelId="select-delivery-address-label"
                                id="select-delivery-address-select"
                                value={deliveryAddress}
                                label={t("common.deliveryAddress")}
                                onChange={handleDeliveryAddress}
                            >
                                {availableAddresses && availableAddresses.map(address =>
                                    <MenuItem key={address.id} value={address.id}>
                                        {addressReformat(address.id)}
                                    </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <Box className={deliveryAddress === ''? "shipping-address-selected-resume-hidden" : "shipping-address-selected-resume shipping-address-selected-resume-show"}>
                            {/*ALIAS*/}
                            <Typography component={"span"}>
                                {/*{selectedDeliveryAddress.company} - {selectedDeliveryAddress.firstName} {selectedDeliveryAddress.lastName}*/}
                                {selectedDeliveryAddress.alias}
                            </Typography>
                            {/*Complete address*/}
                            <Typography component={"span"}>
                                {selectedDeliveryAddress.firstName} {selectedDeliveryAddress.lastName}<br/>
                                {selectedDeliveryAddress.street} <br/>
                                {selectedDeliveryAddress.city}, {selectedDeliveryAddress.postcode} <br/>
                                {selectedDeliveryAddress.countryCode} <br/>
                                {selectedDeliveryAddress.phoneNumber} <br/>
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={sameAddress? "shipping-address-selected-resume-hidden" : "shipping-address-selected-resume-show"}>
                        <FormControl style={{width: "100%"}} disabled={sameAddress}>
                            <InputLabel id="select-billing-address">{t("common.billingAddress")}</InputLabel>
                            <Select
                                labelId="select-billing-address-label"
                                id="select-billing-address-select"
                                value={billingAddress}
                                label={t("common.billingAddress")}
                                onChange={handleBillingAddress}
                            >
                                {availableAddresses && availableAddresses.map(address =>
                                    <MenuItem key={address.id}
                                              value={address.id}>
                                        {addressReformat(address.id)}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Box className={billingAddress === ''? "shipping-address-selected-resume-hidden" : "shipping-address-selected-resume shipping-address-selected-resume-show"}>
                            {/*ALIAS*/}
                            <Typography component={"span"}>
                                {/*{selectedBillingAddress.company} - {selectedBillingAddress.firstName} {selectedBillingAddress.lastName}*/}
                                {selectedDeliveryAddress.alias}
                            </Typography>
                            {/*Complete address*/}
                            <Typography component={"span"}>
                                {selectedBillingAddress.firstName} {selectedBillingAddress.lastName}<br/>
                                {selectedBillingAddress.street} <br/>
                                {selectedBillingAddress.city}, {selectedBillingAddress.postcode} <br/>
                                {selectedBillingAddress.countryCode} <br/>
                                {selectedBillingAddress.phoneNumber} <br/>
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={sameAddress} onChange={handleUseSameAddress}/>}
                        label={t("cart.useDeliveryAddressAsBillingAddress")}
                    />
                </FormGroup>


                {/*
                <ButtonIcapeOutlined onClick={() => setIsManageAddressOpen(!isManageAddressOpen)} sx={{margin: '30px auto'}}>
                    {t("cta.manageAddresses")}
                </ButtonIcapeOutlined>*/}

                <ButtonIcapeOutlined onClick={() => navigate('/user/addresses', {replace: false})} sx={{margin: '30px auto'}}>
                    {t("cta.manageAddresses")}
                </ButtonIcapeOutlined>

                {/*POPUP MANAGE ADDRESS*/}
                <Dialog open={isManageAddressOpen}>
                    <DialogContent>
                        <UserAddresses />
                    </DialogContent>
                    <DialogActions>
                        <ButtonIcapeOutlined onClick={() => setIsManageAddressOpen(!isManageAddressOpen)}>{t("cta.cancel")}</ButtonIcapeOutlined>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

export default Shipping
