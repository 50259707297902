import { motion } from "framer-motion";
import {Box} from "@mui/material";

 const ErrorMessage = (props) =>  {
    const message = props.message;
    const spring = {
        type: "spring",
        damping: 20,
        mass:2,
        stiffness: 600
    }

 return(
     <motion.div
         transition={spring}
         initial={{scale:0.7, opacity:0.5}}
         animate={{scale:1, opacity:1}}
       >
         <Box>
             {message}
        </Box>
     </motion.div>
 )
}
export default ErrorMessage;
