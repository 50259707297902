/**
 * Steps are redefined here to prevent Google from receiving the translations to clarify statistics.
 * @type {string[]}
 */
const gtmCalcualtorSteps = [
    {stepName: "Import your file", pathName: "import-your-file"},
    {stepName: "Add technical data", pathName: "add-technical-data"},
    {stepName: "Shipping", pathName: "shipping"},
];
const gtmSignUpFormSteps = [
    {stepName: 'information', pageTitle: 'ICAPE - sign-up - information'},
    {stepName: 'company', pageTitle: 'ICAPE - sign-up - company'},
    {stepName: 'address', pageTitle: 'ICAPE - sign-up - address'}
];

/**
 * Datalayer load event.
 * Provide key information when the user first load the website.
 * @param {"CN"|"DE"|"EN"|"FR"|"IT"|"JA"|"PT"|"ES"} _activeLanguage
 * @param {"non connected"|"connected"} _userType
 */
export const handleDataLayerLoadEvent = (_activeLanguage, _userType) => {
    //TODO ADD CORRECT COUNTRY
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'dataLayerLoad',
        country: '-',
        language: _activeLanguage,
        user_type: _userType,
    })
}

/**
 * Connexion event.
 * When a user logs into the show.
 */
export const handleConnexionEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'connexion'
    })
}

/**
 * Virtual page view - forms
 * Triggered when the form step is load
 * @param {"register"|"calculator"} _formName
 * @param {number} _formStep
 */
export const handleVirtualPageView = (_formName, _formStep) => {

    const baseUrl = window.location.href;
    let step = {};

    switch (_formName) {
        case "register":
            step = {
                pathName : gtmSignUpFormSteps[_formStep].stepName,
                pageTitle : gtmSignUpFormSteps[_formStep].pageTitle
            }
        case "calculator":
            step = {
                pathName : gtmCalcualtorSteps[_formStep].pathName,
                pageTitle : `${window.location.pathname.slice(1)} - ${gtmCalcualtorSteps[_formStep].stepName}`
            }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageURL: baseUrl + '/' + step.pathName,
        virtualPageTitle : step.pageTitle,
    });
}

/**
 * Sign up form event.
 * Triggered when user change step in the register form.
 * @param {number} _activeStep
 */
export const handleSignUpFormEvent = (_activeStep) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'sign_up_form',
        step_name: gtmSignUpFormSteps[_activeStep].stepName,
        step_number: _activeStep + 1
    });
}

/**
 * Sign up form submit event.
 * Triggered when the user submit the register form.
 */
export const handleSignUpFormSubmitEvent = () => {
    window.dataLayer.push({
        event: 'sign_up_form_submit'
    });
}

/**
 * Devisor view event.
 * Triggered at each step change.
 * @param {("aluminum"|"hdi"|"flex"|"rigid")} _calculator
 * @param {("single"|"classic"|"custom")} _pcbDeliveryFormat
 * @param {number}_activeStep
 */
export const handleDevisorViewEvent = (_calculator, _pcbDeliveryFormat, _activeStep) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'devisor_view',
        product_type: _pcbDeliveryFormat,
        devisor_type: _calculator,
        step_name: gtmCalcualtorSteps[_activeStep].stepName,
        step_number: _activeStep + 1
    });
}

/**
 * Calculator first interaction event.
 * Triggered only once when the user is using any calculator parameter.
 * @param {("aluminum"|"hdi"|"flex"|"rigid")} _calculator
 * @param {("single"|"classic"|"custom")}_pcbDeliveryFormat
 */
export const handleFirstInteractionEvent = (_calculator, _pcbDeliveryFormat) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'first_interaction',
        form_name: 'devisor',
        product_type: _pcbDeliveryFormat,
        devisor_type: 'rigid',
    });
}

/**
 * Download quotation PDF event.
 * Triggered when the user downloads the PDF quote.
 * @param {("aluminum"|"hdi"|"flex"|"rigid")} _calculator
 * @param {("single"|"classic"|"custom")} _pcbDeliveryFormat
 */
export const handleDownloadQuoteEvent = (_calculator, _pcbDeliveryFormat) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'download_my_quote',
        product_type: _pcbDeliveryFormat,
        devisor_type: _calculator,
    });
}

/**
 * Click order event / Click repeat order event.
 * Triggered when user order a quote / repeat an order from his account
 * @param {"click_repeat_order"|"click_order_now"} _eventName
 * @param _product
 * @param _devisor
 */
export const handleClickOrderEvent = (_eventName, _product, _devisor) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: _eventName,
        product_type: _product,
        devisor_type: _devisor
    });
}

/**
 * Order now event.
 * Triggered when the user checks out.
 * @param {("aluminum"|"hdi"|"flex"|"rigid")} _calculator
 * @param {("single"|"classic"|"custom")} _pcbDeliveryFormat
 */
export const handleOrderNowEvent = (_calculator, _pcbDeliveryFormat) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'order_now',
        product_type: _pcbDeliveryFormat,
        devisor_type: _calculator,
    });
}

/**
 * Begin checkout event.
 * Triggered when the user lands on the cart page.
 * @param {object[]} _items - Array with all items.
 * @param {string} _items[].item_name - PCB or Stencil.
 * @param {string} _items[].item_id - Token value.
 * @param {number} _items[].price - Item price (tax excl).
 * @param {string} _items[].item_variant - Calculator.
 * @param {number} _items[].quantity - Item quantity.
 * @param {number} _value - Total items price (tax excl).
 * @param {number} _taxTotal
 * @param {string} _currencyCode
 */
export const handleBeginCheckoutEvent = (_items, _value, _taxTotal, _currencyCode) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            items: _items,
            value: _value,
            tax: _taxTotal,
            currency: _currencyCode,
        }
    });
}

/**
 * Add payment info event.
 * Triggered when the user chose a payment method.
 * @param {object[]} _items - Array with all items.
 * @param {string} _items[].item_name - PCB or Stencil.
 * @param {string} _items[].item_id - Token value.
 * @param {number} _items[].price - Item price (tax excl).
 * @param {string} _items[].item_variant - Calculator.
 * @param {number} _items[].quantity - Item quantity.
 * @param {number} _value - Total items price (tax excl).
 * @param {number} _taxTotal
 * @param {string} _currencyCode
 * @param {string} _paymentMethod
 */
export const handleAddPaymentInfoEvent = (_items, _value, _taxTotal, _currencyCode, _paymentMethod) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
            items: _items,
            value: _value,
            tax: _taxTotal,
            currency: _currencyCode,
            payment_type: _paymentMethod
        }
    });
}

/**
 * Remove from cart event.
 * Triggered when the customer cancel his cart and gets back in calculator.
 * @param {object[]} _items - Array with all items.
 * @param {string} _items[].item_name - PCB or Stencil.
 * @param {string} _items[].item_id - Token value.
 * @param {number} _items[].price - Item price (tax excl).
 * @param {string} _items[].item_variant - Calculator.
 * @param {number} _items[].quantity - Item quantity.
 * @param {number} _taxTotal
 * @param {string} _currencyCode
 */
export const handleRemoveFromCartEvent = (_items, _taxTotal, _currencyCode) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            items: _items,
            tax: _taxTotal,
            currency: _currencyCode
        }
    });
}

/**
 * Purchase event.
 * Triggered when the payment is successful.
 * @param {object[]} _items - Array with all items.
 * @param {string} _items[].item_name - PCB or Stencil.
 * @param {string} _items[].item_id - Token value.
 * @param {number} _items[].price - Item price (tax excl).
 * @param {string} _items[].item_variant - Calculator.
 * @param {number} _items[].quantity - Item quantity.
 * @param {number} _value - Total items price (tax excl).
 * @param {number} _taxTotal
 * @param {string} _currencyCode
 * @param {{}} _paymentMethod
 * @param _couponCode
 */
export const handlePurchaseEvent = (_items, _value, _taxTotal, _currencyCode, _paymentMethod, _couponCode) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            items: _items,
            value: _value,
            tax: _taxTotal,
            currency: _currencyCode,
            payment_type: _paymentMethod,
            coupon: _couponCode,
            transaction_id: 'No id for now',
        }
    });
}
