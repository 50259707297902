import {axiosPrivate, BASE_URL} from '../api/axios';
import authHeader from "./auth-header";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import {ErrorHandling} from "../tools/error-handling";


/**
 * Fetches all available countries.
 * @returns {Promise<any>}
 */
export const getCountries = async () => {
    logInConsoleSpacer()
    logInConsole(`Fetching from backend : Countries list`, 'darkmagenta', 'bold');

    try {
        const countriesList = await axiosPrivate.get(`${BASE_URL}/shop/countries?itemsPerPage=244`, {headers: authHeader()});

        logInConsole(`Countries list received`, 'green');
        logInConsoleObject(countriesList.data);
        return countriesList.data;

    }
    catch (error) {

        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        logInConsole(`Error retrieving company addresses --->  ${resMessage}`, 'red');
        ErrorHandling(null, resMessage);

        throw error;
    }
}