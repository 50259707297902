import React, {useEffect, useState} from "react";
import ErrorMessage from "../../error-message";
import {Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {logInConsole} from "../../../../tools/debug";
import {createFilterOptions} from "@mui/material/Autocomplete";

const Step2 = (props) => {
    const {t} = useTranslation();
    const {
        companyName,setCompanyName,
        companiesList,
        website, setWebsite,
        businessType, setBusinessType,
        siret, setSiret,
        emailOfReferrer, setEmailOfReferrer,
        showErrMsg, errMsg
    } = props.referencesStep2

    const disableCompanyDependencyFields = {
        website: true,
        businessType: true,
        siret: true,
        referringSale: true
    }
    const enableCompanyDependencyFields = {
        website: false,
        businessType: false,
        siret: false,
        referringSale: false
    }
    const [disabledCompanyDependencyFields, setDisabledCompanyDependencyFields] = useState(enableCompanyDependencyFields);

    const resetCompanyDependencyFields = () => {
        setDisabledCompanyDependencyFields(disableCompanyDependencyFields);
        setWebsite('');
        setBusinessType('');
        setSiret('');
        setEmailOfReferrer('');
    }

    const filter = createFilterOptions();

    return (
        <>
            {/*TITLE*/}
            <Box className="register-title">
                <Typography variant={"h1"}>{t("common.registration")}</Typography>
                <Typography component={"span"}>{t("common.company")}</Typography>
            </Box>

            {/*ERROR MESSAGE*/}
            <Box className={showErrMsg ? "errorMessage" : ''}>
                {showErrMsg ? <ErrorMessage message={errMsg}/> : ''}
            </Box>

            {/*STEP 02*/}
            <Box className="register-fields-wrapper">

                {/*COMPANY*/}
                <TextField
                    id="company"
                    label={t("common.companyName")}
                    onChange={e => setCompanyName(e.target.value)}
                    value={companyName}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*OLD COMPANY FIELD WITH LIST*/}
                {/*<Autocomplete
                    value={companyName}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            // Enter key pressed
                            console.log("enter");
                            setCompanyName(newValue);
                            resetCompanyDependencyFields();
                            setDisabledCompanyDependencyFields(enableCompanyDependencyFields);
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            console.log("add");
                            setCompanyName(newValue.inputValue);
                            resetCompanyDependencyFields();
                            setDisabledCompanyDependencyFields(enableCompanyDependencyFields);
                        } else {

                            if (newValue === null) {
                                console.log("clear");
                                setCompanyName('');
                                resetCompanyDependencyFields();
                                return
                            }

                            console.log("Selected one from list");
                            setCompanyName(newValue.name);
                            setWebsite(newValue.website);
                            setBusinessType(newValue.businessType);
                            setSiret(newValue.siret );
                            setEmailOfReferrer(newValue.emailOfReferrer);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue.toLowerCase().trim() === option.name.toLowerCase().trim());
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                name: `${t("cta.add")} "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="company"
                    options={companiesList}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label={t("common.companyName")} required />
                    )}
                />*/}

                {/*WEBSITE*/}
                <TextField
                    id="website"
                    label={t("common.website")}
                    onChange={e => setWebsite(e.target.value)}
                    value={website}
                    variant="outlined"
                    margin="normal"
                    disabled={disabledCompanyDependencyFields.website}
                />

                {/*BUSINESS TYPE*/}
                <FormControl disabled={disabledCompanyDependencyFields.businessType}>
                    <InputLabel id="business-type">{t("common.businessType")}</InputLabel>
                    <Select
                        labelId="business-type"
                        id="business-type"
                        value={businessType}
                        label="Business type"
                        onChange={e => setBusinessType(e.target.value)}
                        sx={{width: "300px"}}
                    >
                        <MenuItem value={1}>{t("menu.industries.automotive")}</MenuItem>
                        <MenuItem value={2}>{t("menu.industries.consumerAndMultimedia")}</MenuItem>
                        <MenuItem value={3}>{t("menu.industries.industry")}</MenuItem>
                        <MenuItem value={4}>{t("menu.industries.metering")}</MenuItem>
                        <MenuItem value={5}>{t("menu.industries.powerEnergy")}</MenuItem>
                        <MenuItem value={6}>{t("menu.industries.medical")}</MenuItem>
                        <MenuItem value={7}>{t("common.electronics")}</MenuItem>
                        <MenuItem value={8}>{t("common.others")}</MenuItem>
                    </Select>
                </FormControl>

                {/*SIRET NUMBER*/}
                <TextField
                    id="siret-number"
                    label={t("common.siretNumber")}
                    onChange={e => setSiret(e.target.value)}
                    value={siret}
                    variant="outlined"
                    margin="normal"
                    disabled={disabledCompanyDependencyFields.siret}
                />

                {/*REFERRING SALE*/}
                <TextField
                    id="referring"
                    label={t("common.referringSaleEmail")}
                    onChange={e => setEmailOfReferrer(e.target.value)}
                    value={emailOfReferrer}
                    variant="outlined"
                    disabled={disabledCompanyDependencyFields.referringSale}
                    margin="normal"
                />

                {/*Spacer*/}
                <Box className={"form-spacer"}></Box>
            </Box>
        </>
    )
}
export default Step2;
