import React, {useEffect, useState} from "react";
import {Box, Divider, Skeleton} from "@mui/material";
import './video-header.scss';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from "@mui/lab";
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Typography from "@mui/material/Typography";
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import {ButtonIcapeOutlined} from "../../button/button-icape";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import useVideoUpdates from "../../../hooks/useVideoUpdates";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";


function SkeletonTypography(props) {
    const { component, content, loading, variant } = props;

    const width = variant === "h3" ? "100%" : "30%"
    const height = variant === "h3" ? "40px" : "10px"

    return (
        <Typography component={component} variant={variant}>
            {loading ? <Skeleton width={width} height={height}/> : content}
        </Typography>
    );
}

SkeletonTypography.propTypes = {
    component: PropTypes.string,
    content: PropTypes.string,
    loading: PropTypes.bool,
    variant: PropTypes.string,
    width: PropTypes.string
};


function VideoHeader(props) {
    const {t} = useTranslation();
    const frontEndUrl = process.env.NODE_ENV === "development" ? "https://eshop.icape-group.com" : process.env.REACT_APP_FRONTEND_URL;
    const videoUrl = `${frontEndUrl}/media/tutorial.mp4`
    const {auth} = useAuth();
    const navigate = useNavigate();
    const {videoUpdatesList, isVideoUpdateLoading} = useVideoUpdates();





    const navigateToUpdatePage = (_index) => {
        if (isVideoUpdateLoading) {
            return
        }

        navigate(`/video-updates?id=${videoUpdatesList[_index]?.id}`, {replace: false});
    }

    return (
        <>
            <Box className="news-container">

                {/*VIDEO INTRO*/}
                <Box className="video-container">
                    <video loop={true} autoPlay={true} muted={true}>
                        <source src={videoUrl} type="video/mp4" />
                        {/*Votre navigateur ne supporte pas la lecture de vidéo.*/}
                    </video>
                </Box>

                {/*VIDEOS UPDATES*/}
                {
                    auth.username !== undefined &&
                    <>
                        <Divider orientation="vertical" flexItem />

                        <Box className="videos-update-container">
                            <h2>{ t('videoUpdates.homeTitle') }</h2>
                            <Timeline>
                                <TimelineItem onClick={ () => navigateToUpdatePage(0) }>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined">
                                            <OndemandVideoTwoToneIcon className="icon-green"/>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <SkeletonTypography component="span" variant="span" loading={isVideoUpdateLoading}
                                                            content={videoUpdatesList[0]?.date}/>
                                        <SkeletonTypography component="h3" variant="h3" loading={isVideoUpdateLoading}
                                                            content={ videoUpdatesList[0]?.title }/>
                                                            {/*content={t(`videoUpdates.${videoUpdatesList[0]?.id}.title`)}/>*/}
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem onClick={ () => navigateToUpdatePage(1) }>
                                    <TimelineSeparator sx={{minWidth:"36px"}}>
                                        <TimelineConnector />
                                        <TimelineDot color="primary" sx={{margin:"11.5px auto"}}/>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <SkeletonTypography component="span" variant="span" loading={isVideoUpdateLoading}
                                                            content={videoUpdatesList[1]?.date}/>
                                        <SkeletonTypography component="h3" variant="h3" loading={isVideoUpdateLoading}
                                                            content={ videoUpdatesList[1]?.title }/>
                                                            {/*content={t(`videoUpdates.${videoUpdatesList[1]?.id}.title`)}/>*/}
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem onClick={ () => navigateToUpdatePage(2) }>
                                    <TimelineSeparator sx={{minWidth:"36px"}}>
                                        <TimelineConnector />
                                        <TimelineDot color="primary" sx={{margin:"11.5px auto"}}/>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <SkeletonTypography component="span" variant="span" loading={isVideoUpdateLoading}
                                                            content={videoUpdatesList[2]?.date}/>
                                        <SkeletonTypography component="h3" variant="h3" loading={isVideoUpdateLoading}
                                                            content={ videoUpdatesList[2]?.title }/>
                                                            {/*content={t(`videoUpdates.${videoUpdatesList[2]?.id}.title`)}/>*/}
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                            <ButtonIcapeOutlined onClick={ () => navigate('/video-updates', {replace: false}) }>
                                { t("cta.seeMore") }
                            </ButtonIcapeOutlined>
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}

export default VideoHeader;