import {Box} from "@mui/material";
import "./panel.scss";
import React, {useEffect, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from "@mui/material/Typography";


export default function Panel(props) {
    const {
        unitFormat,
        pcbQuantityX, pcbQuantityY,
        pcbWidth, pcbHeight,
        panelWidth, panelHeight,
        panelMaximumWidth, panelMaximumHeight,
        spaceX, spaceY,
        technicalBorderTop, technicalBorderRight,
        technicalBorderBottom, technicalBorderLeft,
        separationX, separationY
    } = props

    const [pcbSize, setPcbSize] = useState({});
    const [techBorderSize, setTechBorderSize] = useState({});
    const [spaceSize, setSpaceSize] = useState({});

    useEffect(() => {

        const totalSpaceX = spaceX * (pcbQuantityX - 1);
        const totalSpaceY = spaceY * (pcbQuantityY - 1);

        const totalTechBorderX = technicalBorderRight + technicalBorderLeft;
        const totalTechBorderY = technicalBorderTop + technicalBorderBottom;

        const rowWidth = isNaN(pcbWidth) ? 1 : pcbWidth * pcbQuantityX;
        const rowHeight = isNaN(pcbHeight) ? 1 : pcbHeight * pcbQuantityY;

        const panelWidth = rowWidth + totalSpaceX + totalTechBorderX;
        const panelHeight = rowHeight + totalSpaceY + totalTechBorderY;

        const ratioX = 550 / panelWidth;
        const ratioY = 550 / panelHeight;

        if ( panelWidth > panelHeight ) {
            setPcbSize({
                width: pcbWidth * ratioX,
                height: pcbHeight * ratioX
            });
            setSpaceSize({
                x: spaceX * ratioX,
                y: spaceY * ratioX
            });
            setTechBorderSize({
                top: technicalBorderTop * ratioX,
                right: technicalBorderRight * ratioX,
                bottom: technicalBorderBottom * ratioX,
                left: technicalBorderLeft * ratioX
            })
        }
        else {
            setPcbSize({
                width: pcbWidth * ratioY,
                height: pcbHeight * ratioY
            });
            setSpaceSize({
                x: spaceX * ratioY,
                y: spaceY * ratioY
            });
            setTechBorderSize({
                top: technicalBorderTop * ratioY,
                right: technicalBorderRight * ratioY,
                bottom: technicalBorderBottom * ratioY,
                left: technicalBorderLeft * ratioY
            })
        }

    }, [pcbWidth, pcbHeight, pcbQuantityX, pcbQuantityY, spaceX, spaceY, technicalBorderTop, technicalBorderRight, technicalBorderBottom, technicalBorderLeft]);


    const calculateVCutPosition = (_iteration, _position) => {

        let result = 0;

        switch (_position) {
            case "top":
                if (_iteration === 0) {
                    result = techBorderSize.top
                }
                else {
                    result = techBorderSize.top + ( pcbSize.height * _iteration ) + ( spaceSize.y * _iteration )
                }
                break;
            case "right":
                if (_iteration === 0) {
                    result = techBorderSize.left + pcbSize.width
                }
                else {
                    result = techBorderSize.left + (pcbSize.width * (_iteration + 1)) + (spaceSize.x * _iteration)
                }
                break;
            case "bottom":
                if (_iteration === 0) {
                    result = techBorderSize.top + pcbSize.height
                }
                else {
                    result = techBorderSize.top + (pcbSize.height * (_iteration + 1)) + (spaceSize.y * _iteration)
                }
                break;
            case "left":
                if (_iteration === 0) {
                    result = techBorderSize.left
                }
                else {
                    result = techBorderSize.left + (pcbSize.width + spaceSize.x) * _iteration
                }
                break;
        }

        return result
    }


    return (
        <>
            {/*PANEL*/}
            <Box id="elementToCapture" className="panel"
                 sx={{gap: `${spaceSize.y}px`,
                     paddingTop: `${techBorderSize.top}px`, paddingRight: `${techBorderSize.right}px`,
                     paddingBottom: `${techBorderSize.bottom}px`, paddingLeft: `${techBorderSize.left}px`}}>

                {/*ROWs*/}
                { Array.from({length: pcbQuantityY}, (_, i) => (
                        <Box className="pcb-row" key={i} sx={{gap: `${spaceSize.x}px`}}>

                            {/*PCBs*/}
                            { Array.from({length: pcbQuantityX}, (_, j) => (
                                    <Box className="pcb" key={j}
                                         sx={{ width: `${pcbSize.width}px`, height:`${pcbSize.height}px`}}>

                                        {/*Milling STEMP HOLE X*/}
                                        { separationX === 3 &&
                                            <>
                                                <Box className="milling-right" sx={{gap: `${pcbSize.height/10}px`}}>
                                                    <Box />
                                                    <Box />
                                                </Box>
                                                <Box className="milling-left" sx={{gap: `${pcbSize.height/10}px`}}>
                                                    <Box />
                                                    <Box />
                                                </Box>
                                            </>
                                        }

                                        {/*Milling STEMP HOLE Y*/}
                                        { separationY === 3 &&
                                            <>
                                                <Box className="milling-top" sx={{gap: `${pcbSize.width/10}px`}}>
                                                    <Box />
                                                    <Box />
                                                </Box>
                                                <Box className="milling-bottom" sx={{gap: `${pcbSize.width/10}px`}}>
                                                    <Box />
                                                    <Box />
                                                </Box>
                                            </>
                                        }
                                    </Box>
                            )) }
                        </Box>
                    )) }

                {/*VCUT X*/}
                { separationX === 2 && Array.from({length: pcbQuantityX}, (_,k) => (
                        <React.Fragment key={k}>
                            <Box className="separation column" sx={{left: `${calculateVCutPosition(k, "left")}px`, top: '0'}} />
                            <Box className="separation column" sx={{left: `${calculateVCutPosition(k, "right")}px`, top: '0'}} />
                        </React.Fragment>
                    )) }

                {/*VCUT Y*/}
                { separationY === 2 && Array.from({length: pcbQuantityY}, (_,l) => (
                        <React.Fragment key={l}>
                            <Box className="separation row" sx={{top: `${calculateVCutPosition(l, "top")}px`, left: '0'}} />
                            <Box className="separation row" sx={{top: `${calculateVCutPosition(l, "bottom")}px`, left: '0'}} />
                        </React.Fragment>
                    )) }

                {/*PANEL SIZE Y*/}
                <Box className="panel-size-y">
                    <Box className="panel-height">
                        <Typography variant="span" component="span" className={panelHeight["mm"] > panelMaximumHeight ? "error-size" : ""}>
                            {isNaN(panelHeight[unitFormat]) ? "-" : panelHeight[unitFormat]}&nbsp;{unitFormat}
                        </Typography>
                    </Box>

                    <Box className="auto-arrow">
                        <KeyboardArrowUpIcon />
                        <Box className="vertical-line"/>
                        <KeyboardArrowDownIcon />
                    </Box>
                </Box>

                {/*PANEL SIZE X*/}
                <Box className="panel-size-x">
                    <Box className="auto-arrow">
                        <KeyboardArrowLeftIcon />
                        <Box className="horizontal-line"/>
                        <KeyboardArrowRightIcon />
                    </Box>

                    <Typography variant="span" component="span" className={panelWidth["mm"] > panelMaximumWidth ? "error-size" : ""}>
                        {isNaN(panelWidth[unitFormat]) ? "-" : panelWidth[unitFormat]} {unitFormat}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}