import React, {useRef, useState, useEffect} from "react";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Step, StepLabel, Stepper, TextField} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {axios, axiosPrivate, BASE_URL} from '../../../api/axios';
import {useNavigate} from 'react-router-dom';
import ErrorMessage from "../error-message";
import RegisterResult from "./register-result";
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import {ButtonIcape, ButtonIcapeGreen, ButtonIcapeOutlined} from "../../button/button-icape";
import {useTranslation} from "react-i18next";
import BackendConnection from "../../backend-connection/backend-connection";
import {logInConsole} from "../../../tools/debug";
import {regex} from "../../../tools/regex";
import {
    handleSignUpFormEvent,
    handleSignUpFormSubmitEvent,
    handleVirtualPageView
} from "../../../tools/google-tag-manager-events";
import {ErrorHandling} from "../../../tools/error-handling";

const Register = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [connectToBackend, setConnectToBackend] = useState(false);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);

    const steps = [`${t('common.information')}`, `${t('common.company')}`, `${t('common.address')}`];
    const [activeStep, setActiveStep] = useState(0);


    function colorLog(msg, color) {
        console.log("%c" + msg, "color:" + color + ";font-weight:bold;");
    }

    //Error message block from backend (double) check.
    const [showErrMsg, setShowErrMsg] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    useEffect(() => {
        setShowErrMsg(errMsg !== '')
    }, [errMsg])

    // NAVIGATION
    useEffect(() => {
        if ( window.location.pathname === '/login' ){
            navigate('/register', {replace: true});
        }
    }, []);


    //STEP 1
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [accountPhone, setAccountPhone] = useState('');
    const [validAccountPhone, setValidAccountPhone] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const checkFields_step1 = () => {
        setErrMsg('');
        if (!regex(firstName, 'name')) {
            setErrMsg('First name must have at least 2 characters');
            return false
        }
        if (!regex(lastName, 'name')) {
            setErrMsg('Last name must have at least 2 characters');
            return false
        }
        if (!regex(email, 'email')) {
            setErrMsg('Email address is not correct');
            return false
        }
        if (validAccountPhone || accountPhone === '') {
            setErrMsg('Phone number is not correct');
            return false
        }
        if (!regex(password, 'password')) {
            setErrMsg('Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol');
            return false
        }
        if (!regex(passwordConfirmation, 'password')) {
            setErrMsg('Password confirmation must be the same as password');
            return false
        }
        if (password !== passwordConfirmation) {
            setErrMsg('Password fields are not identical');
            return false
        }

        return true;
    }
    const checkEmailInDb = () => {
        return new Promise((resolve, reject) => {
            setConnectToBackend(true);
            axios.post(BASE_URL + '/shop/user/check-email', JSON.stringify({"email": email}), {
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    console.log('response', response.data.emailExists);
                    if (response.data.emailExists) {
                        colorLog('Email already exists in db', 'orange');
                        setErrMsg('This email is already used');
                        resolve(true);
                    } else {
                        colorLog('Email does not exist in db', 'green');
                        resolve(false);
                    }
                })
                .catch(error => {
                    colorLog(`Error ${error.response.status}: check error`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, error.response.data);
                    reject(error);
                })
                .finally(() => {
                    setConnectToBackend(false);
                });
        });
    }
    const checkStep1 = async () => {
        colorLog("check invalid fields of step 1", 'orange');
        if (!checkFields_step1()) {
            colorLog('at least one field on step 1 is not valid', 'darkred');
            return false
            //This is here just to test status code from backend (for double check)
            if (process.env.NODE_ENV !== "development") {
                return;
            } else {
                colorLog("regex check with bad result but not prod environment", 'darkorange');
            }
        } else {
            colorLog("check regex ok for step 1, checking email in db", 'orange');
            let isEmailExists = await checkEmailInDb();

            if (isEmailExists) {
                return false
            }

            /*SET ADDRESS FIRST AND LAST NAME OF STEP 3*/
            step3FirstName === '' && setStep3FirstName(firstName);
            step3LastName === '' && setStep3LastName(lastName);
            addressPhone === '' && setAddressPhone(accountPhone);
            colorLog('all field on step 1 are valid', 'lightgreen')
            return true
        }
    }
    const referencesStep1 = {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        accountPhone, setAccountPhone,
        validAccountPhone, setValidAccountPhone,
        password, setPassword,
        passwordConfirmation, setPasswordConfirmation,
        showErrMsg, errMsg,
    }


    //STEP 2
    const [companyName, setCompanyName] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [website, setWebsite] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [siret, setSiret] = useState();
    const [emailOfReferrer, setEmailOfReferrer] = useState('');

    const checkFields_step2 = () => {
        setErrMsg('');
        if (companyName === '') {
            setErrMsg('Company is mandatory');
            return false
        }

        if (emailOfReferrer !== '' && !regex(emailOfReferrer, 'email')) {
            setErrMsg('Email address of referrer is not correct');
            return false
        }

        /*if (website === '') {
            setErrMsg('Website address is mandatory');
            return false
        }*/
        /*if (businessType === '') {
            setErrMsg('Business type is mandatory');
            return false
        }*/
        /*if (!regex(siret, 'siret')) {
            setErrMsg('Siret number must consist of 14 digits');
            return false
        }*/

        return true;
    }
    const checkStep2 = () => {
        colorLog("check invalid fields of step 2", 'orange');
        if (!checkFields_step2()) {
            colorLog('at least one field on step 2 is not valid', 'darkred');
            return false
            //This is here just to test status code from backend (for double check)
            if (process.env.NODE_ENV !== "development") {
                return;
            } else {
                colorLog("regex check with bad result but not prod environment", 'darkorange');
            }
        } else {
            colorLog('all field on step 2 are valid', 'lightgreen')
            return true
        }
    }
    const referencesStep2 = {
        companyName, setCompanyName,
        companiesList,
        website, setWebsite,
        businessType, setBusinessType,
        siret, setSiret,
        emailOfReferrer, setEmailOfReferrer,
        showErrMsg, errMsg
    }


    //STEP 3
    const [step3FirstName, setStep3FirstName] = useState('');
    const [step3LastName, setStep3LastName] = useState('');
    const [step3Address, setStep3Address] = useState('');
    const [step3AddressComplement, setStep3AddressComplement] = useState('');
    const [step3PostalCode, setStep3PostalCode] = useState('');
    const [step3City, setStep3City] = useState('');
    const [step3Country, setStep3Country] = useState('');
    const [countriesList, setCountriesList] = useState([]);

    const [addressPhone, setAddressPhone] = useState('');
    const [validAddressPhone, setValidAddressPhone] = useState(false);

    const [step3Alias, setStep3Alias] = useState('');
    const [step3AdditionalInformation, setStep3AdditionalInformation] = useState('');
    const [step3TermsOfService, setStep3TermsOfService] = useState(false);

    const checkFields_step3 = () => {
        setErrMsg('');
        if (!regex(step3FirstName, 'name')) {
            setErrMsg('First name must have at least 2 characters');
            return false
        }
        if (!regex(step3LastName, 'name')) {
            setErrMsg('Last name must have at least 2 characters');
            return false
        }
        if (step3Alias === '') {
            setErrMsg('Address alias is mandatory');
            return false
        }
        if (step3Address === '') {
            setErrMsg('Address is mandatory');
            return false
        }
        if (!regex(step3PostalCode, 'zipCode')) {
            setErrMsg('Wrong Zip code');
            return false
        }
        if (step3City === '') {
            setErrMsg('City is mandatory');
            return false
        }
        if (step3Country === '') {
            setErrMsg('Country is mandatory');
            return false
        }
        if (validAddressPhone || addressPhone === '') {
            setErrMsg('Wrong phone number');
            return false
        }

        return true;
    }
    const referencesStep3 = {
        setConnectToBackend,
        firstName, lastName,
        step3FirstName, setStep3FirstName,
        step3LastName, setStep3LastName,
        step3Alias, setStep3Alias,
        step3Address, setStep3Address,
        step3AddressComplement, setStep3AddressComplement,
        step3PostalCode, setStep3PostalCode,
        step3City, setStep3City,
        step3Country, setStep3Country, countriesList,
        addressPhone, setAddressPhone,
        validAddressPhone, setValidAddressPhone,
        step3AdditionalInformation, setStep3AdditionalInformation,
        step3TermsOfService, setStep3TermsOfService,
        showErrMsg, errMsg
    }


    // GET COMPANIES LIST
    useEffect(() => {
        /*(async () => {
            setConnectToBackend(true);
            await axios.get(BASE_URL + "/shop/countries?itemsPerPage=244", {headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }})
                .then(
                    (response) => {
                        setCountriesList(response.data);
                        logInConsole(`Countries list for address set`, 'forestgreen');
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        console.log("---> ", resMessage);
                        console.log('error when obtaining countries list');
                    }
                ).finally(setConnectToBackend(false))
        })();*/
        setCompaniesList([
            {
                id: 1,
                name: `Salii's corp`,
                website: 'www.salii.fr',
                businessType: 5,
                siret: 90948132700015,
                emailOfReferrer: 'contact@salii.fr'
            },
            {
                id: 2,
                name: `Grooms' corp`,
                website: 'www.grooms.fr',
                businessType: 2,
                siret: '90948132500014',
                emailOfReferrer: 'contact@grooms.fr'
            }
        ])
    }, [])

    // GET COUNTRIES LIST
    useEffect(() => {
        (async () => {
            setConnectToBackend(true);
            await axios.get(BASE_URL + "/shop/countries?itemsPerPage=244", {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
                .then(
                    (response) => {
                        setCountriesList(response.data);
                        logInConsole(`Countries list for address set`, 'forestgreen');
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        console.log("---> ", resMessage);
                        console.log('error when obtaining countries list');
                    }
                ).finally(setConnectToBackend(false))
        })();
    }, [])

    // RESET ERROR MESSAGE ON TYPING
    useEffect(() => {
        setErrMsg('');
    }, [firstName, lastName, email, accountPhone, password, passwordConfirmation,
        companyName,
        step3FirstName, step3LastName, step3Address, step3PostalCode, step3City, step3Country, addressPhone
    ])


    // GTM STATES
    //const [gtmValidatedSteps, setGtmValidatedSteps] = useState([false, false, false]);

    // GTM EVENT : sign_up_form | sign_up_form-submit
    const gtmSignUpForm = () => {
        // if (gtmValidatedSteps[activeStep]) {
        //     return
        // }
        // setGtmValidatedSteps( gtmValidatedSteps.map((step, index) => {
        //     return index === activeStep ? true : step
        // }));

        handleSignUpFormEvent(activeStep);

        if (activeStep === 2) {
            handleSignUpFormSubmitEvent();
        }
    }

    // GTM VIEW : VirtualPageview
    useEffect(() => {
        handleVirtualPageView("register", activeStep);
    }, [activeStep]);


    // STEPS SETTINGS
    const handleNextStep = async (e) => {
        e.preventDefault();
        setErrMsg('');
        window.scrollTo({top: 0});
        if ((activeStep === 0 && await checkStep1()) || (activeStep === 1 && checkStep2())) {
            setActiveStep(activeStep + 1);
            gtmSignUpForm();
        } else {
            colorLog("can't pass to next step as some value as not correct", 'red');
        }
    }
    const handlePreviousStep = () => {
        setErrMsg('');
        window.scrollTo({top: 0});
        setActiveStep(activeStep - 1);
    }


    // SUBMIT
    const handleLoginAccount = (e) => {
        e.preventDefault();
        props.handleView('login');
    }
    const [showResult, setShowResult] = useState(false);
    const handleSubmit = async e => {
        e.preventDefault();
        //check fields
        if (!checkFields_step3()) {
            colorLog('at least one field on step 3 is not valid', 'darkred');
            return
        } else {
            colorLog('all field on step 3 are valid', 'lightgreen')
        }

        //check TOS
        if (!step3TermsOfService) {
            //TODO translation
            setErrMsg('You must accept terms of service first')
            return
        }

        setConnectToBackend(true);

        const data = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: accountPhone,
            email: email,
            password: password,
            subscribedToNewsletter: false,
            emailOfReferrer: emailOfReferrer,
            company: companyName,
            website: website,
            businessType: businessType,
            siretNumber: siret,
            address: {
                firstName: step3FirstName,
                lastName: step3LastName,
                alias: step3Alias,
                street: step3Address,
                postcode: step3PostalCode,
                city: step3City,
                countryCode: step3Country,
                phoneNumber: addressPhone,
                company: companyName,
                addressComplement: step3AddressComplement,
                addressAdditionalInfo: step3AdditionalInformation
            }
        };

        axios.post(BASE_URL + '/shop/customers/customized', JSON.stringify(data), {
            headers: {'Content-Type': 'application/json'},
        })
            .then((response) => {
                colorLog(response?.status, 'cyan');
                if (response?.status === 200) {
                    gtmSignUpForm();
                    colorLog('new customer successfully created', 'green');
                    setShowResult(true);
                }
            })
            .catch(error => {
                setActiveStep(0);
                colorLog(`Error ${error.response.status}: create new customer failed`, 'red');
                setErrorBackendConnection(true);
                ErrorHandling(null, error.response.data);
            })
            .finally(() => {
                setConnectToBackend(false);
            });
    }


    return (
        <>
            {/*LOADING BAR BACKEND*/}
            {connectToBackend ? <BackendConnection/> : null}

            <section className="register-part">

                {
                    showResult ? <RegisterResult handleLoginAccount={handleLoginAccount}/> :
                        <>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {/*STEP RENDER*/}
                            {activeStep === 0 ?
                                <Step1 regex={regex} referencesStep1={referencesStep1}/> : activeStep === 1 ?
                                    <Step2 referencesStep2={referencesStep2}/> :
                                    <Step3 referencesStep3={referencesStep3}/>}


                            {/*TERMS AND SERVICES*/}
                            {activeStep === (steps.length - 1) ?
                                <>
                                    <Box className="register-terms-services">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        size="small"
                                                        onChange={e => setStep3TermsOfService(e.target.checked)}
                                                        checked={step3TermsOfService}
                                                    />
                                                }
                                                label={t("register.iAgreeToTheTermsOfServices")}
                                            />
                                        </FormGroup>
                                        <a href={"https://www.icape-group.com/icape-group-legal-mentions"}
                                           target="_blank">
                                            {t("register.termsOfServices")}
                                        </a>
                                    </Box>
                                </>
                                : ''
                            }

                            {/*PREVISOUS NEXT VALIDATE CTA*/}
                            <Box className="register-cta">
                                {activeStep > 0 && activeStep <= steps.length ?
                                    <ButtonIcapeOutlined
                                        onClick={handlePreviousStep}>{t("cta.previous")}</ButtonIcapeOutlined>
                                    : ''}

                                {activeStep < (steps.length - 1) ?
                                    <ButtonIcape onClick={e => handleNextStep(e)}>{t("cta.next")}</ButtonIcape>
                                    : ''}

                                {activeStep === (steps.length - 1) ?
                                    <ButtonIcapeGreen
                                        onClick={handleSubmit}
                                        disabled={!step3TermsOfService}
                                        endIcon={<AssignmentIndIcon/>}
                                    >
                                        {t("cta.validate")}
                                    </ButtonIcapeGreen>
                                    : ''}
                            </Box>

                            <div className="login-switch">
                                {t('register.alreadyHaveAnAccount')}
                                <span onClick={handleLoginAccount}>{t("common.login")}</span>
                            </div>
                        </>
                }
            </section>
        </>
    )
}

export default Register;
