import React, {useState} from "react";

import {ButtonIcape} from "../button/button-icape";
import {Box, FormControl, FormGroup, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";


export const Newsletter = () =>{
    const {t} = useTranslation();
    const [textValue, setTextValue] = useState("");
    const onTextChange = e => setTextValue(e.target.value);
    const handleSubmit = () => alert(textValue);

    return(
        <>
            <span style={{color: '#37b0f1', marginTop: '20px'}}>{t("footer.subscribeToOurNewsletter")}</span>

            <Box className="footer-nl">
                <TextField
                    onChange={onTextChange}
                    value={textValue}
                    label={t("common.email")}
                />

                <ButtonIcape onClick={handleSubmit}>{t("cta.submit")}</ButtonIcape>
            </Box>
        </>
    )
}