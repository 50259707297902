import {Box} from "@mui/material";
import "./popup.scss";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import {useState} from "react";

function Popup() {

    const learnMoreLink = "https://www.icape-group.com/why-could-chinese-new-year-celebrations-affect-businesses/?utm_source=pop%20up&utm_medium=ishop&utm_campaign=CNY";
    const ourInHouseLink = "https://www.icape-group.com/local-manufacturing/?utm_source=pop%20up&utm_medium=ishop&utm_campaign=CNY-localman";


    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const handleClose = () => {
        setIsPopupOpen(false);
        localStorage.setItem("popup", JSON.stringify({
            showed: true,
        }));
    }


    return(
        <>
            {
                isPopupOpen &&
                <Box className="popup-background" onClick={() => handleClose()}>
                    <Box className="popup" onClick={(e) => e.stopPropagation()}>
                        <Box display="flex" justifyContent="space-between">
                            <h2>
                                Plan Ahead to Avoid Disruptions During the <span>Chinese New Year!</span>
                            </h2>
                            <CloseIcon color="error" cursor="pointer" onClick={() => handleClose()} />
                        </Box>
                        <Typography component="p" paddingTop="5px">
                            2025 CNY: January 27 to February 5, 2025.
                        </Typography>

                        <Box className="content">
                            <Box>
                                <span>Order Early</span>
                                <p>
                                    To avoid delays, please place your orders ahead of this period.
                                </p>
                            </Box>
                            <Box>
                                <span>Uninterrupted Solutions</span>
                                <p>
                                    Our Swedish and South African production facilities remain fully operational and
                                    unaffected by CNY.
                                </p>
                            </Box>
                            <Box marginTop="30px">
                                <p>🎯 Stay on schedule with our global solutions!</p>
                                <Box paddingLeft="27px">
                                    <Link to={learnMoreLink} target="_blank">Learn more</Link>
                                    <span>&nbsp;|&nbsp;</span>
                                    <Link to={ourInHouseLink} target="_blank">Our in house manufacturing</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}

export default Popup;