import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import './checkout.scss';
import {ButtonIcape} from "../button/button-icape";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import BackendConnection from "../backend-connection/backend-connection";
import {getStorageValue} from "../../tools/cookie-handler";
import {logInConsole} from "../../tools/debug";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CalculatorAluminumContext from "../../context/calculatorAluminumProvider";
import CalculatorHdiContext from "../../context/calculatorHdiProvider";
import CalculatorFlexContext from "../../context/calculatorFlexProvider";
import CalculatorRigidContext from "../../context/calculatorRigidProvider";
import {snackbarTransition} from "../../tools/snackbarTransition";

function Checkout() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [backendConnection, setBackendConnection] = useState(false);
    const [backendError, setBackendError] = useState({
        errorMessage: "",
        isError: false
    })

    const axiosPrivate = useAxiosPrivate();
    const config = {headers: {"Content-Type": "application/json", "Accept": "application/json"}};

    const cart = getStorageValue("cart");
    const locale = getStorageValue("userPreferences");

    const [isCheckoutVerified, setIsCheckoutVerified] = useState(false);
    const [bankWireData, setBankWireData] = useState({});

    // CONTEXT SELECTION SECTION
    const checkout = getStorageValue('cart')?.checkout;
    const { resetAllAluminum } = useContext(CalculatorAluminumContext);
    const { resetAllHdi } = useContext(CalculatorHdiContext);
    const { resetAllFlex } = useContext(CalculatorFlexContext);
    const { resetAllRigid } = useContext(CalculatorRigidContext);

    const calculatorContexts = {
        "aluminum": CalculatorAluminumContext,
        "hdi": CalculatorHdiContext,
        "flex": CalculatorFlexContext,
    };
    const calculatorUsed = calculatorContexts[checkout?.calculator] || CalculatorRigidContext;


    /**
     * Clear local storage 'cart' and reset React states
     */
    const clearAllData = () => {
        localStorage.removeItem('cart');

        calculatorUsed === CalculatorAluminumContext && resetAllAluminum();
        calculatorUsed === CalculatorHdiContext && resetAllHdi();
        calculatorUsed === CalculatorFlexContext && resetAllFlex();
        calculatorUsed === CalculatorRigidContext && resetAllRigid();
    }

    /**
     * Axios POST adding customer order reference to an order
     */
    const updateCustomerOrderReference = async () => {
        const _orderToken = cart?.orderToken;
        const _customerOrderReference = cart?.customerOrderReference;

        if (_customerOrderReference === undefined || _customerOrderReference === "") {
            setIsCheckoutVerified(true);
            clearAllData();
            return
        }

        const data = {
            "customerOrderReference": _customerOrderReference
        };

        try {
            setBackendConnection(true)
            await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/order/${_orderToken}/update-customer-order-reference-checkout`, data, config);
            logInConsole(`Customer order reference has been added to order ${_orderToken}`, 'green');

        } catch (error) {
            logInConsole(`Failed to add customer order reference to order ${_orderToken}`, 'red');
            setBackendError({
                ...backendError,
                isError: true,
                errorMessage: `${error.response.status} - ${t("error.checkout.customerOrderReference")}`
            })

        } finally {
            setBackendConnection(false);
            setIsCheckoutVerified(true);
            clearAllData();
        }
    }

    /**
     * Axios GET bank wire details to display to customer
     */
    const getBankWireDetails = async () => {

        const _orderToken = cart?.orderToken

        setBackendConnection(true);
        await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/payment/${_orderToken}/getBankWireDetails/${locale?.activeLanguage}`, config)
            .then(
                (response) => {
                    setIsCheckoutVerified(true);
                    const { amount, currency, instructions, paymentterms } = response.data;
                    setBankWireData({ amount, currency, instructions, paymentterms })

                    logInConsole(`Get details OK`, 'cyan');
                    logInConsole(`Clearing cookies`, 'green');
                },
                (error) => {
                    logInConsole('Error : Get details KO', 'grey');

                }
            )
            .finally(
                () => {
                    setBackendConnection(false);
                })
        };


    /**
     * @boot Get localStorage cart to update order state
     */
    useEffect(async () => {
        if (cart === undefined || cart === null) {
            navigate('/user/orders', {replace: true});
            return
        }
        switch (cart.checkout?.method) {
            case 'card':
                logInConsole('card method used', "purple");
                await updateCustomerOrderReference();
                setIsCheckoutVerified(true);
                break;
            case 'bank wire':
                logInConsole('bank wire method used', "purple");
                await updateCustomerOrderReference();
                break;
            case 'paypal':
                logInConsole('Paypal method used', "purple");
                await updateCustomerOrderReference();
                break;
            default:
                console.log(`Payment method unknown :/`);
        }
    }, [])


    return (
        <>
            {/*PROGRESS BAR CONNECT TO BO*/}
            {backendConnection ? ( <BackendConnection/> ) : ""}

            {isCheckoutVerified ?
                <Box className="checkout-card">
                    <Typography variant={"h1"}>
                        <CheckCircleIcon sx={{fontSize: "40px", fill:"#007dc8"}}/>
                        {t("checkout.orderConfirmed.title")}
                    </Typography>
                    <Box>
                        <span>{t("checkout.orderConfirmed.thanks")} </span> <br/>
                        <span>{t("checkout.orderConfirmed.orderProcessed")}</span>
                    </Box>


                    {bankWireData?.amount > 0 &&
                        <>
                            {/*<h4>Please, send your payment amount of <span>{bankWireData.amount} {bankWireData.currency}</span>*/}
                            {/*    <br/>*/}
                            {/*    by bank transfer to ICAPE GROUP*/}
                            {/*</h4>*/}

                            <h4>Please, send your payment by bank transfer to ICAPE GROUP</h4>

                            {/*<Box className="bank-information-container">*/}
                            {/*    <span>Bank code : {bankWireData.instructions?.bankCode}</span>*/}
                            {/*    <span>Branch code : {bankWireData.instructions?.branchCode}</span>*/}
                            {/*    <span>Account number : {bankWireData.instructions?.accountNumber}</span>*/}
                            {/*    <span>RIB Key : {bankWireData.instructions?.ribKey}</span>*/}
                            {/*    <span>IBAN : {bankWireData.instructions?.iban}</span>*/}
                            {/*    <span>SWIFT/BIC : {bankWireData.instructions?.swiftBic}</span>*/}
                            {/*    <span>Paiement Terms : {bankWireData.paymentterms} </span>*/}
                            {/*</Box>*/}
                        </>
                    }

                    <ButtonIcape onClick={() => { navigate('/', { replace: false}) }}>
                        <ThumbUpIcon sx={{fontSize: "20px", fill:"#ffffff"}}/>
                        {t("cta.backToCalculators")}
                    </ButtonIcape>
                </Box>
                :
                // TODO : waiting animation
                <Box sx={{height: '600px'}}></Box>
            }

            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={backendError.isError}
                      key='topright'
                      sx={{ width: '100%' }}
                      autoHideDuration={10000}
                      TransitionComponent={snackbarTransition}
                      onClose={() => {
                          setBackendError({...backendError, errorMessage: "", isError: false})
                      }}>
                <Alert severity="warning" variant="filled"
                       onClose={() => {
                           setBackendError({...backendError, errorMessage: "", isError: false})
                       }}>
                    {backendError.errorMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Checkout
