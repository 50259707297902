import useAxiosPrivate from "./useAxiosPrivate";
import {useContext, useEffect, useState} from "react";
import useAuth from "./useAuth";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../tools/debug";
import useOrder from "./useOrder";
import {useNavigate} from "react-router-dom";
import {getCompanyAddresses, getCustomerAddresses, getCustomerData} from "../services/customer.service";
import CalculatorAluminumContext from "../context/calculatorAluminumProvider";
import CalculatorHdiContext from "../context/calculatorHdiProvider";
import CalculatorFlexContext from "../context/calculatorFlexProvider";
import CalculatorRigidContext from "../context/calculatorRigidProvider";
import {getStorageValue} from "../tools/cookie-handler";
import UserContext from "../context/UserProvider";
import {
    handleAddPaymentInfoEvent,
    handleBeginCheckoutEvent,
    handlePurchaseEvent,
    handleRemoveFromCartEvent
} from "../tools/google-tag-manager-events";
import {ErrorHandling} from "../tools/error-handling";

const useCartProcess = () => {

    const navigate = useNavigate();

    // CONTEXT SELECTION SECTION
    const checkout = getStorageValue('cart')?.checkout;
    const { resetAllAluminum } = useContext(CalculatorAluminumContext);
    const { resetAllHdi } = useContext(CalculatorHdiContext);
    const { resetAllFlex } = useContext(CalculatorFlexContext);
    const { resetAllRigid } = useContext(CalculatorRigidContext);
    const {companyId, isTestAccount} = useContext(UserContext);


    const calculatorContexts = {
        "aluminum": CalculatorAluminumContext,
        "hdi": CalculatorHdiContext,
        "flex": CalculatorFlexContext,
    };
    const calculatorUsed = calculatorContexts[checkout?.calculator] || CalculatorRigidContext;


    const {orderToken, setOrderToken, setConnectToBO, setProductVariant, repeatOrderMode, userGerber, userSpecFile, priceOfferLocale} = useContext(calculatorUsed);
    const resetAllCheckoutCalculator = () => {
        calculatorUsed === CalculatorAluminumContext && resetAllAluminum();
        calculatorUsed === CalculatorHdiContext && resetAllHdi();
        calculatorUsed === CalculatorFlexContext && resetAllFlex();
        calculatorUsed === CalculatorRigidContext && resetAllRigid();
    }



    const auth = useAuth();
    const customerId = auth.auth.customerId;
    const customerEmail = auth.auth.username;

    const order = useOrder();

    const axiosPrivate = useAxiosPrivate();
    const config = {headers: {"Content-Type": "application/json", "Accept": "application/json"}};
    const configWithPatch = {headers: {"Content-Type": "application/merge-patch+json", "Accept": "application/json"}};

    const [checkoutState, setCheckoutState] = useState(null);
    const [warnUser, setWarnUser] = useState(false);
    const [warnUserData, setWarnUserData] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [shippingCost, setShippingCost] = useState([]);
    const [orderPricing, setOrderPricing] = useState({});
    let currencyCode = '';
    const [customerAddresses, setCustomerAddresses] = useState([]);
    const [shipmentMethod, setShipmentMethod] = useState({});
    const [paymentMethod, setPaymentMethod] = useState({});
    const [tokenValue, setTokenValue] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [isCartReadyToProcessPayment, setIsCartReadyToProcessPayment] = useState(false);

    //Promotional coupon code
    const [couponCode, setCouponCode] = useState(null);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
    const [orderPromotionTotal, setOrderPromotionTotal] = useState(0);
    const [isWrongPromoCode, setIsWrongPromoCode] = useState(false);
    const [voucherList, setVoucherList] = useState([]);

    const [orderItemOk, setOrderItemOk] = useState(false);

    const getGtmItems = () => {
        let gtmItems = []
        const calculator = getStorageValue('cart')?.checkout.calculator

        cartItems.length > 1 ?
            gtmItems =  [{
                item_name: 'Stencil',
                item_id: tokenValue,
                price: cartItems[0].subtotal / 100,
                item_variant: calculator,
                quantity: cartItems[0].quantity
            },{
                item_name: 'PCB',
                item_id: tokenValue,
                price: cartItems[1].subtotal / 100,
                item_variant: calculator,
                quantity: cartItems[1].quantity
            }]
            :
            gtmItems =  [{
                item_name: 'PCB',
                item_id: tokenValue,
                price: cartItems[0].subtotal / 100,
                item_variant: calculator,
                quantity: cartItems[0].quantity
            }]

        return gtmItems
    }


    /**
     * call backend to get orders list for current customer
     * @param _customerId
     * @returns {Promise<AxiosResponse<any>>}
     */
    async function getCartIfExists(_customerId) {
        //TODO CHECK SECURITY OF THIS ROUTE
        setConnectToBO(true)
        return await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/cartChecking/${_customerId}`, config).then(
            (response) => {
                setConnectToBO(false)
                if(response.data === null) {
                    logInConsole('No order found in DB', 'orange');
                }

                return response.data;
            },
            (error) => {
                setConnectToBO(false)
                logInConsole(`error when getCartIfExist: ${error} | ( code ${error.response.status})`, 'red');
                return error;
            }
        );
    }



    /**
     * @Boot
     * First get Customer addresses.
     * Then check if there is an open order in database and cart cookie.
     */
    useEffect(async () => {
        // Fetching customer addresses

        // TODO ALLER CHERCHER DANS LES CONTEXT USER PUISQUON FAIT DEJA LES APPELS ?
        const fetchAddresses = async () => {
            try {
                setConnectToBO(true);

                // 1. Première requête => info client pour company ID
                const _customerData = await getCustomerData(customerId);
                const _companyID = _customerData.company.id;

                // 2. Deuxième requête => customer addresses
                let _globalAddresses = await getCustomerAddresses(customerId);

                // 3. Troisième requête => ajout des company addresses
                const companyAddresses = await getCompanyAddresses(customerId, _companyID);
                setCustomerAddresses(_globalAddresses.concat(companyAddresses));

            }
            catch (error) {
                logInConsole('Error during addresses fetching', 'red');
            }
            finally {
                setConnectToBO(false);
            }
        };
        await fetchAddresses();

        // Cheking open order in database and local storage
        await (async () => {
            await order.setOrders(await getCartIfExists(customerId));

            let cartCookie = localStorage.getItem('cart');

            if (order.isOpenOrderExists()) {
                //order find in DB

                if (cartCookie != null) {
                    cartCookie = JSON.parse(cartCookie);

                    //order find in cookie (le plus long)

                    if (cartCookie.orderToken === order.getTokenValue()) {
                        //tokens are equal
                        loadOrderFromBackend(order.getTokenValue());
                    } else {
                        logInConsole('orderToken mismatch, creating new order', 'pink');
                        await deleteOrderOnBackend(order.getTokenValue());
                        await createNewOrder();
                    }

                } else {
                    //no cart cookie
                    loadOrderFromBackend(order.getTokenValue());
                }
            } else {
                //no order in DB

                if (cartCookie != null) {
                    cartCookie = JSON.parse(cartCookie);
                    if (Object.keys(cartCookie.productVariant).length > 0) {
                        createNewOrder()
                    } else {
                        logInConsole("Product variant is empty", 'red');
                        logInConsole(`Cart cookie product variant length : ${Object.keys(cartCookie.productVariant).length}`, 'purple');
                        localStorage.removeItem('cart');
                        navigate('/');
                    }
                } else {
                    logInConsole(`Cart cookie is : ${cartCookie}`, 'grey');
                    navigate('/');
                }
            }
        })();

    }, []);


    const loadOrderFromBackend = (_orderToken) => {
        setTokenValue(_orderToken);
        setOrderToken(_orderToken);
        setCheckoutState(order.getCheckoutState());
        setCartItems(order.getItems());
        setShippingCost(order.getShippingCost());
        localStorage.setItem('cart', JSON.stringify({
            ...getStorageValue('cart'),
            orderId: order.getId()
        }));
        setOrderItemOk(true);
    }
    const cancelCart = async () => {
        logInConsoleSpacer()
        logInConsole(`Cancelling order`, 'darkmagenta', 'bold');

        await deleteOrderOnBackend(orderToken);
        setOrderToken('');
        if (!repeatOrderMode.enabled) {
            setProductVariant({});
            logInConsole('No repeat order mode, product variant cleared', 'green');
            logInConsole('');
        }

        // GTM EVENT : remove_from_cart
        handleRemoveFromCartEvent(getGtmItems(), orderPricing.taxTotal, currencyCode)

        localStorage.removeItem('cart');
        navigate(`/${checkout?.calculator}`, {replace: true});
    }
    const deleteOrderOnBackend = async (_orderToken) => {
        setConnectToBO(true);
        await axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${_orderToken}`, config)
            .then(
                (response) => {
                    logInConsole(`deleted cart order [${_orderToken}]`, 'violet');
                },
                (error) => {
                    logInConsole('error when delete cart order', 'red')
                }
            ).finally(
                () => {
                    setConnectToBO(false);
                })
    }
    const addItemsToOrder = async (_orderToken, _cartData) => {
        logInConsoleSpacer();
        logInConsole('Try to add item to cart ', 'darkmagenta', 'bold');

        let cartItemTemp = [];
        let productLoopId = 0;
        for (const product of _cartData?.productVariant) {
            let data = {"productVariant": product.code, "quantity": product.quantity};
            setConnectToBO(true);
            await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${_orderToken}/items`, JSON.stringify(data), config).then(
                (response) => {
                    setConnectToBO(false);
                    logInConsole(`Item ${product.code} added to order [${response.data.tokenValue}]`, 'green');
                    setCheckoutState(response.data.checkoutState)
                    cartItemTemp = response.data.items;
                    if (productLoopId ===  _cartData.productVariant.length - 1 ) {
                        setOrderItemOk(true);
                        productLoopId = 0;
                    }
                },
                (error) => {
                    setConnectToBO(false);
                    logInConsole('error when add product to order', 'red');
                    ErrorHandling(getStorageValue('cart')?.checkout?.calculator, error.response.data);
                }
            )
            productLoopId ++
        }
        setCartItems(cartItemTemp);
    }
    const createNewOrder = async () => {

        //TODO what to do if createOrder without data ?
        let cookieCart = localStorage.getItem('cart');
        if (!cookieCart) {
            //Warn user or go back to calculator ?
            logInConsole('no cookieCart > Warn user or go back to calculator ?', 'red');
            return
        }
        logInConsole('Cart found in local storage', 'grey', 'bold');
        logInConsoleObject(JSON.parse(cookieCart));

        const cartData = JSON.parse(localStorage.getItem('cart'));


        if (!cartData.productVariant.length > 0) {
            //Warn user or go back to calculator ?
            logInConsole('no productVariant > Warn user or go back to calculator ?', 'red');
            return
        }

        logInConsoleSpacer();
        logInConsole('Try to create a new order', 'darkmagenta', 'bold');

        let data = {"localeCode": priceOfferLocale};
        await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders`, data, config).then(
            (response) => {
                logInConsole(`Order ${response.data.id} created with tokenValue : ${response.data.tokenValue}`, 'green');
                setTokenValue(response.data.tokenValue);
                setOrderToken(response.data.tokenValue);
                setCheckoutState(response.data.checkoutState);
                localStorage.setItem('cart', JSON.stringify({
                    ...getStorageValue('cart'),
                    orderId: response.data.id
                }));
                addItemsToOrder(response.data.tokenValue, cartData);
            },
            (error) => {
                logInConsole('error when create order', 'red');
            }
        )

    }
    const showWarningToUser = (_newState) => {
        setWarnUser(_newState);
        //if need to have more informations (like object creation with message support ...)
    }


    /**
     * Set Delivery and billing addresses only if they both exist
     * @param _addresses
     */
    const handleAddressesChooseByCustomer = (_addresses) => {
        if (_addresses === undefined || _addresses.delivery === undefined || _addresses.billing === undefined) {
            return;
        }
        setSelectedDeliveryAddress(customerAddresses.find(address => address.id === _addresses.delivery));
        setSelectedBillingAddress(customerAddresses.find(address => address.id === _addresses.billing));
    }

    /**
     * Set voucher (only if something is entered in the field - don't use real code in client side !!!
     * @param _voucher
     */
    const handleVoucherChooseByCustomer = (_voucher) => {
        if (_voucher === undefined || _voucher === '' ) {
            return;
        }
        if (_voucher === null) {
            setOrderPromotionTotal(0);
        }
        setCouponCode(_voucher);
    }

    /**
     * if (all) addresses is filled OR if voucher code is filled, then update order status
     */
    useEffect(() => {
        if (orderItemOk && tokenValue && selectedDeliveryAddress) {
            setAddresses();
        }
    }, [selectedBillingAddress, selectedDeliveryAddress, couponCode, tokenValue, orderItemOk])

    /**
     * PUT state to addressed
     */
    const setAddresses = () => {
        logInConsoleSpacer();
        logInConsole('Try to PUT customer addresses to cart', 'darkmagenta', 'bold');

        let data = {
            "email": customerEmail,
            "billingAddress": selectedBillingAddress,
            "shippingAddress": selectedDeliveryAddress,
            "couponCode": couponCode
        }

        setConnectToBO(true);
        setIsCartReadyToProcessPayment(false);
        axiosPrivate.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${tokenValue}`, data, config).then(
            async (response) => {
                setConnectToBO(false);

                //TODO REMOVE PROPERLY SHIPPING EVERYWHERE
                !isNaN(response.data.shippingTotal) && setShippingCost(response.data.shippingTotal);
                !isNaN(response.data.taxTotal) && setOrderPricing({
                    taxTotal: response.data.taxTotal / 100,
                    totalAllInclude: response.data.total / 100
                });

                logInConsole('shipping methods received', 'green');

                await getCurrencyFromPriceOffer(tokenValue);
                logInConsole(`Price offer currency obtained : ${currencyCode}`, 'blue');

                if (selectedBillingAddress) {

                    let shipment_method = response.data.shipments[0].method;
                    shipment_method = shipment_method.substring(shipment_method.lastIndexOf('/') + 1, shipment_method.length);
                    setShipmentMethod({'id': response.data.shipments[0].id, 'method': shipment_method});

                    setOrderStateToShipmentSelected({
                        'id': response.data.shipments[0].id,
                        'method': shipment_method
                    });
                }
                if (couponCode) {
                    setOrderPromotionTotal(response.data.orderPromotionTotal);
                }
            },
            (error) => {
                setConnectToBO(false);
                logInConsole('error when put billing and delivery address', 'red');
                if(error.response.status === 422)
                {
                    if (error.response.data.detail.indexOf('code is invalid')) {
                        setIsWrongPromoCode(true);
                        setIsCartReadyToProcessPayment(true);
                    }
                    if (error.response.data.detail.indexOf('unprocessable entity')) {
                        logInConsole('422 : unprocessable entity', 'red');
                        ErrorHandling(getStorageValue('cart')?.checkout?.calculator, error.response.data);
                    }
                }
            }
        )
    }

    /**
     * For now, currency in order DB isn't correct. we have to reach the currency of the price offer
     * @param _tokenValue
     */
    const getCurrencyFromPriceOffer = async (_tokenValue) => {

        logInConsole('Querying price offer currency', 'blue');
        await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/price-offer/${_tokenValue}/currency`, config)
            .then(
                (response) => {
                    currencyCode = response.data.currency;
                },
                (error) => {
                    logInConsole('Error when getting price offer currency', 'red');
                }
            )
            .finally(() => {
                setConnectToBO(false);
            })
    }

    // GTM EVENT : begin_checkout
    useEffect(() => {
        if(currencyCode !== '') {
            handleBeginCheckoutEvent(
                getGtmItems(),
                orderPricing.totalAllInclude - orderPricing.taxTotal,
                orderPricing.taxTotal,
                currencyCode
                );
        }
    }, [currencyCode]);


    /**
     * PATCH state to shipment_selected
     */
    const setOrderStateToShipmentSelected = (_shipmentMethod) => {
        if (!tokenValue || _shipmentMethod.method === '') {
            return
        }

        logInConsoleSpacer();
        logInConsole('Try to PATCH state to shipment_selected', 'darkmagenta', 'bold');

        let data = {"shippingMethod": _shipmentMethod.method}
        setConnectToBO(true);
        axiosPrivate.patch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${tokenValue}/shipments/${_shipmentMethod.id}`, data, configWithPatch).then(
            (response) => {
                setConnectToBO(false);
                logInConsole('PATCH to shipment_selected OK', 'green');
                setCheckoutState(response.data.checkoutState);
                let payment_method;
                if (paymentMethod !== null) {
                    payment_method = paymentMethod.method;
                } else {
                    payment_method = response.data.payments[0].method;
                    payment_method = payment_method.substring(payment_method.lastIndexOf('/') + 1, payment_method.length);

                }
                setPaymentMethod({id: response.data.payments[0].id, method: payment_method})

                //search all available payment methods with this payment
                getPaymentMethods(response.data.payments[0].id);
            },
            (error) => {
                setConnectToBO(false);
                logInConsole('ERROR when trying to patch state to shipment selected', 'red');
            }
        )
    }
    const getPaymentMethods = (_paymentMethodId) => {

        logInConsoleSpacer();
        logInConsole('Try to get all payment methods for this order', 'darkmagenta', 'bold');

        setConnectToBO(true);
        axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${tokenValue}/payments/${_paymentMethodId}/methods`, config).then(
            (response) => {
                setConnectToBO(false);

                logInConsole('Payment methods list received', 'green');
                logInConsoleObject(response.data);

                // Deleting the card with the wrong currency
                response.data = response.data.filter(item => !(item.name === "card" && item.instructions !== currencyCode));

                logInConsole('currency updated:', 'green');
                logInConsoleObject(response.data);

                setPaymentMethods(response.data);
                voucherListInitialization();
            },
            (error) => {
                setConnectToBO(false);
                logInConsole('ERROR when trying to get payment methods for a payment id', 'red');
            })
    }

    //TODO optimize this part to be called only once
    const voucherListInitialization = () => {
        logInConsole(`setting voucher list`, 'chocolate');

        let config = {headers: {"Content-Type": "application/Id+json"}};
        (async () => {
            setConnectToBO(true);
            try {
                const response = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/customers/${customerId}/vouchers-list`, config)
                setVoucherList(response.data);

            } catch (error) {
                logInConsole('ERROR when trying to get available promotions coupon', 'red');
            } finally {

                setIsCartReadyToProcessPayment(true);
                setConnectToBO(false);
            }
        })();
    }
    const setPayment = (_paymentMethod) => {
        if (_paymentMethod === undefined) {
            return;
        }
        logInConsole(`set the payment method to ${_paymentMethod}`, 'orange');
        setPaymentMethod({...paymentMethod, method: _paymentMethod});


        // GTM EVENT : add_payment_info
        if(userGerber !== {}) {
            handleAddPaymentInfoEvent(
                getGtmItems(),
                orderPricing.totalAllInclude - orderPricing.taxTotal,
                orderPricing.taxTotal,
                currencyCode,
                _paymentMethod
                );
        }
    }
    const setOrderStateToPaymentSelected = async () => {
        //if checkoutState = shippingSelected AND customerAddresse selected !!!!
        if (checkoutState === "shipping_selected") {

            logInConsoleSpacer();
            logInConsole('Try to PATCH  to payment_selected', 'darkmagenta', 'bold');

            let data = {"paymentMethod": paymentMethod.method};
            setConnectToBO(true);
            await axiosPrivate.patch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${tokenValue}/payments/${paymentMethod.id}`, data, configWithPatch).then(
                (response) => {
                    setConnectToBO(false);
                    logInConsole('PATCH to payment_selected OK', 'green');
                    setCheckoutState(response.data.checkoutState);
                },
                (error) => {
                    setConnectToBO(false);
                    logInConsole('ERROR when trying to patch state to payment selected', 'red');
                }
            )
        }
    }
    //TODO don't think this method is necessary as we do the work twice (but not in the same way) (see getPaymentMethodeCodeByName in Payment.jsx)
    const getPaymentMethodeNameByCode = (methodCode) => {
        let code;
        if(paymentMethods.length>0)
        {
            code = paymentMethods.find(paymentMethod => paymentMethod.code.toLowerCase() === methodCode.toLowerCase()).name;
        }else {
            code = methodCode;
        }
        return code;
    }

    /**
     * PATCH STATE TO COMPLETED
     */
    const proceedToCheckout = async () => {
        logInConsole(`checkoutState : ${checkoutState}`, 'lightblue');
        if (checkoutState !== 'shipping_selected') {
            //TODO Warn user to choose addresses
            logInConsole(`WARN USER TO CHOOSE ADDRESSES BEFORE CHECKOUT`, 'red');
            setWarnUser(true);
            setWarnUserData({
                "message": "You need to choose Delivery and Billing addresses before checkout."
            })
            return;
        }
        //first patch payment method
        await setOrderStateToPaymentSelected();


        // GTM EVENT : purchase
        handlePurchaseEvent(
            getGtmItems(),
            orderPricing.totalAllInclude - orderPricing.taxTotal,
            orderPricing.taxTotal,
            currencyCode,
            paymentMethod,
            couponCode
        );

        // Bankwire
        if(getPaymentMethodeNameByCode(paymentMethod.method) === "bank wire") {
            let data = {"notes": "complete"};
            setConnectToBO(true);
            axiosPrivate.patch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${tokenValue}/complete`, data, configWithPatch).then(
                async (response) => {
                    await uploadCustomerDocuments();
                    setCheckoutState(response.data.checkoutState);
                    logInConsole('ORDER COMPLETE', 'violet');
                },
                (error) => {
                    logInConsole('ERROR when trying to patch state to payment selected', 'red');
                })
                .finally(() => {
                    setConnectToBO(false);
                });
        }

        // Paypal
        if(getPaymentMethodeNameByCode(paymentMethod.method)  === "paypal") {
            // Recuperer URL PAYPAL AVEC PARAMETRES
            logInConsole("checkout Paypal, send info to backend", "orange");
        }

        // Axepta
        if(getPaymentMethodeNameByCode(paymentMethod.method)  === "card") {
            // Recuperer URL BANQUE AVEC PARAMETRES
            let data = {"notes": "complete"};
            setConnectToBO(true);
            axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/payment/${orderToken}/axepta`, data, configWithPatch).then(
                (response) => {
                    logInConsole(`Axepta bank parameters : ${response}`, 'black');

                    // REDIRECTION AXETA PAYMENT
                    document.open();
                    document.write(response.data);
                    document.close();
                },
                (error) => {
                    logInConsole('ERROR when trying to patch state to payment selected', 'red');
                })
                .finally(() => {
                    setConnectToBO(false);
                });
        }
    }

    const uploadCustomerDocuments = async () => {

        logInConsoleSpacer();
        logInConsole('Try to send customer documents', 'darkmagenta', 'bold');

        let headers = {
            'Content-Type': 'multipart/form-data',
        };
        let requestOptions = {
            headers,
            redirect: 'follow',
        };

        const orderId = getStorageValue('cart')?.orderId;

        const gerber = userGerber[0];
        const specFiles = userSpecFile;

        if (gerber && gerber.path) {
            const formData = new FormData();

            formData.append('files', gerber);
            formData.append('companyId', companyId);
            formData.append('customerId', customerId);
            formData.append('orderId', orderId);


            try {
                setConnectToBO(true)
                await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/order/gerber-upload`, formData, requestOptions)
                logInConsole(`Gerber uploaded to backend`, 'green');

            } catch (error) {
                logInConsole(`Failed to upload Gerber to backend`, 'red');

            } finally {
                setConnectToBO(false);
            }
        }

        if (specFiles && specFiles.length > 0) {

            const formData = new FormData();

            specFiles.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
            formData.append('companyId', companyId);
            formData.append('customerId', customerId);
            formData.append('orderId', orderId);

            try {
                setConnectToBO(true)
                await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/order/specifications-files-upload`, formData, requestOptions)
                logInConsole(`Spec files uploaded to backend`, 'green');

            } catch (error) {
                logInConsole(`Failed to upload documents to backend`, 'red');

            } finally {
                setConnectToBO(false);
            }
        }
    }

    useEffect(() => {
        if(orderToken==='')
        {
            return
        }
        //let productVariant = JSON.parse(localStorage.getItem('cart'))?.productVariant;
        localStorage.setItem('cart', JSON.stringify({
            ...getStorageValue('cart'),
            orderToken,
            'voucher': couponCode,
        }));
    }, [orderToken, couponCode]);

    return {
        loadOrderFromBackend,
        cancelCart,
        deleteOrderOnBackend,
        createNewOrder,
        showWarningToUser,
        shippingCost,
        orderPricing,
        cartItems,
        warnUser,
        warnUserData,
        checkoutState,
        customerAddresses,
        setAddresses,
        setPayment,
        paymentMethods,
        proceedToCheckout,
        handleAddressesChooseByCustomer,
        handleVoucherChooseByCustomer,
        orderPromotionTotal,
        isWrongPromoCode, setIsWrongPromoCode,
        voucherList,
        isCartReadyToProcessPayment,
        uploadCustomerDocuments,
    }
}

export default useCartProcess;
