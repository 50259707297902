import ErrorMessage from "../../error-message";
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {regex} from "../../../../tools/regex";
import {COUNTRIES_LIST, getCountries} from "../../../../services/countries.service";
import {logInConsole} from "../../../../tools/debug";
import {axiosPrivate, BASE_URL} from "../../../../api/axios";
import authHeader from "../../../../services/auth-header";

const Step3 = (props) => {
    const {t} = useTranslation();
    const {
        firstName, lastName,
        step3FirstName, setStep3FirstName,
        step3LastName, setStep3LastName,
        step3Alias, setStep3Alias,
        step3Address, setStep3Address,
        step3AddressComplement, setStep3AddressComplement,
        step3PostalCode, setStep3PostalCode,
        step3City, setStep3City,
        step3Country, setStep3Country, countriesList,
        addressPhone, setAddressPhone,
        validAddressPhone, setValidAddressPhone,
        step3AdditionalInformation, setStep3AdditionalInformation,
        showErrMsg, errMsg
    } = props.referencesStep3

    // PHONE FIELD CHECK
    const handleAddressPhoneNumberChange = (newPhoneNumber) => {
        setValidAddressPhone(!matchIsValidTel(newPhoneNumber));
        setAddressPhone(newPhoneNumber);
    }

    return (
        <>
            {/*TITLE*/}
            <Box className="register-title">
                <Typography variant={"h1"}>{t("common.registration")}</Typography>
                <Typography component={"span"}>{t("common.address")}</Typography>
            </Box>

            {/*ERROR MESSAGE*/}
            <Box className={showErrMsg ? "errorMessage" : ''}>
                {showErrMsg ? <ErrorMessage message={errMsg}/> : ''}
            </Box>

            {/*STEP 03*/}
            <Box className="register-fields-wrapper">
                {/*FIRSTNAME*/}
                <TextField
                    id="step3-firstName"
                    label={t("common.firstName")}
                    onChange={e => setStep3FirstName(e.target.value)}
                    value={step3FirstName}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*LASTNAME*/}
                <TextField
                    id="step3-lastName"
                    label={t("common.lastName")}
                    onChange={e => setStep3LastName(e.target.value)}
                    value={step3LastName}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*ALIAS*/}
                <TextField
                    label={t("common.addressAlias")}
                    value={step3Alias}
                    variant="outlined"
                    margin="normal"
                    onChange={e => setStep3Alias(e.target.value)}
                    required
                />

                {/*ADDRESS*/}
                <TextField
                    id="step3-address"
                    label={t("common.address")}
                    onChange={e => setStep3Address(e.target.value)}
                    value={step3Address}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*ADDRESS COMPLEMENT*/}
                {/*<TextField
                    id="step3-address-complement"
                    label={t("common.addressComplement")}
                    onChange={e => setStep3AddressComplement(e.target.value)}
                    value={step3AddressComplement}
                    variant="outlined"
                    margin="normal"
                />*/}

                {/*POSTAL CODE / ZIP CODE*/}
                <TextField
                    id="step3-posteal-code"
                    label={t("common.zipCode")}
                    onChange={e => setStep3PostalCode(e.target.value)}
                    value={step3PostalCode}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*CITY*/}
                <TextField
                    id="step3-city"
                    label={t("common.city")}
                    onChange={e => setStep3City(e.target.value)}
                    value={step3City}
                    variant="outlined"
                    margin="normal"
                    required
                />

                {/*COUNTRY*/}
                <FormControl>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId={t("common.country")}
                        id="country"
                        value={step3Country}
                        label='Country'
                        onChange={e => setStep3Country(e.target.value)}
                        sx={{width: "300px"}}
                        required
                    >
                        {countriesList.map((country) => {
                            if (country.enabled) {
                                return <MenuItem name="pays" value={country.code} key={country.id}>
                                    {country.name}
                                </MenuItem>
                            }
                        })}
                    </Select>
                </FormControl>

                {/*PHONE NUMBER*/}
                <MuiTelInput
                    label={t("common.phone")}
                    value={addressPhone}
                    disabled={false}
                    defaultCountry="FR"
                    error={validAddressPhone}
                    onChange={handleAddressPhoneNumberChange}
                />

                {/*Spacer*/}
                <Box className={"form-spacer"}></Box>

                {/*ALIAS*/}
                {/*<TextField
                    id="step3-alias"
                    label={t("register.assignAlias")}
                    onChange={e => setStep3Alias(e.target.value)}
                    value={step3Alias}
                    variant="outlined"
                    margin="normal"
                />*/}

                {/*ADITIONNAL INFORMATION*/}
                {/*<TextField
                    id="step3-information"
                    label={t("register.additionalInformation")}
                    onChange={e => setStep3AdditionalInformation(e.target.value)}
                    value={step3AdditionalInformation}
                    variant="outlined"
                    margin="normal"
                />*/}

            </Box>
        </>
    )
}
export default Step3;
