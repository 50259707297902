const useOrder = () => {

    let order = null;
    const setOrders = (_order) => {
        order = _order;
    }
    const getOrders = () => {
        return order;
    }
    const isOpenOrderExists = () => {
        //check in order if it exists a checkoutState not completed
        // let openOrder = order.find( order => order.checkoutState!== "completed")
        // return (openOrder!==undefined)
        return (order!==null)
    }
    const getCart = () => {
        // return order.find( order => order.checkoutState!== "completed");
        return order;
    }
    const getTokenValue = () => {
        return order.tokenValue;
    }
    const getId = () => {
        return order.id;
    }
    const getCheckoutState = () => {
        return getCart().checkoutState;
    }
    const getItems = () => {
        return getCart().items;
    }
    const getShippingCost = () => {
        return getCart().shippingTotal;
    }
    const setShippingCost = (_newValue) => {
        order.shippingTotal = _newValue;
    }
    const getShippingAddress = () => {
        return getCart().shippingAddress;
    }
    const getBillingAddress = () => {
        return getCart().billingAddress;
    }


    return {
        setOrders,
        getOrders,
        getId,
        isOpenOrderExists,
        getTokenValue,
        getCheckoutState,
        getItems,
        getShippingCost,
        setShippingCost,
        getShippingAddress,
        getBillingAddress
    }
}
export default useOrder;
