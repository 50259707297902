

const superExpressAvailableCountries = ["CZ","LT","PL","PT","SK","SI","GB","NL","FR","IT","DE"];

/**
 * Returns true if the country is eligible to Super express delivery mode.
 * @param countryCode
 */
export const isSuperExpressAvailable = (countryCode) => {
    return superExpressAvailableCountries.includes(countryCode);
}