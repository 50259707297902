import {useEffect, useState} from "react";
import {logInConsole} from "../tools/debug";
import {axiosPrivate} from "../api/axios";

const useVideoUpdates = () => {

    const [isVideoUpdateLoading, setIsVideoUpdateLoading] = useState(true);

    // FETCH VIDEO UPDATES JSON + config
    const [videoUpdatesList, setVideoUpdatesList] = useState([]);
    useEffect(() => {

        (async () => {
            // setConnectToBackend(true)
            await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/changelogs`)
                .then(
                    (response) => {
                        logInConsole(`Synchronized with success : Video changelogs`, 'green');

                        setVideoUpdatesList(response.data);
                        setIsVideoUpdateLoading(false);
                    },
                    (error) => {
                        logInConsole(`Error when obtaining customer infos`, 'red');
                    }
                ).finally(
                    () => {
                        // setConnectToBackend(false);
                    }
                )
        })();

    },[])


    return {videoUpdatesList, isVideoUpdateLoading}
}

export default useVideoUpdates;