import {Box} from "@mui/material";
import Home from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import React from "react";
import {ButtonIcape} from "../../button/button-icape";
import {useTranslation} from "react-i18next";

const RegisterResult = (props) => {
    const {t} = useTranslation();

    return (
        <>
            {/*TITLE*/}
            <Box className="register-title">
                <Typography variant={"h1"}>{t("register.accountCreated")}</Typography>
                <Typography component={"span"}>{t("common.welcome")}</Typography>
            </Box>

            <Box className="register-result">
                <Typography component={"p"}>
                    {t("register.accountSuccessfullyCreated")}
                </Typography>
                <ButtonIcape
                    onClick={props.handleLoginAccount}
                    endIcon={<Home/>}
                >
                    {t("cta.login")}
                </ButtonIcape>
            </Box>
        </>
    )
}
export default RegisterResult;
