import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./context/AuthProvider";
import {UserProvider} from "./context/UserProvider";
import {JaydaProvider} from "./context/jaydaProvider";
import {CalculatorRigidProvider} from "./context/calculatorRigidProvider";
import {CalculatorAluminumProvider} from "./context/calculatorAluminumProvider";
import {CalculatorFlexProvider} from "./context/calculatorFlexProvider";
import {CalculatorHdiProvider} from "./context/calculatorHdiProvider";
import {CalculatorLabServicesProvider} from "./context/calculatorLabServicesProvider";
import {CalculatorRigidFlexProvider} from "./context/calculatorRigidFlexProvider";
import './i18n';
import LoadingScreen from "./ui/loading/loading";

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingScreen />}>
            <BrowserRouter>
                <AuthProvider>
                    <UserProvider>
                        <CalculatorRigidFlexProvider>
                            <CalculatorLabServicesProvider>
                                <CalculatorHdiProvider>
                                    <CalculatorFlexProvider>
                                        <CalculatorAluminumProvider>
                                            <CalculatorRigidProvider>
                                                <JaydaProvider>
                                                    <Routes>
                                                        <Route path="/*" element={<App/>}/>
                                                    </Routes>
                                                </JaydaProvider>
                                            </CalculatorRigidProvider>
                                        </CalculatorAluminumProvider>
                                    </CalculatorFlexProvider>
                                </CalculatorHdiProvider>
                            </CalculatorLabServicesProvider>
                        </CalculatorRigidFlexProvider>
                    </UserProvider>
                </AuthProvider>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

