import {createContext, useContext, useEffect, useState} from "react";
import {getStorageValue} from "../tools/cookie-handler";
import {logInConsole} from "../tools/debug";
import {addBusinessDays} from "date-fns";
import UserContext from "./UserProvider";

const   CalculatorRigidContext = createContext({});

export const CalculatorRigidProvider = ({ children }) => {
    const {companyLocale, defaultCountryFromAddresses} = useContext(UserContext);
    const calculatorName = 'rigid';

    // GLOBAL
    const [unitFormat, setUnitFormat] = useState(getStorageValue(calculatorName)?.unitFormat || "mm");
    const [partNumber, setPartNumber] = useState(getStorageValue(calculatorName)?.partNumber || "");
    const [isJaydaPcbQuotable, setIsJaydaPcbQuotable] = useState(true);
    const [connectToBO, setConnectToBO] = useState(false);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);
    const [repeatOrderMode, setRepeatOrderMode] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode || {background: false, enabled: false, lockPrice: false});
    const [repeatOrderCreationAt, setRepeatOrderCreationAt] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderCreationAt || '');
    const [isLockFields, setIsLockFields] = useState(getStorageValue(calculatorName)?.isLockFields || false);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [idOrder, setIdOrder] = useState(0);
    const [priceOfferId, setPriceOfferId] = useState(0);
    const [priceMatrix, setPriceMatrix] = useState({});

    const [customerReferences, setCustomerReferences] = useState([]);
    const [customerReferenceChoose, setCustomerReferenceChoose] = useState(
        {title: getStorageValue(calculatorName)?.partNumber || '', isNewReference: false}
    );
    const [repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable] = useState([]);
    const [isAvailableOrderSelected, setIsAvailableOrderSelected] = useState(false)

    const [productVariant, setProductVariant] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.background ? getStorageValue(calculatorName)?.repeatOrder.productVariant : []);
    const [orderToken, setOrderToken] = useState("");
    const [userGerber, setUserGerber] = useState([]);
    const [userSpecFile, setUserSpecFile] = useState([]);
    const [pcbDeliveryFormat, setPcbDeliveryFormat] = useState(
        getStorageValue(calculatorName)?.pcbDeliveryFormat || 'single'
    );
    const [loadCurrency, setLoadCurrency] = useState(getStorageValue(calculatorName)?.loadCurrency || {});


    //JAYDA
    let jobId = null;
    const [ jobParams, setJobParams ] = useState({});
    const [ pcbImagesUrls, setPcbImagesUrls ] = useState({});
    const [ analyzeInProgress, setAnalyzeInProgress ] = useState('stop');
    const [ progress, setProgress ] = useState(0);
    const [ isErrorConnectToJayda, setIsErrorConnectToJayda ] = useState(false);
    const resetJayda = () => {
        setJobParams({});
        setPcbImagesUrls({});
        setAnalyzeInProgress('stop');
        setProgress(0);
        setIsErrorConnectToJayda(false);
    }


    //STEP 1
    const [isStepOneToFinalize, setIsStepOneToFinalize] = useState(false);

    const [pcbLength, setPcbLength] = useState(getStorageValue(calculatorName)?.pcb.pcbLength || "");
    const [pcbWidth, setPcbWidth] = useState(getStorageValue(calculatorName)?.pcb.pcbWidth || "");
    const [panelLength, setPanelLength] = useState(getStorageValue(calculatorName)?.panel.mm.panelLength || "");
    const [panelWidth, setPanelWidth] = useState(getStorageValue(calculatorName)?.panel.mm.panelWidth || "");

    const [quantity, setQuantity] = useState(getStorageValue(calculatorName)?.quantity || 10);
    const [quantityError, setQuantityError] = useState(false);
    const [panelQuantity, setPanelQuantity] = useState(getStorageValue(calculatorName)?.panel.panelQuantity || 1);
    const [pcbPerPanel, setPcbPerPanel] = useState(getStorageValue(calculatorName)?.panel.pcbPerPanel || 1);

    const [solderMaskId, setSolderMaskId] = useState(getStorageValue(calculatorName)?.pcb.solderMaskId || "");
    const [technologyId, setTechnologyId] = useState(getStorageValue(calculatorName)?.pcb.technologyId || "");
    const [smallestDrillingHoleSizeId, setSmallestDrillingHoleSizeId] = useState(getStorageValue(calculatorName)?.pcb.smallestDrillingHoleSizeId || "");
    const [legendId, setLegendId] = useState(getStorageValue(calculatorName)?.pcb.legendId || "");
    const [tracksWidthAndSpaceId, setTracksWidthAndSpaceId] = useState(getStorageValue(calculatorName)?.pcb.tracksWidthAndSpaceId || "");
    const [xoutId, setXoutId] = useState(getStorageValue(calculatorName)?.pcb.xoutId || 1);


    // MM INCH
    const [pcbLengthIn, setPcbLengthIn] = useState(getStorageValue(calculatorName)?.pcb.pcbLengthIn || "");
    const [pcbWidthIn, setPcbWidthIn] = useState(getStorageValue(calculatorName)?.pcb.pcbWidthIn || "");
    const [panelLengthIn, setPanelLengthIn] = useState(getStorageValue(calculatorName)?.panel.in.panelLengthIn || "");
    const [panelWidthIn, setPanelWidthIn] = useState(getStorageValue(calculatorName)?.panel.in.panelWidthIn || "");

    // PANELIZATION
    const [lockCustomPanelizationFields, setLockCustomPanelizationFields] = useState(getStorageValue(calculatorName)?.lockCustomPanelizationFields || false);
    const [customPanelizationSettings, setCustomPanelizationSettings] = useState(getStorageValue(calculatorName)?.customPanelizationSettings || {});


    // STEP 2
    //Technology
    const [extraPrepregsId, setExtraPrepregsId] = useState(getStorageValue(calculatorName)?.pcb.extraPrepregsId || 1);
    const [finishedInnerCuId, setFinishedInnerCuId] = useState(getStorageValue(calculatorName)?.pcb.finishedInnerCuId || 1);
    const [basedOuterCuId, setBasedOuterCuId] = useState(getStorageValue(calculatorName)?.pcb.basedOuterCuId || 1);
    const [finishedOuterCuId, setFinishedOuterCuId] = useState(getStorageValue(calculatorName)?.pcb.finishedOuterCuId || 1);
    const [ipc3Id, setIpc3Id] = useState(getStorageValue(calculatorName)?.pcb.ipc3Id || 1);
    const [pluggingViaId, setPluggingViaId] = useState(getStorageValue(calculatorName)?.pcb.pluggingViaId || 1);
    const [resinFillingId, setResinFillingId] = useState(getStorageValue(calculatorName)?.pcb.resinFillingId || 1);

    //Material
    const [rawMaterialId, setRawMaterialId] = useState(getStorageValue(calculatorName)?.pcb.rawMaterialId || 1);
    const [boardThicknessId, setBoardThicknessId] = useState(getStorageValue(calculatorName)?.pcb.boardThicknessId || 7);

    //PCB Details
    const [solderMaskColorId, setSolderMaskColorId] = useState(getStorageValue(calculatorName)?.pcb.solderMaskColorId || 2);
    const [legendColorId, setLegendColorId] = useState(getStorageValue(calculatorName)?.pcb.legendColorId || 2);
    const [finishingKey, setFinishingKey] = useState(getStorageValue(calculatorName)?.pcb.finishingKey || 1);

    //Advanced options
    const [ulCountryId, setUlCountryId] = useState(getStorageValue(calculatorName)?.pcb.ulCountryId || 1);
    const [vCutId, setVCutId] = useState(getStorageValue(calculatorName)?.pcb.vCutId || 1);
    const [ionicContaminationId, setIonicContaminationId] = useState(getStorageValue(calculatorName)?.pcb.ionicContaminationId || 1);
    const [cti600Id, setCti600Id] = useState(getStorageValue(calculatorName)?.pcb.cti600Id || 1);
    const [impedanceControlId, setImpedanceControlId] = useState(getStorageValue(calculatorName)?.pcb.impedanceControlId || 1);
    const [pressFitHoleId, setPressFitHoleId] = useState(getStorageValue(calculatorName)?.pcb.pressFitHoleId || 1);
    const [carbonInkId, setCarbonInkId] = useState(getStorageValue(calculatorName)?.pcb.carbonInkId || 1);
    const [maskOptionId, setMaskOptionId] = useState(getStorageValue(calculatorName)?.pcb.maskOptionId || 1);


    //STEP 3
    //Stencil
    const [stencilTypeId, setStencilTypeId] = useState(getStorageValue(calculatorName)?.stencil.stencilTypeId || 1);
    const [stencilEdgeId, setStencilEdgeId] = useState(getStorageValue(calculatorName)?.stencil.stencilEdgeId || 1);
    const [stencilThicknessId, setStencilThicknessId] = useState(getStorageValue(calculatorName)?.stencil.stencilThicknessId || 1);
    const [stencilFrameId, setStencilFrameId] = useState(getStorageValue(calculatorName)?.stencil.stencilFrameId || 1);
    const [isROStencilAdded, setIsROStencilAdded] = useState(false);

    const [shippingMethodId, setShippingMethodId] = useState(getStorageValue(calculatorName)?.shipping.shippingMethodId || 2);
    const [country, setCountry] = useState(getStorageValue(calculatorName)?.shipping.country || {});
    const [totalLeadTime, setTotalLeadTime] = useState(getStorageValue(calculatorName)?.countryId || 1);


    const [calendar, setCalendar] = useState(getStorageValue(calculatorName)?.shipping.calendar || {});
    const [requestedDate, setRequestedDate] = useState(getStorageValue(calculatorName)?.shipping.requestedDate || new Date);
    const [minDeliveryDate, setMinDeliveryDate] = useState(getStorageValue(calculatorName)?.shipping.minDeliveryDate || "");
    const [daysToDelivery, setDaysToDelivery] = useState(getStorageValue(calculatorName)?.shipping.daysToDelivery || "");
    const [productionTime, setProductionTime] = useState(getStorageValue(calculatorName)?.shipping.productionTime || "");
    const [persist, setPersist] = useState(false);
    const [comment, setComment] = useState(getStorageValue(calculatorName)?.comment || "");
    const [priceOfferLocale, setPriceOfferLocale] = useState(getStorageValue(calculatorName)?.priceOfferLocale || "");
    const [priceOfferPdfUrl, setPriceOfferPdfUrl] = useState("");

    //PROJECT PANEL (aka _PP)
    //FROM API
    const [price_PP, setPrice_PP] = useState(0);
    const [shippingCost_PP, setShippingCost] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue(calculatorName)?.repeatOrder.prices.shipping : 0);
    const [supplierCost_PP, setSupplierCost_PP] = useState(0);
    const [cost_PP, setCost_PP] = useState(0);

    const [batchSize, setBatchSize] = useState(0)
    const [unitPrice_PP, setUnitPrice_PP] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue(calculatorName)?.repeatOrder.prices.unit : 0);
    const [stencil_PP, setStencil_PP] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue(calculatorName)?.repeatOrder.prices.stencilUnitPrice : 0);
    const [tariff, setTariff] = useState(0);
    const [total_PP, setTotal_PP] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue(calculatorName)?.repeatOrder.prices.total : 0);
    const [initialTotalPricePP, setInitialTotalPricePP] = useState(getStorageValue(calculatorName)?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue(calculatorName)?.repeatOrder.prices.initialTotal : 0);
    const [incoterm_PP, setIncoterm_PP] = useState(getStorageValue(calculatorName)?.incoterm || "");

    const [voucher, setVoucher] = useState([]);
    const [weight, setWeight] = useState(0);


    /**
     * Filling data to place and order or a repeat order
     * @param refToLoad
     * @param {boolean} isRepeatOrder
     * @param {boolean} isCart
     */
    const loadCustomerReferenceRigid = (refToLoad, isRepeatOrder, isCart) => {
        resetAllRigid();

        if (isRepeatOrder) {
            logInConsole('Entering Rigid repeat order mode', 'gold');

            // Global
            setIdOrder(refToLoad.id);
            setRepeatOrderCreationAt(refToLoad.createdAt);
        }
        else {
            setPriceOfferId(refToLoad.id)
        }

        //CURRENCY
        setLoadCurrency(refToLoad.currency);

        if (refToLoad.isValid && isCart) {
            logInConsole('Loading Rigid quote to order', 'gold');
            setProductVariant(refToLoad.productVariants);
            return

        } else if (isRepeatOrder || (refToLoad.isValid && !isCart)){

            setRepeatOrderMode({background: isRepeatOrder, enabled: true, lockPrice: true});
            setProductVariant(refToLoad.productVariants);

            // Pricing
            setShippingCost(refToLoad.prices.shippingCost);
            setBatchSize(refToLoad.batchSize);
            setUnitPrice_PP(refToLoad.prices.pcbUnitPrice);
            setStencil_PP(refToLoad.prices.stencilUnitPrice);
            setIncoterm_PP(refToLoad.incoterm);
            setTariff(refToLoad.prices.tariff);
            if (!isNaN(refToLoad.prices.stencilUnitPrice)) {
                setInitialTotalPricePP(refToLoad.prices.fullPrice - refToLoad.prices.stencilUnitPrice);
            }
            else {
                setInitialTotalPricePP(refToLoad.prices.fullPrice);
            }
            setTotal_PP(refToLoad.prices.fullPrice);


        } else {
            console.log("passer par la matrice");
        }


        // Global
        setIsLockFields(true);
        setCompleted({0: true, 1: true});
        setPartNumber(refToLoad.pcbPartNumber);
        setCustomerReferenceChoose({title: refToLoad.pcbPartNumber, isNewReference: false});
        setQuantity(refToLoad.pcbQuantity);
        setPcbDeliveryFormat(refToLoad.deliveryFormat);
        setUnitFormat(refToLoad.unitFormat);

        // step 01
        setPcbLength(refToLoad.pcb.pcbLength);
        setPcbLengthIn(refToLoad.pcb.pcbLengthIn);
        setPcbWidth(refToLoad.pcb.pcbWidth);
        setPcbWidthIn(refToLoad.pcb.pcbWidthIn);

        setPanelLength(refToLoad.panel.panelLength);
        setPanelLengthIn(refToLoad.panel.panelLengthIn);
        setPanelWidth(refToLoad.panel.panelWidth);
        setPanelWidthIn(refToLoad.panel.panelWidthIn);
        setPcbPerPanel(refToLoad.panel.pcbPerPanel);
        setPanelQuantity(refToLoad.panel.panelQuantity);

        setPcbDeliveryFormat(refToLoad.deliveryFormat);
        refToLoad.deliveryFormat === 'custom' && setCustomPanelizationSettings(refToLoad.customPanelizationSettings);

        setSolderMaskId(refToLoad.pcb.solderMask);
        setPluggingViaId(refToLoad.pcb.pluggingVia);
        setTechnologyId(refToLoad.pcb.technology);
        setSmallestDrillingHoleSizeId(refToLoad.pcb.smallestDrillingHoleSize);
        setTracksWidthAndSpaceId(refToLoad.pcb.widthAndSpace);
        setLegendId(refToLoad.pcb.legend);
        setXoutId(refToLoad.pcb.xOut);

        // step 02
        setExtraPrepregsId(refToLoad.pcb.extraPrepeg);
        setFinishedInnerCuId(refToLoad.pcb.innerCu);
        setBasedOuterCuId(refToLoad.pcb.basedOuterCu);
        setFinishedOuterCuId(refToLoad.pcb.outerCu);
        setIpc3Id(refToLoad.pcb.IPC3);
        setResinFillingId(refToLoad.pcb.resinFilling);

        setRawMaterialId(refToLoad.pcb.rawMaterial);
        setBoardThicknessId(refToLoad.pcb.boardThickness);

        setSolderMaskColorId(refToLoad.pcb.solderMaskColor);
        setLegendColorId(refToLoad.pcb.legendColor);
        setFinishingKey(refToLoad.pcb.finishing);

        setUlCountryId(refToLoad.pcb.ulCountry);
        setVCutId(refToLoad.pcb.vCut);
        setIonicContaminationId(refToLoad.pcb.ionicContamination);
        setCti600Id(refToLoad.pcb.CTI600);
        setImpedanceControlId(refToLoad.pcb.impedanceControl);
        setPressFitHoleId(refToLoad.pcb.pressFitHole);

        setCarbonInkId(refToLoad.pcb.carbonInk);
        setMaskOptionId(refToLoad.pcb.maskOption);

        // step 03
        if (refToLoad.stencil !== undefined) {
            setStencilTypeId(refToLoad.stencil.stencilType);
            setStencilEdgeId(refToLoad.stencil.stencilEdge);
            setStencilThicknessId(refToLoad.stencil.stencilThickness);
            setStencilFrameId(refToLoad.stencil.stencilFrame);
        }

        setShippingMethodId(refToLoad.deliveryData.shippingMethodId);
        setCountry(refToLoad.deliveryData.country);
        setCalendar(refToLoad.deliveryData.calendar);
        setRequestedDate(refToLoad.deliveryData.estimatedDeliveryDate);
        setMinDeliveryDate(refToLoad.deliveryData.minDeliveryDate);
        setProductionTime(refToLoad.deliveryData.productionTime);
        setDaysToDelivery(refToLoad.deliveryData.daysToDelivery);

        setComment(refToLoad.comment === null ? "" : refToLoad.comment);
        setPriceOfferLocale(refToLoad.localeCode);
        setPriceOfferPdfUrl(refToLoad.priceOfferPdf);

        setActiveStep(2);
    }

    /**
     * Reset all data from Rigid calculator, including Jayda and local storage
     */
    const resetAllRigid = () => {
        logInConsole("Deleting all data Rigid", "turquoise");

        //Global
        setIdOrder(0);
        setPriceOfferId(0);
        setCustomerReferenceChoose({title: '', isNewReference: false});
        setRepeatOrdersSelectedAvailable([]);
        setIsAvailableOrderSelected(false);
        setPartNumber('');
        setProductVariant({});
        setIsStepOneToFinalize(false);

        setIsJaydaPcbQuotable(true);
        setIsLockFields(false);
        setLockCustomPanelizationFields(false);
        setActiveStep(0);
        setCompleted({});
        setRepeatOrderMode({background: false, enabled: false, lockPrice: false});
        setRepeatOrderCreationAt('');
        setUserGerber([]);
        setUserSpecFile([]);
        setPcbDeliveryFormat('single');
        setLoadCurrency({});
        setQuantity(10);
        setPanelQuantity(1);
        resetJayda();

        //Project panel
        setPriceMatrix({});
        setUnitPrice_PP(0);
        setStencil_PP(0);
        setShippingCost(0);
        setTotal_PP(0);
        setInitialTotalPricePP(0);
        setIncoterm_PP('');
        setTariff(0);
        setBatchSize(0);

        localStorage.removeItem(calculatorName);
        localStorage.removeItem('designMyPanel');

        //Step1
        setPcbLength('');
        setPcbWidth('');
        setPanelLength('');
        setPanelWidth('');
        setPcbLengthIn('');
        setPcbWidthIn('');
        setPanelLengthIn('');
        setPanelWidthIn('');
        setPcbPerPanel(1);
        setSolderMaskId('');
        setTechnologyId('');
        setSmallestDrillingHoleSizeId('');
        setTracksWidthAndSpaceId('');
        setLegendId('');
        setXoutId(1);
        setCustomPanelizationSettings({});

        //Step2
        setExtraPrepregsId(1);
        setFinishedInnerCuId(1);
        setBasedOuterCuId(1);
        setFinishedOuterCuId(1);
        setIpc3Id(1);
        setPluggingViaId(1);
        setResinFillingId(1);
        setRawMaterialId(1);
        setBoardThicknessId(7);
        setSolderMaskColorId(2);
        setLegendColorId(2);
        setFinishingKey(1);
        setUlCountryId(1);
        setVCutId(1);
        setIonicContaminationId(1);
        setCti600Id(1);
        setImpedanceControlId(1);
        setPressFitHoleId(1);
        setCarbonInkId(1);
        setMaskOptionId(1);

        //Step3
        setStencilTypeId(1);
        setStencilEdgeId(1);
        setStencilThicknessId(1);
        setStencilFrameId(1);

        setCalendar({});
        setShippingMethodId(2);
        setCountry(defaultCountryFromAddresses);
        setPriceOfferLocale(companyLocale);
        setProductionTime("");
        setDaysToDelivery("");
        setRequestedDate(new Date);

        setComment("");
        setPriceOfferPdfUrl("");
    }

    return (
        <CalculatorRigidContext.Provider value={{
            //GLOBAL
            idOrder, setIdOrder,
            priceOfferId, setPriceOfferId,
            priceMatrix, setPriceMatrix,
            partNumber, setPartNumber,
            repeatOrderMode, setRepeatOrderMode,
            repeatOrderCreationAt, setRepeatOrderCreationAt,
            activeStep, setActiveStep,
            completed, setCompleted,
            isLockFields, setIsLockFields,
            pcbDeliveryFormat, setPcbDeliveryFormat,
            isStepOneToFinalize, setIsStepOneToFinalize,
            quantity, setQuantity,
            panelQuantity, setPanelQuantity,
            quantityError, setQuantityError,
            lockCustomPanelizationFields, setLockCustomPanelizationFields,
            customPanelizationSettings, setCustomPanelizationSettings,
            customerReferences, setCustomerReferences,
            customerReferenceChoose, setCustomerReferenceChoose,
            repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable,
            isAvailableOrderSelected, setIsAvailableOrderSelected,
            productVariant, setProductVariant,
            orderToken, setOrderToken,
            unitFormat, setUnitFormat,
            connectToBO, setConnectToBO,
            errorBackendConnection, setErrorBackendConnection,
            loadCurrency, setLoadCurrency,

            //PROJECT PANEL
            price_PP, setPrice_PP,
            shippingCost_PP, setShippingCost,
            supplierCost_PP, setSupplierCost_PP,
            cost_PP, setCost_PP,
            unitPrice_PP, setUnitPrice_PP,
            stencil_PP, setStencil_PP,
            total_PP, setTotal_PP,
            initialTotalPricePP, setInitialTotalPricePP,
            incoterm_PP, setIncoterm_PP,
            voucher, setVoucher,
            weight, setWeight,
            tariff, setTariff,
            batchSize, setBatchSize,

            //JAYDA
            isJaydaPcbQuotable, setIsJaydaPcbQuotable,
            userGerber, setUserGerber,
            userSpecFile, setUserSpecFile,
            jobId,
            jobParams, setJobParams,
            pcbImagesUrls, setPcbImagesUrls,
            analyzeInProgress, setAnalyzeInProgress,
            progress, setProgress,
            isErrorConnectToJayda, setIsErrorConnectToJayda,

            //STEP 01
            pcbLength, setPcbLength,
            pcbWidth, setPcbWidth,
            pcbLengthIn, setPcbLengthIn,
            pcbWidthIn, setPcbWidthIn,
            panelLength, setPanelLength,
            panelWidth, setPanelWidth,
            panelLengthIn, setPanelLengthIn,
            panelWidthIn, setPanelWidthIn,
            pcbPerPanel, setPcbPerPanel,
            solderMaskId, setSolderMaskId,
            technologyId, setTechnologyId,
            smallestDrillingHoleSizeId, setSmallestDrillingHoleSizeId,
            tracksWidthAndSpaceId, setTracksWidthAndSpaceId,
            legendId, setLegendId,
            xoutId, setXoutId,

            //STEP 02
            extraPrepregsId, setExtraPrepregsId,
            finishedInnerCuId, setFinishedInnerCuId,
            basedOuterCuId, setBasedOuterCuId,
            finishedOuterCuId, setFinishedOuterCuId,
            ipc3Id, setIpc3Id,
            pluggingViaId, setPluggingViaId,
            resinFillingId, setResinFillingId,
            rawMaterialId, setRawMaterialId,
            boardThicknessId, setBoardThicknessId,
            solderMaskColorId, setSolderMaskColorId,
            legendColorId, setLegendColorId,
            finishingKey, setFinishingKey,
            ulCountryId, setUlCountryId,
            ionicContaminationId, setIonicContaminationId,
            vCutId, setVCutId,
            cti600Id, setCti600Id,
            impedanceControlId, setImpedanceControlId,
            pressFitHoleId, setPressFitHoleId,
            carbonInkId, setCarbonInkId,
            maskOptionId, setMaskOptionId,

            //STEP 03
            shippingMethodId, setShippingMethodId,
            country, setCountry,
            stencilTypeId, setStencilTypeId,
            stencilEdgeId, setStencilEdgeId,
            stencilThicknessId, setStencilThicknessId,
            stencilFrameId, setStencilFrameId,
            isROStencilAdded, setIsROStencilAdded,
            calendar, setCalendar,
            requestedDate, setRequestedDate,
            minDeliveryDate, setMinDeliveryDate,
            daysToDelivery, setDaysToDelivery,
            productionTime, setProductionTime,
            persist, setPersist,
            totalLeadTime, setTotalLeadTime,
            comment, setComment,
            priceOfferLocale, setPriceOfferLocale,
            priceOfferPdfUrl, setPriceOfferPdfUrl,

            //FUNCTIONS
            loadCustomerReferenceRigid,
            resetAllRigid
        }} displayName="CalculatorRigid">
            {children}
        </CalculatorRigidContext.Provider>
    )
}

export default CalculatorRigidContext;
