export const localeConverter = (_localeCode) => {

    const locales = {
        "zh_CN": "language.chinese",
        "de_DE": "language.german",
        "en_US": "language.english",
        "fr_FR": "language.french",
        "it_IT": "language.italian",
        "ja_JP": "language.japanese",
        "pt_BR": "language.portuguese",
        "es_ES": "language.spanish"
    }

    return locales[_localeCode]
}