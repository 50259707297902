import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Alert,
    Box,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import './cart.scss'
import Typography from "@mui/material/Typography";
import {ButtonIcapeGreen, ButtonIcapeOutlinedRed} from "../../button/button-icape";
import {logInConsole} from "../../../tools/debug";
import {useTranslation} from "react-i18next";
import UserContext from "../../../context/UserProvider";
import {getStorageValue} from "../../../tools/cookie-handler";
import {useDropzone} from "react-dropzone";
import ClearIcon from '@mui/icons-material/Clear';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

function Cart(props) {
    const {t} = useTranslation();
    const items = props.items;

//FAUT QUON PARLE DE CA ET LITALI E LA
    //du coup on a zappé d'en parler, mais plutôt que d'avoir 2 ou 3 props, peut etre qu'il serait mieux de faire un
    // objet complet (genre cartPrices) depuis le cartProcess (au moment où il récupère les infos de l'order, puis mis
    // à jour avec le code promo) avec les bonnes valeurs de montant de commande. Comme ca ici, ca sera plus
    //propre pour gérer ces valeurs.
    const orderPromotionTotal = props.orderPromotionTotal;
    const shippingCost = props.shippingCost;
    const orderPricing = props.orderPricing;
    const setInvoiceTotalPaypal = props.setInvoiceTotalPaypal;
    const repeatOrderMode = props.repeatOrderMode;

    const [rows, setRows] = useState([]);
    const [voucher, setVoucher] = useState('');
    const voucherList = props.voucherList;

    const [customerOrderReference, setCustomerOrderReference] = useState("");

    const {orderCurrency, isTestAccount} = useContext(UserContext);
    const handleVoucher = () => {
        if (voucher.toLowerCase() === 'electronica' && !isTestAccount) {
            props.setIsWrongPromoCode(true);
            return
        }

        props.handleVoucherChooseByCustomer(voucher);
        props.setIsWrongPromoCode(false);
    }
    const deleteVoucher = () => {
        setVoucher('');
        props.setIsWrongPromoCode(false);
        props.handleVoucherChooseByCustomer(null);
    }


    // FILLING CART ROWS INFORMATION
    useEffect(() => {
        if (items.length > 0) {
            //CHeck stencil
            let prepare_rows = [];
            items.forEach((item) => {
                let product = "";
                if (item.variant.endsWith("-stencil")) {
                    product = t("common.stencil");
                } else {
                    product = `${t("common.pcb")} - ` + getStorageValue('cart')?.checkout.calculator
                }

                prepare_rows.push({
                    desc: item.productName,
                    product:  product,
                    qty: item.quantity,
                    newUnitPrice: item.unitPrice / 100,
                    newPrice: (item.unitPrice / 100) * item.quantity
                })
            })
            setRows(prepare_rows);
        }

        logInConsole(`Applying invoice total to paypal : ${orderPricing.totalAllInclude}`, 'grey');
        setInvoiceTotalPaypal(ccyFormat(orderPricing.totalAllInclude));

    }, [orderPricing])


    function ccyFormat(num) {
        return (num !== undefined) ? `${num.toFixed(2)}` : num;
    }
    function subtotal(items) {
        return items.map(({newPrice}) => newPrice).reduce((sum, i) => sum + i, 0);
    }

    const invoiceSubtotal = subtotal(rows);


    //DRAG AND DROP ZONE HANDLER
    const {isGerberUploaded, setIsGerberUploaded, userGerber, setUserGerber, userSpecFile, setUserSpecFile} = props.gerber;
    const [isSpecFileUploaded, setIsSpecFileUploaded] = useState(false);
    const [isErrorGerberUploaded, setIsErrorGerberUploaded] = useState(false);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'application/zip': ['.zip', '.zipx'],
            'application/x-rar-compressed': ['.rar'],
            'application/x-7z-compressed': ['.7z'],
        },
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                setUserGerber([
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                ]);
            } else {
                setIsErrorGerberUploaded(true);
                console.error('Seuls les fichiers d\'archive sont autorisés.');
            }
        }
    });
    const {getRootProps:getRootSpecFileProps, getInputProps:getInputSpecFileProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setUserSpecFile(prevFiles => [
                ...prevFiles,
                ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })),
            ]);
        }
    });

    const deleteOneSpecFile = (indexToDelete) => {
        const updatedFiles = [...userSpecFile.slice(0, indexToDelete), ...userSpecFile.slice(indexToDelete + 1)];
        setUserSpecFile(updatedFiles);
    }

    useEffect(() => {
        //TODO ROUTE POUR ASSOCIER LE GERBER A L'ORDER @IKRAM
        setIsGerberUploaded(userGerber.length > 0)
    },[userGerber])

    useMemo(() => {
        //TODO ROUTE POUR ASSOCIER LE GERBER A L'ORDER @IKRAM
        setIsSpecFileUploaded(userSpecFile.length > 0)
    }, [userSpecFile])


    /**
     * Set Customer order reference in local storage to be updated after checkout
     * local storage object: cart
     */
    useEffect(() => {
        if(!getStorageValue('cart')){
            return
        }

        localStorage.setItem('cart', JSON.stringify({
            ...getStorageValue('cart'),
            customerOrderReference
        }));
    }, [customerOrderReference]);

    return (
        <>
            {/*SHOPPING CART TABLE*/}
            <Typography variant={'h3'}>{t("common.cart")}</Typography>
            <TableContainer className={"cart-table"}>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("common.description")}</TableCell>
                            <TableCell align="left">{t("common.product")}</TableCell>
                            <TableCell align="center">{t("calculators.toolbar.quantity")}</TableCell>
                            <TableCell align="center">{t("common.unit")}</TableCell>
                            <TableCell align="right">{t("common.sum")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <TableRow key={row.desc}>
                                <TableCell>{row.desc}</TableCell>
                                <TableCell>{row.product}</TableCell>
                                <TableCell align="center">{row.qty}</TableCell>
                                <TableCell align="center">{ccyFormat(row.newUnitPrice)} {orderCurrency}</TableCell>
                                <TableCell align="right">{ccyFormat(row.newPrice)} {orderCurrency}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={orderPromotionTotal === 0 ? 3 : 4} sx={{borderBottom: "none"}}/>
                            <TableCell rowSpan={orderPromotionTotal === 0 ? 3 : 4} sx={{borderBottom: "none"}}/>
                            <TableCell colSpan={2}>{t("common.subTotal")}</TableCell>
                            <TableCell align="right">{rows && ccyFormat(invoiceSubtotal)} {orderCurrency}</TableCell>
                        </TableRow>

                        {/*<TableRow>*/}
                        {/*    <TableCell colSpan={2}>{t("common.shipping")}</TableCell>*/}
                        {/*    <TableCell align="right">{ccyFormat(shippingCost / 100)} {orderCurrency}</TableCell>*/}
                        {/*</TableRow>*/}

                        {orderPromotionTotal === 0 ? null :
                            <TableRow>
                                <TableCell colSpan={2}>{t("common.voucher")}</TableCell>
                                <TableCell align="right">{orderPromotionTotal/100} {orderCurrency}</TableCell>
                            </TableRow>
                        }

                        <TableRow>
                             <TableCell colSpan={2}>
                                 {
                                     props.country.code === 'US' ?
                                         <>
                                            Tariff
                                         </>
                                         :
                                         <>
                                            Tax
                                         </>
                                 }
                             </TableCell>
                             <TableCell align="right">{orderPricing.taxTotal} {orderCurrency}</TableCell>
                         </TableRow>


                        <TableRow>
                            <TableCell colSpan={2}>{t("common.total")}</TableCell>
                            <TableCell align="right">{orderPricing.totalAllInclude} {orderCurrency}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <Box className="cart-double-item-container">
                {/*VOUCHER FIELD*/}
                <Box className="cart-voucher">
                    <Typography variant={'h3'}>{t("common.voucher")}</Typography>
                    <Box className="cart-voucher-field">
                        <TextField
                            id="voucher"
                            label={t("common.voucher")}
                            value={voucher}
                            onChange={(e) => setVoucher(e.target.value)}
                            variant="outlined"
                            disabled={orderPromotionTotal !== 0}
                            error={props.isWrongPromoCode}
                            helperText={props.isWrongPromoCode ? t("cart.wrongVoucher") : null}
                        />
                        {orderPromotionTotal === 0 ?
                            <ButtonIcapeGreen onClick={handleVoucher} disabled={!props.isCartReadyToProcessPayment}>{t("cta.addVoucher")}</ButtonIcapeGreen>
                            :
                            <ButtonIcapeOutlinedRed onClick={deleteVoucher}>{t("cta.resetVoucher")}</ButtonIcapeOutlinedRed>
                        }
                    </Box>

                    {/*VOUCHERS LIST*/}
                    <Box className="cart-voucher-list">
                        {voucherList.map((voucher, index) => (
                            <>
                                {
                                    ((voucher.code === "CyberMonday24" && invoiceSubtotal >= 600) || voucher.code !== "CyberMonday24") &&
                                    <Box component="p" key={index} onClick={() => setVoucher(voucher.code)}>
                                        <Box component="span">{voucher.code}</Box> : {voucher.description}
                                    </Box>
                                }
                            </>
                        ))}
                    </Box>
                </Box>

                <Box className="cart-voucher">
                    <Typography variant={'h3'}>
                        {t("common.customerOrderReference")}
                    </Typography>
                    <TextField
                        id="order-reference"
                        label={t("common.customerOrderReference")}
                        value={customerOrderReference}
                        onChange={(e) => setCustomerOrderReference(e.target.value)}
                        variant="outlined"
                    />
                </Box>
            </Box>

            {/*DROP ZONES*/}
            <Box className="cart-double-item-container">

                {/*GERBER DROP ZONE*/}
                {
                    !repeatOrderMode?.background &&
                    <Box className="cart-dropzone-container">
                        <Typography variant={'h3'}>{t("calculators.step1.gerber.gerberFile")}*</Typography>

                        {isGerberUploaded ?
                            <>
                                <Box className='cart-dropzone'>
                                    <Box className="file-item">
                                        <Box>
                                            <CloudDoneIcon />
                                        </Box>
                                        <Box component={"span"}>{userGerber[0]?.name}</Box>
                                        <Box id="delete-gerber-cross" onClick={() => setUserGerber([])} title="Delete Gerber">
                                            <ClearIcon />
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                            :
                            <Box {...getRootProps({className: 'cart-dropzone'})}>
                                <input {...getInputProps()} />
                                <img src="/assets/lottie/dragndrop.gif" alt=""/>
                                <Box className="cart-dropzone-text-container">
                                    <Box component={"span"}>{t("calculators.step1.gerber.dragNDrop")}</Box>
                                    <Box component="span" className="dropzone-text-small">{t("calculators.step1.gerber.allowedFiles")}</Box>
                                </Box>
                            </Box>
                        }
                        <Box component="span">
                            {t("cart.gerberFileMandatory")}
                        </Box>
                    </Box>
                }

                {/*SPEC FILE DROP ZONE*/}
                <Box className="cart-dropzone-container">
                    <Typography variant={'h3'}>{t('calculators.step1.gerber.specificationFiles')}</Typography>

                    <Box className="cart-spec-dropzone">
                        <Box {...getRootSpecFileProps({className:'drag-and-drop'})}>
                            <input {...getInputSpecFileProps()} />
                            <img src="/assets/lottie/dragndrop.gif" alt=""/>
                            <Box className="cart-dropzone-text-container">
                                <Box component={"span"}>{t("calculators.step1.gerber.dragNDrop")}</Box>
                                <Box component="span" className="dropzone-text-small">{t("calculators.step1.gerber.allowedSpecFiles")}</Box>
                            </Box>
                        </Box>

                        {userSpecFile.length > 0 &&
                            <Box className="specifications-files-list">
                                {userSpecFile.map((file, index) => (
                                    <Box key={index} className="file-item">
                                        <Box>
                                            <CloudDoneIcon />
                                        </Box>
                                        <Box component={"span"}>{file?.name}</Box>
                                        <Box id="delete-gerber-cross" onClick={() => deleteOneSpecFile(index)} title="Delete specification file">
                                            <ClearIcon />
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        }
                    </Box>
                </Box>


            </Box>

            {/*SNACKBAR ERROR MIME TYPE GERBER DROPZONE*/}
            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={isErrorGerberUploaded}
                      key='topright'
                      sx={{ width: '100%' }}
                      autoHideDuration={6000}
                      onClose={() => setIsErrorGerberUploaded(false)}>
                <Alert severity="warning" variant="filled"
                       onClose={() => setIsErrorGerberUploaded(false)}>
                    Only archive files are accepted for Gerber
                </Alert>
            </Snackbar>
        </>
    )
}

export default Cart
