import React, {useContext, useEffect, useMemo} from "react";
import debounce from "lodash.debounce";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import './step-1.scss';
import Typography from "@mui/material/Typography";
import CalculatorHdiContext from "../../../../context/calculatorHdiProvider";
import {useTranslation} from "react-i18next";
import {inchToMm, mmToInch} from "../../../../tools/unit.utils";
import GerberDropzone from "../../components/gerber-dropzone";
import DeliveryFormatSwitch from "../../components/delivery-format-switch";
import {extremeSizeValues, maxPcbCalculator, panelSizeCheck, pcbSizeCheck} from "../../../../tools/quantity-checks";

function Step1(props) {
    const {t} = useTranslation();

    const {
        isJaydaPcbQuotable, repeatOrderMode,
        quantity, setQuantity,
        unitFormat,
        analyzeInProgress,
        technologyId, setTechnologyId,
        pcbLength, setPcbLength,
        pcbWidth, setPcbWidth,
        panelLength, setPanelLength,
        panelWidth, setPanelWidth,
        solderMaskId, setSolderMaskId,
        panelQuantity, setPanelQuantity,
        pcbPerPanel, setPcbPerPanel,
        xoutId, setXoutId,
        userGerber, setUserGerber,
        pcbDeliveryFormat, setPcbDeliveryFormat,
        isLockFields,
        isStepOneToFinalize,
        pcbLengthIn, setPcbLengthIn,
        pcbWidthIn, setPcbWidthIn,
        panelLengthIn, setPanelLengthIn,
        panelWidthIn, setPanelWidthIn
    } = useContext(CalculatorHdiContext);


    const handleDropFile = props.handleDropFile;


    // handle PCB length field in mm and inch
    const handleMmInchPcbLength = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPcbLength('');
            setPcbLengthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPcbLength(newValue);
            setPcbLengthIn(mmToInch(newValue));
        } else {
            setPcbLength(inchToMm(newValue));
            setPcbLengthIn(newValue);
        }
    }
    // handle PCB width field in mm and inch
    const handleMmInchPcbWidth = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPcbWidth('');
            setPcbWidthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPcbWidth(newValue);
            setPcbWidthIn(mmToInch(newValue));
        } else {
            setPcbWidth(inchToMm(newValue));
            setPcbWidthIn(newValue);
        }
    };
    // handle PANEL length field in mm and inch
    const handleMmInchPanelLength = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPanelLength('');
            setPanelLengthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPanelLength(newValue);
            setPanelLengthIn(mmToInch(newValue));
        } else {
            setPanelLength(inchToMm(newValue));
            setPanelLengthIn(newValue);
        }
    }
    // handle PANEL width field in mm and inch
    const handleMmInchPanelWidth = (newValue) => {
        // checking if the field is empty
        if(isNaN(newValue)) {
            setPanelWidth('');
            setPanelWidthIn('');
            return
        }

        if(unitFormat === 'mm'){
            setPanelWidth(newValue);
            setPanelWidthIn(mmToInch(newValue));
        } else {
            setPanelWidth(inchToMm(newValue));
            setPanelWidthIn(newValue);
        }
    }
    const handlePcbPerPanel = (nbPcb) => {
       if (nbPcb > maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength) || nbPcb < 1) {
           return
       }
        setPcbPerPanel(nbPcb);
    }


    // Checking all dimensions
    const dimensionsCheck = (_unitFormat, _pcbLength, _pcbLengthIn, _pcbWidth, _pcbWidthIn, _panelLength, _panelLengthIn, _panelWidth, _panelWidthIn) => {
        pcbSizeCheck(_unitFormat, _pcbLength, _pcbLengthIn, setPcbLength, setPcbLengthIn, 'length');
        pcbSizeCheck(_unitFormat, _pcbWidth, _pcbWidthIn, setPcbWidth, setPcbWidthIn, 'width');
        panelSizeCheck(_unitFormat, _panelLength, _panelLengthIn, _pcbLength, _pcbLengthIn, setPanelLength, setPanelLengthIn, 'length');
        panelSizeCheck(_unitFormat, _panelWidth, _panelWidthIn, _pcbWidth, _pcbWidthIn, setPanelWidth, setPanelWidthIn, 'width');
    }

    //debounce useEffect to give the user time to type any number in fields.
    const debounceDimensions = useMemo(() => debounce(dimensionsCheck, 800), []);

    // DEBOUNCE LAUNCHER
    useEffect(() => {
        debounceDimensions(unitFormat, pcbLength, pcbLengthIn, pcbWidth, pcbWidthIn, panelLength, panelLengthIn, panelWidth, panelWidthIn);
    }, [pcbLength, pcbLengthIn, pcbWidth, pcbWidthIn, panelLength, panelLengthIn, panelWidth, panelWidthIn]);
    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return () => {
            debounceDimensions.cancel();
        }
    }, []);


    return (
        <>
            {/*SWITCHER + DRAG N DROP*/}
            <Box className={"technical-data-card-column step1-form-settings"}>

                {/*DRAG N DROP GERBER*/}
                <GerberDropzone
                    gerber={{userGerber, setUserGerber, handleDropFile}}
                    jayda={{analyzeInProgress, isJaydaPcbQuotable}}
                    lock={{repeatOrderMode, isLockFields}}/>

                {/*SWITCHER*/}
                <DeliveryFormatSwitch
                    pcbDeliveryFormat={{pcbDeliveryFormat, setPcbDeliveryFormat}}
                    panelization={{isCustomPanelization: false, handleShowPanelization: false, lockCustomPanelizationFields: false}}
                    isLockFields={isLockFields}/>
            </Box>

            {/*SINGLE PCB / GERBER PANELIZATION*/}
            <Box className="technical-data-card step1-settings">

                {/*TITLE SINGLE PCB - PANELIZATION*/}
                <Typography variant={'h3'}>
                    {
                        pcbDeliveryFormat === 'single' ?
                            t("calculators.step1.deliveryFormat.singlePcb")
                            :
                            (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                            t("calculators.step1.deliveryFormat.panelization")
                    }
                </Typography>

                {/*FIELDS*/}
                <Box className="technical-data-card-column-fields">

                    {/*PCB LENGTH*/}
                    <TextField
                        id="pcbLength"
                        label={t("calculators.step1.fields.pcbLength")}
                        value={unitFormat === 'mm' ? pcbLength : pcbLengthIn}
                        onChange={e => handleMmInchPcbLength(parseFloat(e.target.value))}
                        variant="outlined"
                        margin="normal"
                        type="number"
                        required
                        disabled={isLockFields}
                        error={pcbLength === '' && isStepOneToFinalize}
                        helperText={`${t("common.dimensions")} ${extremeSizeValues("pcb","length", unitFormat)}`}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                        }}
                    />

                    {/*PCB WIDTH*/}
                    <TextField
                        id="pcbWidth"
                        label={t("calculators.step1.fields.pcbWidth")}
                        value={unitFormat === 'mm' ? pcbWidth : pcbWidthIn}
                        onChange={e => handleMmInchPcbWidth(parseFloat(e.target.value))}
                        variant="outlined"
                        margin="normal"
                        type="number"
                        required
                        disabled={isLockFields}
                        error={pcbWidth === '' && isStepOneToFinalize}
                        helperText={`${t("common.dimensions")} ${extremeSizeValues("pcb","width", unitFormat)}`}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                        }}
                    />

                    {
                        (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                        <>
                            {/* PANEL LENGTH */}
                            <TextField
                                id="panelLength"
                                label={t("calculators.step1.fields.panelLength")}
                                value={unitFormat === 'mm' ? panelLength : panelLengthIn}
                                onChange={e => handleMmInchPanelLength(parseFloat(e.target.value))}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                disabled={isLockFields && repeatOrderMode.background}
                                error={panelLength === '' && isStepOneToFinalize}
                                helperText={`${t("common.dimensions")} ${extremeSizeValues("panel","length", unitFormat)}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                            />

                            {/* PANEL WIDTH */}
                            <TextField
                                id="panelWidth"
                                label={t("calculators.step1.fields.panelWidth")}
                                value={unitFormat === 'mm' ? panelWidth : panelWidthIn}
                                onChange={e => handleMmInchPanelWidth(parseFloat(e.target.value))}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                disabled={isLockFields && repeatOrderMode.background}
                                error={panelWidth === '' && isStepOneToFinalize}
                                helperText={`${t("common.dimensions")} ${extremeSizeValues("panel","width", unitFormat)}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                            />

                            {/* PCB PER PANEL */}
                            <TextField
                                id="pcbPerPanel"
                                label={t("calculators.step1.fields.pcbPerPanel")}
                                value={pcbPerPanel}
                                onChange={e => handlePcbPerPanel(parseInt(e.target.value))}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                disabled={isLockFields && repeatOrderMode.background}
                                error={pcbPerPanel === '' && isStepOneToFinalize}
                            />
                        </>
                    }

                    {/* SOLDER MASK Field */}
                    <FormControl disabled={isLockFields} required
                                 error={solderMaskId === '' && isStepOneToFinalize}>
                        <InputLabel id="solderMask-label">{t("calculators.step1.fields.solderMask")}</InputLabel>
                        <Select
                            labelId="solderMask-label"
                            id="solderMask"
                            value={solderMaskId}
                            label={t("calculators.step1.fields.solderMask")}
                            onChange={e => setSolderMaskId(e.target.value)}

                        >
                            <MenuItem value={1}>{t("calculators.selectable.noSolderMask")}</MenuItem>
                            <MenuItem value={2}>{t("calculators.selectable.top")}</MenuItem>
                            <MenuItem value={3}>{t("calculators.selectable.bottom")}</MenuItem>
                            <MenuItem value={4}>{t("calculators.selectable.topBottom")}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* STACK UP Field */}
                    <FormControl disabled={isLockFields} required
                                 error={technologyId === '' && isStepOneToFinalize}>
                        <InputLabel id="stackUp-label">{t("calculators.step1.fields.stackUp")}</InputLabel>
                        <Select
                            labelId="stackUp-label"
                            id="stackUp"
                            value={technologyId}
                            label={t("calculators.step1.fields.stackUp")}
                            onChange={(e) => setTechnologyId(e.target.value)}
                        >
                            <MenuItem value={21} disabled={true}>{t("calculators.selectable.singleSide")}</MenuItem>
                            <MenuItem value={22} disabled={true}>{t("calculators.selectable.doubleSide")}</MenuItem>
                            <MenuItem value={23}>4 {t("common.layers")}</MenuItem>
                            <MenuItem value={24}>6 {t("common.layers")}</MenuItem>
                            <MenuItem value={25}>8 {t("common.layers")}</MenuItem>
                            <MenuItem value={26}>10 {t("common.layers")}</MenuItem>
                            <MenuItem value={27}>12 {t("common.layers")}</MenuItem>
                        </Select>
                    </FormControl>

                    {/*XOUT*/}
                    { (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') &&
                        <>
                            {/* XOUT Field */}
                            <FormControl disabled={isLockFields} required
                                         error={xoutId === '' && isStepOneToFinalize}>
                                <InputLabel id="xOut-label">{t("calculators.step1.fields.xOut")}</InputLabel>
                                <Select
                                    labelId="xOut-label"
                                    id="xOut"
                                    value={xoutId}
                                    label={t("calculators.step1.fields.xOut")}
                                    onChange={(e) => setXoutId(e.target.value)}
                                >
                                    <MenuItem value={1}>{t("common.yes")} (10%)</MenuItem>
                                    <MenuItem value={2}>{t("common.no")}</MenuItem>
                                </Select>
                            </FormControl>
                        </>}

                </Box>
            </Box>
        </>
    )
}

export default Step1;
